import Types from '../../common/modules/types';

import tests from '../../helpers/tests';

const skipPassword = () => false;

const {
  isNotCreateActivity,
  viewerIsNotAdmin,
  userIsNotCustomer,
  userIsNotContentManager,
  skipIfUserIsCustomerWhichCanManageOrganization,
  viewerCannotEditUserPermissions,
} = tests;

export const fieldName = ['FieldText', {
  name: 'name',
  label: null,
  prefix: 'Name:',
  rules: [['validation.isRequired'], ['validation.isName']],
}];

export const fieldEmail = ['FieldText', {
  name: 'email',
  label: null,
  prefix: 'Email:',
  autoComplete: 'new-email',
  rules: [['validation.isRequired'], ['validation.isEmail']],
}];

export const fieldRole = ['FieldSelect', {
  name: 'role',
  label: null,
  prefix: 'Role:',
  initialValue: 'CUSTOMER',
  rules: [['validation.isRequired']],
  choices: Types.USER_ROLE_LIST.map(({ id, label }) => ({
    label,
    value: id,
  })),
   getInitialValue: (field, props) => (
    props.data && props.data.role
    ? props.data.role
    : !props.viewer
    ? undefined
    : props.viewer.role === 'CUSTOMER'
    ? 'CUSTOMER'
    : 'CUSTOMER'
  ),
  disabled: [isNotCreateActivity],
  virtual: [isNotCreateActivity],
  hidden: [
    viewerIsNotAdmin,
  ],
}];

export const fieldPasswordTitle = ['FieldTitle', {
  title: 'Password',
  skip: [skipPassword],
  // style: { marginTop: '10px' },
}];

export const fieldPassword = ['FieldText', {
  name: 'password',
  label: null,
  prefix: 'Password:',
  type: 'password',
  autoComplete: 'new-password',
  rules: [
    ['validation.isPassword'],
  ],
  skip: [skipPassword],
  col: { xs: 24, md: 12 },
}];

export const fieldConfirmPassword = ['FieldText', {
  name: 'confirmPassword',
  label: null,
  prefix: 'Confirm:',
  type: 'password',
  rules: [
    ['validation.isPasswordSame'],
  ],
  skip: [skipPassword],
  col: { xs: 24, md: 12 },
  virtual: true,
}];

// Customer

export const fieldOrganization = ['FieldConnectionSelect', {
  name: 'organization',
  label: null,
  getInitialValue: (field, props) => (
    props.data && props.data.organization
    ? props.data.organization
    : !props.viewer
    ? undefined
    : props.viewer.role === 'CUSTOMER'
    ? props.viewer.organization
    : undefined
  ),
  prefix: 'Organization:',
  placeholder: 'Select the customers organization',
  url: '/organizations',
  searchPaths: ['name'],
  getFieldsToResetOnChange: () => ['permissions.readVisualsForTeams'],
  // rules: [['validation.isRequired']],
  disabled: [isNotCreateActivity],
  virtual: [isNotCreateActivity],
  hidden: [
    viewerIsNotAdmin,
    userIsNotCustomer,
  ],
}];

export const dividerCustomerPermissions = ['Divider', {
  name: 'permissionsDivider',
  skip: [
    userIsNotCustomer,
    viewerCannotEditUserPermissions,
  ],
}];

export const dividerContentManagerPermissions = ['Divider', {
  name: 'permissionsDivider',
  skip: [
    userIsNotContentManager,
    viewerIsNotAdmin,
  ],
}];

export const fieldsContentManagerPermissions = [
  ...[
    ['managePrivateVisuals', 'Manage Private Visuals'],
  ].map(([name, label, disabled = [], isDivider = false]) => (
    [isDivider ? 'Divider' : 'FieldSwitch', {
      name: `permissions.${name}`,
      label,
      skip: [
        userIsNotContentManager,
        viewerIsNotAdmin,
      ],
      disabled,
      col: isDivider ? undefined : { lg: 8, xs: 24 },
      ghost: isDivider ? true : undefined,
    }]
  )),
];

export const fieldsCustomerPermissions = [
  ...[
    ['manageOrganization', 'Manage Organization', []],
    // ['manageLibrary', 'Manage Library', [
    //   skipIfUserIsCustomerWhichCanManageOrganization,
    // ]],
    // ['permissionsVisualsDivider', null, [
    //   skipIfUserIsCustomerWhichCanManageOrganization,
    // ], true],
    ['manageVisuals', 'Manage Visuals', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
    ['readVisuals', 'Access Visual Reports', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
    ['readDashboard', 'Access In-Store Insights', [
      skipIfUserIsCustomerWhichCanManageOrganization,
    ]],
  ].map(([name, label, disabled, isDivider = false]) => (
    [isDivider ? 'Divider' : 'FieldSwitch', {
      name: `permissions.${name}`,
      label,
      skip: [
        userIsNotCustomer,
        viewerCannotEditUserPermissions,
      ],
      prepareValueForInput: (value, props) => {
        if (name === 'manageOrganization') {
          return value;
        }
        const manageOrganization = props.form.getFieldValue(
          'permissions.manageOrganization',
        );
        if (['readVisuals'].includes(name)) {
          return true;
          // const manageVisuals = props.form.getFieldValue(
          //   'permissions.manageVisuals',
          // );
          // return (manageOrganization || manageVisuals) ? true : value;
        }
        return manageOrganization ? true : value;
      },
      disabled: [
        ...disabled,
        ...(
          ['readVisuals'].includes(name)
            // ? [[
            //   'condition.testFieldValue',
            //   'permissions.manageVisuals',
            //   '===',
            //   true,
            // ]]
            ? [() => true]
            : []
        ),
      ],
      col: isDivider ? undefined : { lg: 8, xs: 24 },
      ghost: isDivider ? true : undefined,
    }]
  )),
  // ['FieldConnectionSelect', {
  //   name: 'permissions.readVisualsForTeams',
  //   label: 'Access Visual Reports for Teams',
  //   placeholder: 'Select teams this user can read visual reports for',
  //   url: '/teams',
  //   searchPaths: ['name'],
  //   mode: 'multiple',
  //   getExtraQueryConditions: (props) => {
  //     const organization = props.form.getFieldValue('organization');
  //     return [{
  //       organization: {
  //         IN: organization ? [organization, null] : [null],
  //       },
  //     }];
  //   },
  //   skip: [
  //     userIsNotCustomer,
  //     viewerCannotEditUserPermissions,
  //     () => true, // TODO disabled unitl TEAMS are added!
  //   ],
  //   prepareValueForInput: (value, props) => {
  //     const manageOrganization = props.form.getFieldValue(
  //       'permissions.manageOrganization'
  //     );
  //     const manageVisuals = props.form.getFieldValue(
  //       'permissions.manageVisuals'
  //     );
  //     const readVisuals = props.form.getFieldValue(
  //       'permissions.readVisuals'
  //     );
  //     return (
  //       manageOrganization
  //       || manageVisuals
  //       || !readVisuals
  //     )
  //       ? []
  //       : value;
  //   },
  //   disabled: [
  //     skipIfUserIsCustomerWhichCanManageOrganization,
  //     [
  //       'condition.testFieldValue',
  //       'permissions.readVisuals',
  //       '!==',
  //       true,
  //     ],
  //     [
  //       'condition.testFieldValue',
  //       'permissions.manageVisuals',
  //       '===',
  //       true,
  //     ],
  //   ],
  // }],
];

export const ADMIN_ALL = [
  fieldName,
  fieldEmail,
  fieldRole,
  fieldOrganization,
  fieldPasswordTitle,
  fieldPassword,
  fieldConfirmPassword,

  dividerCustomerPermissions,
  ...fieldsCustomerPermissions,
  dividerContentManagerPermissions,
  ...fieldsContentManagerPermissions,
];
