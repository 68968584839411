import styled, { css } from 'styled-components';

const Title = styled.h1`
  margin: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.less.textColor};

  font-size: 40px;
  ${({ as }) => as === 'h2' && css`font-size: 30px;`}
  ${({ as }) => as === 'h3' && css`font-size: 18px;`}
`;

const Subtitle = styled.h4`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.less.textColor};
  opacity: 0.5;

  font-size: 20px;
  ${({ as }) => as === 'h5' && css`font-size: 16px;`}
`;

const Body = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.less.textColor};
  opacity: 0.75;

  ${({ $alternative }) => $alternative && css`
    opacity: 0.5;
    font-style: italic;
  `}
`;

export { Title, Subtitle, Body };
