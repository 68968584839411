import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

import AntdEmpty from 'antd/lib/empty';

import PageArchiveTable from 'hive-admin/src/components/PageArchiveTable';
import { QueryBuilder } from 'hive-admin/src/components/Filters'

import Query from 'hive-admin/src/components/Query';
import { PowerBIEmbed } from 'powerbi-client-react';
import { TokenType } from 'powerbi-models';

import useQuery from '../modules/useQuery';

import tests from '../helpers/tests';

// import Types from '../common/modules/types';

const {
  viewerCannotViewDashboard,
} = tests;

const Wrapper = styled.div`
  overflow: hidden;
  flex: 1;
  flex-grow: 1;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
`;

const IFrameWrapper = styled.div`
  width: 100%;
  aspect-ratio: 600 / 373.5;
  position: relative;
  overflow: hidden;
  /* margin-bottom: calc(-36px -23px -2px); */
  /* width: calc(100% + 2* $padding);
  margin: 0 -$padding -$padding; */
  .power-bi-report {
    top: 0px;
    width: 100%;
    height: 100%;
    /* clip-path: polygon(
      0% 0%,
      100% 0%,
      100% calc(100% - 31px),
      calc(100% - 80px) calc(100% - 31px),
      calc(100% - 80px) 100%,
      calc(0% + 158px) 100%,
      calc(0% + 158px) calc(100% - 31px),
      0% calc(100% - 31px)
    ); */
    iframe {
      border: 0px;
    }
  }
  &[data-is-small="true"] .power-bi-report {
    clip-path: none;
    /* clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 100%,
      calc(0% + 158px) 100%,
      calc(0% + 158px) calc(100% - 31px),
      0% calc(100% - 31px)
    ); */
  }
`;

// const IFrameCover = styled.div`
//   position: absolute;
//   bottom: 0px;
//   height: 36px;
//   background: #eaeaea;
//   mix-blend-mode: darken;
//   pointer-events: none;
// `;

function IFrameComponent({ reportId, embedUrl, embedAccessToken }) {
  const ref = useRef();
  const isSmallRef = useRef(false);
  const [isSmall, setIsSmall] = useState(false);
  useEffect(
    () => {
      let observer = null;
      if (ref.current) {
        const { current: iFrame } = ref;
        observer = new ResizeObserver(() => {
          const width = iFrame.offsetWidth;
          // console.log('width:', width);
          if (width <= 543) {
            if (!isSmallRef.current) {
              isSmallRef.current = true;
              setIsSmall(true);
            }
          } else if (isSmallRef.current) {
            isSmallRef.current = false;
            setIsSmall(false);
          }
        });
        observer.observe(iFrame);
      }
      return () => {
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      };
    },
    [],
  );
  return (
    <>
      <IFrameWrapper
        data-is-small={isSmall}
      >
        {
            embedAccessToken
          ? (
              <PowerBIEmbed
                cssClassName="power-bi-report"
                key={reportId}
                embedConfig={{
                  type: 'report',
                  id: reportId,
                  embedUrl,
                  accessToken: embedAccessToken,
                  tokenType: TokenType.Embed,
                }}
                getEmbeddedComponent={(embedded) => {
                  ref.current = embedded.element;
                }}
              />
            )
          : null
        }
        {/* <IFrameCover
          key="cover-top"
          style={{
            bottom: '36px',
            height: '24px',
            left: '0px',
            right: '0px',
            background: '#f5f5f5',
          }}
        />
        <IFrameCover
          key="cover-left"
          style={{
            left: '0px',
            width: '158px',
          }}
        />
        <IFrameCover
          key="cover-center"
          style={{
            left: '158px',
            right: '80px',
          }}
        />
        <IFrameCover
          key="cover-right"
          style={{
            right: '0px',
            width: '80px',
          }}
        /> */}
      </IFrameWrapper>
    </>
  );
}

function IFrameComponentGetter({
  organizationId = 'me',
  reportId,
  client,
}) {
  const [{ _loading, data }] = useQuery(client, {
    url: `organizations/${
      organizationId
    }/dashboard/${
      reportId
    }`,
    // method: 'POST',
  });
  if (!data?.data) {
    return null;
  }
  return (
    <IFrameComponent
      // embedUrl={data.data.embedUrl}
      reportId={data.data.reportId}
      embedAccessToken={data.data.embedToken}
    />
  );
}

class PageDashboard extends PageArchiveTable {
  renderArchiveContent() {
    if (!this.props.viewer) {
      return null;
    }
    let organizationId = null;
    let reportId = null;
    const [
      newOrganizationId,
      newReportId,
    ] = this.props.searchParams?.report?.split?.('-') || [];
    if (newOrganizationId && !newReportId) {
      reportId = newOrganizationId;
    } else if (newOrganizationId) {
      reportId = newReportId;
      organizationId = newOrganizationId;
    } else if (this.props.viewer?.organizationData?._id) {
      organizationId = this.props.viewer.organizationData._id;
      reportId = this.props.viewer
        ?.organizationData?.dashboardPowerBiReports?.[0]?._id;
    } else if (this.props.viewer?.role === 'CUSTOMER') {
      organizationId = 'me';
    }
    return (
      <Wrapper>
        {
          organizationId
          ? (
              <Query
                client={this.props.client}
                url={`organizations/${organizationId}`}
              >
                {({ loading, data }) => {
                  if (!loading && !data) {
                    return (
                      <AntdEmpty
                        style={{ maxWidth: '400px' }}
                        description={
                          // eslint-disable-next-line max-len
                          'No In-Store Insights found.'
                        }
                      />
                    );
                  }
                  if (data && data.data) {
                    const reportsMap = (
                      data.data.dashboardPowerBiReports?.reduce?.(
                        (agr, report) => {
                          agr[report._id] = report;
                          return agr;
                        },
                        {},
                      )
                    );
                    let report = reportsMap[reportId];
                    if (!report) {
                      report = data.data.dashboardPowerBiReports?.[0];
                    }
                    if (!report) {
                      return null;
                    }
                    return (
                      <IFrameComponentGetter
                        organizationId={organizationId}
                        reportId={report?._id || reportId}
                        client={this.props.client}
                      />
                    );
                    // return <IFrameComponent report={report} />;
                  }
                  return null;
                }}
              </Query>
            )
          : this.props.viewer?.role === 'ADMIN'
          ? (
              <AntdEmpty
                style={{ maxWidth: '400px' }}
                description={
                  // eslint-disable-next-line max-len
                  'Please access In-Store Insights from the Organization page using the report "Preview" button.'
                }
              />
            )
          : null
        }
      </Wrapper>
    );
  }
}

export default [config => PageDashboard.create(config), {
  title: 'In-Store Insights',
  path: '/in-store-insights',
  exact: true,
  icon: 'dashboard',
  queryBuilder: new QueryBuilder(),
  createButtonSupported: false,
  skip: [
    viewerCannotViewDashboard,
  ],

  filters: [
    ['FilterField', {
      id: 'report',
      name: 'report',
      buildQuery: () => {},
      getValueForField: (value, props) => value || (
        props
        ?.viewer
        ?.organizationData
        ?.dashboardPowerBiReports
        ?.[0]
        ?._id
      ),
      getValueForQuery: (value) => {
        value = !value
        ? undefined
        : value.target
        ? value.target.value
        : value;
        return !value || !value.length
        ? undefined
        : value;
      },
      propsFromPage: ({ viewer }) => ({
        viewer,
      }),
      getSkip: ({ viewer }) => (
        (viewer?.organizationData?.dashboardPowerBiReports?.length || 0) <= 1
      ),
      field: ['FieldSelect', {
        label: null,
        name: 'report',
        renderChoices: (field, props) => (
          props
          ?.viewer
          ?.organizationData
          ?.dashboardPowerBiReports
          ?.map?.(
            choice => props.renderChoice({
              value: choice._id,
              label: choice.label,
            }, field, props)
          ) || []
        ),
        block: true,
      }],
      col: { xs: 24 },
      section: 'top',
    }],
  ],
}];
