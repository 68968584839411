import React, {
  useEffect,
} from 'react';
import styled from 'styled-components';
import {
  // parse as parseQuery,
  stringify as stringifyQuery,
} from 'query-string';
// import getKey from 'lodash/get';
// import { Link } from 'react-router-dom';

import {
  LockOutlined,
  LoadingOutlined,
  BulbOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';

import AntdButton from 'antd/lib/button';
// import Affix from 'antd/lib/affix';
// import Row from 'antd/lib/row';
// import Col from 'antd/lib/col';

import Admin from 'hive-admin';
import PageArchiveTable from 'hive-admin/src/components/PageArchiveTable';
import PageArchiveGrid from 'hive-admin/src/components/PageArchiveGrid';

import { Grid, TrackerItem } from '../../components/Tracker';

// import { Report } from '../../components/PageSingleProject';
// import { Header } from '../../components/PageSingleProject/Report/common';

import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
  TRACKER as FILTERS_TRACKER,
} from './filters';

import {
  NEW as FIELDS_NEW,
  CREATE as FIELDS_CREATE,
  PROCESSING as FIELDS_PROCESSING,
  REPORT as FIELDS_REPORT,
} from './fields';

import {
  actionSaveContent,
  actionSaveResults,
  actionDuplicate,
  actionDelete,
  // actionFillReportFromJson,
  actionExportAnnotations,
  actionExportProcessingResponse,
  actionCreateReport,
  actionSwitchBackTo,

  actionSubmit,

  actionDownloadReport,
} from './actions';

const HelpLinkComponent = styled(AntdButton)`
  margin-left: -10px;
`;

function HelpLink({ href, ...props }) {
  if (!href) {
    return null;
  }
  return (
    <HelpLinkComponent
      type="link"
      icon="info-circle"
      href={href}
      target="_blank"
      {...props}
    />
  );
}

function ProcessingTitle({ children, reload }) {
  useEffect(() => {
    const interval = setInterval(
      () => {
        reload();
      },
      5000,
    );
    return () => {
      clearInterval(interval);
    };
  }, [reload]);
  return children;
}

const skipIfViewerCanNotAccessVisuals = [
  'condition.not',
  ['condition.or', [
    tests.viewerIsAdminOrContentManager,
    tests.isViewerCustomerWhichCanManageOrganization,
    tests.isViewerCustomerWhichCanManageVisuals,
    tests.isViewerCustomerWhichCanReadVisuals,
  ]],
];

const PAGES = [{
  path: '/visuals/:id/content',
  // loadUrl: '/visuals/:id/?full=true',
  step: {
    title: 'Content',
    description: 'Setup custom test content',
    onClick: props => props.history.replace(
      `/visuals/${props.data._id}/content`
    ),
    getIsDisabled: props => props.data?.status !== 'NEW',
    // eslint-disable-next-line no-unused-vars
    getRedirect: ({ data, loading, viewer }) => {
      // if (!loading && data && viewer) {
      //   if (['ADMIN', 'CONTENT_MANAGER'].includes(viewer?.role)) {
      //     if (data.status === 'REPORT') {
      //       return `/visuals/${data._id}/report`;
      //     }
      //   }
      // }
      if (!loading && data && data.status !== 'NEW') {
        if (data.status === 'PROCESSING') {
          return `/visuals/${data._id}/processing`;
        }
        if (data.status === 'REPORT') {
          return `/visuals/${data._id}/report`;
        }
      }
      return null;
    },
    getIcon: props => (
      props.data.status === 'NEW'
        ? <span>1</span>
        : <LockOutlined />
    ),
    getStatus: props => (
      props.data.status !== 'NEW'
        ? undefined
        : 'finish'
    ),
    renderContent: (props, renderForm, renderResult) => (
      renderResult(
        'Content',
        'Setup custom test content.',
        null,
        renderForm(),
        { disabled: props.data.status !== 'NEW' },
      )
    ),
  },
  fields: FIELDS_NEW,
  actions: [
    actionSaveContent,
    actionDuplicate,
    actionDelete,
    actionSubmit,
  ],
}, {
  path: '/visuals/:id/processing',
  // loadUrl: '/visuals/:id/?full=true',
  // getHideHeader: (props) => {
  //   console.log(props);
  //   return false;
  // },
  step: {
    title: 'Processing',
    description: 'Compiling results',
    onClick: props => props.history.replace(
      `/visuals/${props.data._id}/processing`
    ),
    getIsDisabled: props => props.data?.status !== 'PROCESSING',
    getRedirect: ({ data, loading }) => {
      if (!loading && data && data.status !== 'PROCESSING') {
        if (data.status === 'NEW') {
          return `/visuals/${data._id}/content`;
        }
        if (data.status === 'REPORT') {
          return `/visuals/${data._id}/report`;
        }
      }
      return null;
    },
    getDescription: ({ data: { status } }) => (
      status === 'NEW'
      ? 'Submit your test content'
      : status === 'REPORT'
      ? 'Processing finished'
      : 'Compiling results'
    ),
    getIcon: ({ data: { status } }) => (
      status === 'NEW'
        ? <span>2</span>
        : status === 'REPORT'
        ? <BulbOutlined />
        : <LoadingOutlined />
    ),
    getStatus: props => (
      props.data.status !== 'PROCESSING'
      ? undefined
      : 'finish'
    ),
    renderContent: (props, renderForm, renderResult) => {
      const { data, viewer } = props;
      const cannotManageVisual = (
        viewer.role === 'CUSTOMER'
        && !viewer.permissions.manageOrganization
        && !viewer.permissions.manageVisuals
      );
      switch (data.status) {
        case 'NEW':
          return renderResult(
            'Submit',
            cannotManageVisual
              ? 'Please consult your project manager about further steps'
              : 'When you\'re ready to submit, click the button below',
            null,
          );
        case 'PROCESSING':
          if (viewer.role === 'CUSTOMER') {
            return renderResult(
              'Submitted',
              `
                Visual is being processed at this time,
                please check back later.
              `,
              'loading',
            );
          }
          return (
            data.processing
            ? renderResult(
                (
                  <ProcessingTitle reload={props.reload}>
                    Awaiting AI Results
                  </ProcessingTitle>
                ),
                'Please wait for the results.',
                'loading',
              )
            : renderResult(
                'Processing',
                'Please enter results here.',
                null,
                renderForm(),
                { disabled: props.data.status !== 'PROCESSING' },
              )
          );
        case 'REPORT':
          return renderResult(
            'Processing finished',
            'Check out the results on the Report tab.',
            'like',
          );
        default:
          return null;
      }
    },
  },
  fields: FIELDS_PROCESSING,
  actions: [
    actionSwitchBackTo,
    actionSaveResults,
    actionDuplicate,
    actionDelete,
    // actionFillReportFromJson,
    actionExportAnnotations,
    actionExportProcessingResponse,
    actionCreateReport,
    actionSubmit,
  ],
}, {
  path: '/visuals/:id/report',
  step: {
    title: 'Report',
    description: 'View your test results',
    onClick: props => props.history.replace(
      `/visuals/${props.data._id}/report`
    ),
    getIsDisabled: props => props.data?.status !== 'REPORT',
    getRedirect: ({ data, loading }) => {
      if (!loading && data && data.status !== 'REPORT') {
        if (data.status === 'NEW') {
          return `/visuals/${data._id}/content`;
        }
        if (data.status === 'PROCESSING') {
          return `/visuals/${data._id}/processing`;
        }
      }
      return null;
    },
    getStatus: props => (
      props.data.status !== 'REPORT'
      ? undefined
      : 'finish'
    ),
    getIcon: ({ data: { status } }) => (
      status === 'REPORT'
      ? <AreaChartOutlined />
      : <span>3</span>
    ),
  },
  fields: FIELDS_REPORT,
  actions: [
    actionSwitchBackTo,
    actionDuplicate,
    actionExportAnnotations,
    actionExportProcessingResponse,
    actionDelete,
    actionDownloadReport,
  ],
}];

const STEPS = PAGES.map(({ path, step = {} }, index) => ({
  index,
  ...step,
  path,
}));

function handleOnSelectedToggle(selectedId, props) {
    const compareIds = (props.searchParams?.compare || '').split(',');
    let newCompareIds = [...compareIds].filter(id => id?.length);
    if (Array.isArray(selectedId)) {
      newCompareIds = selectedId;
    } else if (compareIds.includes(selectedId)) {
      newCompareIds = compareIds.filter(id => id !== selectedId);
    } else {
      newCompareIds.push(selectedId);
    }
    newCompareIds = newCompareIds.slice(-4);
    const newQueryParams = {
      ...props.searchParams,
      ...(newCompareIds.length ? { compare: newCompareIds.join(',') } : {}),
    };
    if (!newCompareIds.length) {
      delete newQueryParams.compare;
    }
    const newQuery = stringifyQuery(newQueryParams);
    props.history.push(
      `${props.location.pathname}${
        newQuery.length ? `?${newQuery}` : ''
      }`,
    );
  }

class PageArchiveGridCustom extends PageArchiveGrid {
  handleOnSelectedToggle = (selectedId) => {
    handleOnSelectedToggle(selectedId, this.props);
  }

  renderArchiveContent() {
    const compareIds = (this.props.searchParams?.compare || '').split(',')
    .filter(id => !!id);
    const data = this.props.data ? this.props.data.data : [];
    return (
      <Grid>
        {data.map(item => (
          <TrackerItem
            key={item._id}
            item={item}
            pageProps={this.props}
            viewer={this.props.viewer}
            onSelectedToggle={this.handleOnSelectedToggle}
            selected={compareIds.includes(item._id)}
          />
        ))}
      </Grid>
    );
  }
}

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Optimizer',
    icon: 'project',
    path: '/visuals',
    skip: [skipIfViewerCanNotAccessVisuals],
    afterArchivePages: [
      PageArchiveGridCustom.create({
        title: 'Optimizer',
        path: '/visuals/grid',
        loadUrl: '/visuals',
        alias: '/visuals',
        hidden: [() => true],
        filters: FILTERS_ALL,
        exact: true,
        redirect: [['redirect.unauthorized']],
        loadExtractData: response => (
          response
          ? { count: response.data.count, data: response.data.data }
          : { count: 0, data: [] }
        ),
        createButtonPath: '/visuals/create',
        createButtonLabel: 'Upload',
        gridItemLink: '/visuals/:_id',
        createButtonSupported: true,
        getUseFiltersExtra: () => true,
      }),
    ],
    archiveConfig: {
      icon: 'project',
      title: 'Optimizer',
      label: 'Optimizer',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
      redirect: [['redirect.unauthorized']],
      subtitleColumnPath: 'image',
      createButtonPath: '/visuals/create',
      createButtonLabel: 'Upload',
      getUseFiltersExtra: () => true,
      // eslint-disable-next-line arrow-body-style
      getTableProps: (props, mode) => {
        const compareIds = (props.searchParams?.compare || '').split(',')
        .filter(id => !!id);
        return {
          rowSelection: {
            selectedRowKeys: compareIds,
            // onChange: (ids) => {
            //   ids = ids.slice(-4);
            //   handleOnSelectedToggle(ids, props);
            // },
            onSelect: (record, _selected, _selectedRows) => {
              handleOnSelectedToggle(record._id, props);
            },
            onSelectAll: () => {
              handleOnSelectedToggle([], props);
            },
            onSelectInvert: () => {
              handleOnSelectedToggle([], props);
            },
            // selections: false,
            // hideDefaultSelections: true,
          },
          ...PageArchiveTable.defaultProps.getTableProps(props, mode),
        };
      },
      getCreateButtonSupported: ({ viewer }) => (
        viewer
        && (
          viewer.role === 'ADMIN'
          || viewer.role === 'CONTENT_MANAGER'
          || (
            viewer.role === 'CUSTOMER'
            && (
              viewer.permissions.manageOrganization
              || viewer.permissions.manageVisuals
            )
          )
        )
      ),
    },
    singleCreateConfig: {
      hidden: true,
      alias: '/visuals',
      backButtonPaths: ['/visuals'],
      headerTitle: 'Upload New',
      fields: FIELDS_CREATE, // CREATE
      redirect: [['redirect.unauthorized']],
      renderHeaderDescription: (props) => (
        <HelpLink
          icon="info-circle"
          href={props.viewer?.settings?.links?.howToUploadVisuals}
        >
          How to upload?
        </HelpLink>
      ),
    },
    singleEditConfig: {
      title: 'Visual',
      alias: '/visuals',
      backButtonPaths: ['/visuals'],
      redirect: [
        ['redirect.unauthorized'],
        ({ match: { url } }) => `${url}/content`,
      ],
    },
    beforeSinglePages: [
      ...PAGES.map(({ step, ...config }) => ['PageSingleVisual', {
        alias: '/visuals',
        backButtonPaths: ['/visuals'],
        activity: 'edit',
        renderHeaderDescription: (props) => (
          ['NEW'].includes(props.data?.status)
          ? (
              <HelpLink
                icon="info-circle"
                href={props.viewer?.settings?.links?.howToUploadVisuals}
              >
                How to upload?
              </HelpLink>
            )
          : ['PROCESSING', 'REPORT'].includes(props.data?.status)
          ? (
              <HelpLink
                icon="info-circle"
                href={props.viewer?.settings?.links?.howToInterpretReports}
              >
                How to interpret report?
              </HelpLink>
            )
          : null
        ),
        loadUrl: `/visuals/:id/?query=${btoa(JSON.stringify({
          populate: {
            organizationData: true,
            brandData: true,
            departmentData: true,
            categoryData: true,
            retailerData: true,
          },
        }))}`,
        deleteUrl: '/visuals/:id',
        deleteRedirectPath: '/visuals',
        title: 'Visual',
        ...config,
        steps: STEPS,
        redirect: [['redirect.unauthorized']],
      }]),
    ],
  }])
).pages;

export const TrackerPages = [PageArchiveGridCustom.create({
  title: 'In-Store Gallery',
  path: '/in-store-gallery',
  loadUrl: '/visuals',
  icon: 'fire',
  filters: FILTERS_TRACKER,
  redirect: [['redirect.unauthorized']],
  loadExtractData: response => (
    response
    ? { count: response.data.count, data: response.data.data }
    : { count: 0, data: [] }
  ),
  gridItemLink: '/visuals/:_id/report',
  createButtonSupported: false,
  getUseFiltersExtra: () => true,
})];
