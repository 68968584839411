import mongoose from 'mongoose/browser';

import React, {
  useMemo,
} from 'react';
import styled from 'styled-components';

import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';

import AntdRate from 'antd/lib/rate';
import AntdEmpty from 'antd/lib/empty';
import AntdButton from 'antd/lib/button';
import AntdMessage from 'antd/lib/message';

import Admin from 'hive-admin';

import {
  asLocalStorageField,
} from 'hive-admin/src/components/FieldLocalStorage';

import tests from '../../helpers/tests';
import clickAnchor from '../../helpers/clickAnchor';

import Types from '../../common/modules/types';

import copyToClipboard from '../../helpers/copyToClipboard';

import {
  fieldKind,
  fieldOrganization,
} from '../common';

import {
  ALL as ALL_BRAND_FIELDS,
} from '../brands/fields';

import Preview from '../../components/Preview';
import Report from '../../components/Report';

const viewerIsAdminOrContentManager = Admin.compileFromLibrary(
  tests.viewerIsAdminOrContentManager,
);

const isViewerCustomerWhichCanManageVisuals = Admin.compileFromLibrary(
  tests.isViewerCustomerWhichCanManageVisuals,
);

const { Types: { ObjectId } } = mongoose;

const MetricStars = styled(AntdRate)`
  .ant-rate-star.ant-rate-star-full {
    color: ${({ theme }) => theme.less.primaryColor};
  }
`;

// const activityNotCreateNorStatusNew = (
//   props => props.activity !== 'create' || props.data?.status !== 'NEW'
// );

export const fieldName = ['FieldText', {
  name: 'name',
  rules: [['validation.isRequired']],
}];

export const fieldCountry = ['FieldSelect', {
  name: 'country',
  placeholder: 'Country (leave empty to inherit from the Organization)',
  allowClear: true,
  showArrow: true,
  showSearch: true,
  optionFilterProp: 'children',
  disabled: [tests.isNotCreateActivity],
  virtal: [tests.isNotCreateActivity],
  choices: [
    { label: 'N/A', value: null },
    ...Types.COUNTRIES.map(({ name, iso3a2 }) => ({
      label: name,
      value: iso3a2,
    })),
  ],
}];

export const fieldTeams = ['FieldTable', {
  name: 'teams',
  table: {
    showHeader: false,
    scroll: { x: '370px' },
  },
  skip: [props => props.activity !== 'edit'],
  controls: 'simple',
  controlsGetNewItem: () => ({
    _id: `${ObjectId()}`,
  }),
  columns: [{
    key: 'name',
    title: 'Team Name',
    fields: [{
      name: 'name',
      props: {
        size: 'default',
        placeholder: 'Team Name',
      },
    }],
  }],
}];

export const fieldImage = ['FieldUploadRefsHorizontal', {
  name: 'image',
  label: 'Upload Creative',
  maxCount: 1,
  accept: 'image/png,image/jpeg,image/jpg',
  listType: 'picture',
  transformations: [
    ['GM', {
      command: 'compress',
      quality: 90,
      maxWidth: 2000,
      maxHeight: 2000,
    }],
  ],
  // disabled: [activityNotCreateNorStatusNew],
  // hidden: [activityNotCreateNorStatusNew],
}];

export const fieldImageDisplay = ['FieldUploadRefsHorizontal', {
  name: 'imageDisplay',
  label: 'Upload Additional (eg. Display Side Graphics)',
  maxCount: 1,
  accept: 'image/png,image/jpeg,image/jpg',
  listType: 'picture',
  skipRules: true,
  skip: [[
    'condition.testFieldValue',
    'marketingType',
    '!==',
    'DISPLAY',
  ]],
  transformations: [
    ['GM', {
      command: 'compress',
      quality: 90,
      maxWidth: 2000,
      maxHeight: 2000,
    }],
  ],
  // disabled: [activityNotCreateNorStatusNew],
  // hidden: [activityNotCreateNorStatusNew],
}];

export const [
  fieldMarketingType,
  fieldMarketingTypeSpecify,
  fieldGoal,
  fieldGoalSpecify,
  fieldPromotionType,
  fieldPromotionTypeSpecify,
  fieldSeason,
  fieldSeasonSpecify,
] = [
  ['marketingType', 'Marketing Type', Types.VISUAL_MARKETING_TYPE_LIST],
  ['goal', 'Goal', Types.VISUAL_GOAL_LIST],
  [
    'promotionType',
    'Promotion Type',
    Types.VISUAL_PROMOTION_TYPE_LIST,
    ['OTHER', 'DISCOUNT'],
    [[
      'condition.testFieldValue',
      'goal',
      '!==',
      'PROMOTION',
    ]],
  ],
  ['season', 'Season', Types.VISUAL_SEASON_LIST],
].reduce(
  (agr, [name, label, choices, disabledSpecify = ['OTHER'], skip = []]) => {
    agr.push(['FieldSelect', {
      name,
      label,
      allowClear: true,
      choices: choices.map(({ id, label: choiceLabel }) => ({
        label: choiceLabel,
        value: id,
      })),
      skip,
      col: { xs: 24, md: 12 },
    }]);
    agr.push(['FieldText', {
      name: `${name}Specify`,
      label: 'Specify',
      disabled: [
        props => !disabledSpecify.includes(props.form.getFieldValue(name)),
      ],
      virtual: [
        props => !disabledSpecify.includes(props.form.getFieldValue(name)),
      ],
      skip,
      skipRules: true,
      col: { xs: 24, md: 12 },
    }]);
    return agr;
  },
  [],
);

const RenderInputWrapper = React.forwardRef(
  ({ addNewBrandActionRender, children, ...props }, ref) => (
    <>
      {addNewBrandActionRender}
      {React.cloneElement(children, { ref, ...props })}
    </>
  ),
);

export const fieldBrand = ['FieldConnectionSelect', {
  name: 'brand',
  label: 'Brand',
  url: '/brands',
  searchPaths: ['name'],
  renderChoices: (field, props) => ([
    ...props.choices.map(
      choice => props.renderChoice(choice, field, props)
    ),
    (
      <props.choiceClass
        key="_CREATE_NEW_"
        value={null}
        onClick={({ domEvent }) => {
          domEvent.preventDefault();
          domEvent.stopPropagation();
          props.form.addNewButtonActionRef.current
          .toggleModalOpen(
            true,
          );
        }}
      >
        Create New Brand
      </props.choiceClass>
    ),
  ]),
  renderInput: (inputProps, extras, props, input) => {
    if (!props.form?.addNewButtonActionRef) {
      props.form.addNewButtonActionRef = React.createRef();
    }
    if (!props.form?.addNewButtonActionSearchValueRef) {
      props.form.addNewButtonActionSearchValueRef = React.createRef('');
    }
    if (typeof input.state?.search === 'string') {
      const search = input.state.search.trim();
      if (search.length) {
        props.form.addNewButtonActionSearchValueRef.current = search;
      }
    }
    return (
      <RenderInputWrapper
        addNewBrandActionRender={(
          props.addNewBrandAction.render({
            modalProps: {
              centered: true,
              closable: true,
              destroyOnClose: true,
            },
            form: props.form,
            client: props.client,
            viewer: props.viewer,
            activity: 'create',
            addNewButtonActionRef: (
              props.form.addNewButtonActionRef
            ),
            addNewButtonActionSearchValueRef: (
              props.form.addNewButtonActionSearchValueRef
            ),
            data: {
              name: props.form.addNewButtonActionSearchValueRef?.current,
              kind: props.data?.kind,
              organization: props.data?.organization,
            },
          })
        )}
      >
        {
          React.cloneElement(
            input.renderInput(inputProps, extras, props, input),
            {
              notFoundContent: (
                (
                  viewerIsAdminOrContentManager(props)
                  || isViewerCustomerWhichCanManageVisuals(props)
                )
                ? (
                    <AntdEmpty>
                      <AntdButton
                        type="primary"
                        icon="plus"
                        style={{ marginBottom: '15px' }}
                        onClick={() => {
                          props.form.addNewButtonActionRef.current
                          .toggleModalOpen(
                            true,
                          );
                        }}
                      >
                        Create New Brand
                      </AntdButton>
                    </AntdEmpty>
                  )
                : null
              ),
            },
          )
        }
      </RenderInputWrapper>
    );
  },
  addNewBrandAction: Admin.compileFromLibrary(['ActionWithFormModal', {
    title: 'Create New Brand',
    fields: [
      ...ALL_BRAND_FIELDS.map(
        (field) => (
          field[1].name === 'name'
          ? [field[0], {
              ...field[1],
              label: null,
              prefix: 'Name:',
            }]
          : field
        ),
      ),
    ],
    renderAction: (props, action) => {
      if (props.parentForm?.addNewButtonActionRef) {
        props.parentForm.addNewButtonActionRef.current = action;
      }
      return action.renderAction({ ...props, style: { display: 'none' } });
    },
    actions: [
      ['ActionWithFormBasedRequest', {
        name: 'create-brand',
        title: 'Create',
        messageWorking: 'Creating...',
        messageSuccess: 'Created!',
        disabled: [
          ['condition.isLoading'],
          ['condition.and', [
            ['not', ['condition.anyFieldTouched']],
            ({ searchParams, data }) => !searchParams.duplicate || !data,
          ]],
          ['condition.anyFieldHasError'],
        ],
        handleSuccess: (data, props) => {
          props.parentForm?.setFields?.({
            brand: {
              value: data._id,
              touched: true,
            },
          });
          props.modalAction?.toggleModalOpen?.(false);
        },
        getRequestConfig: (props, data) => {
          const organization = props.viewer?.organization;
          return {
            url: 'brands',
            method: 'POST',
            data: {
              name: data.name,
              kind: organization ? 'PRIVATE' : data.kind,
              organization: organization || data.organization || null,
            },
          };
        },
      }],
    ],
  }]),
  getExtraQueryConditions: (props) => {
    const { organization } = props.data;
    return [{
      organization: {
        IN: organization ? [organization, null] : [null],
      },
    }];
  },
}];

export const fieldCategory = ['FieldConnectionSelect', {
  name: 'category',
  label: 'Category',
  url: '/categories',
  searchPaths: ['name'],
}];

export const fieldRetailer = ['FieldConnectionSelect', {
  name: 'retailer',
  label: 'Retailer (optional)',
  url: '/retailers',
  searchPaths: ['name'],
  skipRules: true,
}];

export const fieldVisualSize = ['FieldSelect', {
  name: 'size',
  label: 'Visual Size',
  choices: Types.VISUAL_SIZE_LIST.map(({ id, label: choiceLabel }) => ({
    label: choiceLabel,
    value: id,
  })),
  col: { xs: 24, md: 12 },
}];

export const fieldSentiment = ['FieldText', {
  name: 'sentiment',
  label: 'What is the overall sentiment of the visual?',
  col: { xs: 24, md: 12 },
}];

export const fieldIncludesAnimals = ['FieldSwitch', {
  name: 'includesAnimals',
  label: 'Does the visual include animals?',
  col: { xs: 24, md: 8 },
}];

export const fieldIncludesPeople = ['FieldText', {
  name: 'includesPeople',
  label: 'Does the visual include people?',
  help: 'Leave the value at 0 if no people are shown',
  initialValue: 0,
  col: { xs: 24, md: 8 },
}];

export const fieldShownAtTimeOfUse = ['FieldSwitch', {
  name: 'shownAtTimeOfUse',
  label: 'Is the product shown at time of use?',
  initialValue: false,
  col: { xs: 24, md: 8 },
}]

export const fieldColors = ['FieldSortableList', {
  name: 'colors',
  fields: [
    ['FieldColorPicker', {
      name: 'color',
      col: { xs: 24, md: 12 },
      rules: [[(config = {}) => ({
        validator: (rule, value, cb) => (cb(
          (!value || !value.length)
            ? undefined
            : /^#([0-9A-F]{3}){1,2}$/i.test(value)
              ? undefined
              : rule.message
        )),
        message: 'Color must be a valid HEX String (eg. #C58DB8)',
        ...config,
      })]],
    }],
    ['FieldText', {
      name: 'percentage',
      addonAfter: '%',
      col: { xs: 24, md: 12 },
    }],
  ],
}];

export const [
  fieldVisibilityScore,
  fieldEngagementScore,
] = [
  ['visibilityScore'],
  ['engagementScore'],
].map(([name]) => {
  const reportValueConfig = Types.REPORT_VALUES_MAP[name];
  return ['FieldRadio', {
    name,
    label: reportValueConfig.label,
    col: { xs: 24, md: 12 },
    block: true,
    help: reportValueConfig.note || reportValueConfig.description,
    choices: [1, 2, 3, 4, 5].map(value => ({
      label: value,
      value,
    })),
  }];
});

export const [
  fieldPromotionVisibility,
  fieldProductExposure,
  fieldCtaVisibility,
  fieldKeyVisual,
  fieldTextBalance,
  fieldVisualSimplicity,
  fieldShoppingExperienceScore,
  fieldBrandingScore,
] = [
  ...[
    ['promotionVisibility'],
    ['productExposure'],
    ['ctaVisibility'],
    ['keyVisual'],
    // ['textHeaviness'],
    ['textBalance'],
    // ['visualComplexity'],
    ['visualSimplicity'],
    ['shoppingExperienceScore'],
    ['brandingScore'],
  ].map(([name, col = 12, extraConfig = {}]) => (['FieldText', {
    name,
    type: 'number',
    col: { xs: 24, md: col },
    getFormItemConfig: (fieldConfig, formProps) => {
      // const typeObject = Types.REPORT_VALUES_MAP[name];
      const value = formProps.form.getFieldValue(name);
      // const starCount = typeObject.getScaleLength(
      //   formProps.data.marketingType,
      // );
      // const starValue = typeObject.getScaledValue(
      //   value,
      //   formProps.data.marketingType,
      // );
      return {
        ...(fieldConfig.formItemConfig || {}),
        extra: (
          <MetricStars
            disabled
            count={5}
            value={value || 0}
          />
        ),
      };
    },
    rules: [
      [(config = {}) => ({
        type: 'number',
        min: 0,
        max: 5,
        message: 'Metric can only be integers between 0 and 5',
        ...config,
      })],
    ],
    ...extraConfig,
  }])),
  // ['FieldRadio', {
  //   name: 'shoppingExperienceScore',
  //   block: true,
  //   choices: [1, 2, 3, 4, 5].map(value => ({
  //     label: value,
  //     value,
  //   })),
  //   col: { xs: 24, md: 12 },
  // }],
].map(([thisFieldName, thisFieldConfig = {}]) => {
  const reportValueConfig = Types.REPORT_VALUES_MAP[thisFieldConfig.name];
  return [thisFieldName, {
    ...thisFieldConfig,
    label: reportValueConfig.label,
    help: reportValueConfig?.note || reportValueConfig?.description,
  }];
});

export const fieldImprovementSuggestions = ['FieldSortableList', {
  name: 'improvementSuggestions',
  label: null,
  // label: 'Improvement Suggestions',
  fields: [
    ['FieldText', {
      name: 'issue',
      label: 'Issue',
    }],
    ['FieldTextArea', {
      name: 'suggestion',
      label: 'How to improve?',
      autoSize: { minRows: 2 },
    }],
  ],

  ActionsWrapper: styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 12px;
    [data-is-tablet="true"] & {
      flex-wrap: wrap;
    }
  `,

  ActionsWrapperSide: styled.div`
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
    [data-is-tablet="true"] & {
      width: 100%;
      flex-direction: column;
      > .ant-btn {
        width: 100%;
      }
    }
  `,

  generateImprovementSuggestions: [
    ['generate', 'Generate'],
    ['more', 'Load More'],
  ].reduce(
    (agr, [name, title]) => {
      agr[name] = Admin.compileFromLibrary(
        ['ActionWithRequest', {
          name: `generateImprovementSuggestions-${name}`,
          title,
          ghost: true,
          // eslint-disable-next-line arrow-body-style
          getRequestConfig: (props) => {
            props.setImprovementsGenerating(true);
            return {
              url: `visuals/${
                props.data._id
              }/actions/generate-improvement-suggestions`,
              method: 'POST',
              data: {
                action: name,
                previousMessages: (
                    name === 'generate'
                  ? []
                  : props.previousMessages
                ),
                data: Types.REPORT_VALUES_LIST.reduce(
                  (dataAgr, item) => {
                    dataAgr[item.id] = props.form.getFieldValue(item.id);
                    return dataAgr;
                  },
                  {},
                ),
              },
            };
          },
          handleError: (error, props) => {
            props.setImprovementsGenerating(false);
          },
          handleSuccess: (data, props) => {
            props.setImprovementsGenerating(false);
            const { fieldImprovementSuggestions: field } = props;
            if (data?.suggestions?.length) {
              field.addItem(...data.suggestions.map(improvementSuggestion => ({
                ...field.props.generateNewItem(field.props, field),
                ...improvementSuggestion,
              })));
            }
            props.setPreviousMessages(data.previousMessages);
          },
          disabled: [
            props => (
              props.improvementsGenerating
              || (
                name === 'more'
                && !(props.previousMessages?.length > 0)
              )
            ),
          ],
          hidden: [],
          skip: [
            props => props.data && props.data.status !== 'PROCESSING',
            tests.viewerIsNotAdminNorContentManager,
          ],
        }],
      );
      return agr;
    },
    {},
  ),

  renderAddButton: (props, self, key, extras = {}) => {
    const {
      generateImprovementSuggestions: actions,
      ActionsWrapper,
      ActionsWrapperSide,
    } = props;
    const extraProps = {
      improvementsGenerating: self.state.improvementsGenerating === true,
      previousMessages: self.state.previousMessages || [],
      setPreviousMessages: (previousMessages) => self.setState({
        previousMessages,
      }),
      setImprovementsGenerating: (improvementsGenerating) => self.setState({
        improvementsGenerating,
      }),
    };
    return (
      <ActionsWrapper>
        <ActionsWrapperSide>
          <AntdButton
            icon="plus"
            data-action="addCustomSuggestion"
            {...extras}
          >
            {props.addButtonLabel}
          </AntdButton>
          {actions.generate.render({
            client: props.client,
            viewer: props.viewer,
            form: props.form,
            data: props.data,
            fieldImprovementSuggestions: self,
            ...extraProps,
          })}
          {actions.more.render({
            client: props.client,
            viewer: props.viewer,
            form: props.form,
            data: props.data,
            fieldImprovementSuggestions: self,
            ...extraProps,
          })}
        </ActionsWrapperSide>
        <ActionsWrapperSide>
          <AntdButton
            key="clear"
            ghost
            type="danger"
            data-action="clear"
            onClick={() => self.onChange([])}
          >
            Clear
          </AntdButton>
        </ActionsWrapperSide>
      </ActionsWrapper>
    );
  },
  skipRules: true,
}];

export const ALL = [
  fieldName,
];

export const CREATE = [
  fieldName,
  fieldCountry,
  fieldKind,
  fieldOrganization,
];

export const CONTENT = [
  fieldMarketingType,
  fieldMarketingTypeSpecify,
  fieldGoal,
  fieldGoalSpecify,
  fieldPromotionType,
  fieldPromotionTypeSpecify,
  fieldSeason,
  fieldSeasonSpecify,
];

export const NEW = [
  ['FieldReact', {
    name: 'preview',
    renderContent: props => (
      <Preview
        value={props.form.getFieldValue('marketingType') || 'INITIAL'}
      />
    ),
  }],
  ...[
    fieldName,
    ...CONTENT,

    fieldBrand,
    fieldCategory,
    fieldRetailer,

  // ['FieldTitle', { title: 'Media' }],
    fieldImage, // TODO another image when type is display
    fieldImageDisplay,
  ].map(([fieldType, fieldConfig]) => (
    fieldType === 'FieldTitle' || fieldConfig.skipRules
    ? [fieldType, fieldConfig]
    : [
        fieldType,
        {
          ...fieldConfig,
          rules: [
            ...(fieldConfig.rules || []),
            ['validation.isRequired'],
          ],
        },
      ]
  )),
];

const anyoneIsAnnotating = (props) => (
  props.data.annotationsSession
  ? true
  : false
);

const viewerIsAnnotating = (props) => (
  props.data.annotationsSession
  && (
    (
      props.data.annotationsSession?.user?._id
      || props.data.annotationsSession?.user
    ) === props.viewer._id
  )
);

const viewerIsNotAnnotating = (props) => (
  !props.data.annotationsSession
  || props.viewer._id !== props.data.annotationsSession?.user
);

const someoneElseIsAnnotating = (props) => (
  anyoneIsAnnotating(props)
  && viewerIsNotAnnotating(props)
);

// eslint-disable-next-line max-len
const viewerIsNotAdminNorContentManagerAndViewerIsNotAnnotatingOrViewerIsAnnotating = (
  props
) => (
  (
    (
      props.viewer?.role !== 'ADMIN'
      && props.viewer?.role !== 'CONTENT_MANAGER'
    )
    && viewerIsNotAnnotating(props)
  ) || viewerIsAnnotating(props)
);

export const PROCESSING = [
  ...[
    [fieldImage[0], {
      ...fieldImage[1],
      hidden: [
        // ...(fieldImage[1].hidden || []),
        () => true,
      ],
      virtual: [
        // ...(fieldImage[1].virtual || []),
        () => true,
      ],
      skipRules: true,
    }],

    [fieldImageDisplay[0], {
      ...fieldImageDisplay[1],
      hidden: [
        // ...(fieldImageDisplay[1].hidden || []),
        () => true,
      ],
      virtual: [
        // ...(fieldImageDisplay[1].virtual || []),
        () => true,
      ],
      skipRules: true,
    }],

    ['FieldReact', {
      name: 'imagesPreview',
      virtual: [() => true],
      Wrapper: styled.div`
        display: flex;
        gap: 15px;
        height: 400px;
      `,
      ImageWrapper: styled.div`
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      `,
      ImageWithZoomWrapper: styled.div`
        display: flex;
        gap: 10px;
        background: hsla(0, 0%, 0%, 0.03);
        border: 1px solid transparent;
        border-bottom: 0px;
        border-color: ${({ theme }) => theme.less.borderColor};
        border-radius: ${({ theme }) => theme.less.borderRadius};
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        overflow: hidden;
        width: 100%;
        height: 100%;
      `,
      ImageWithZoom: styled(InnerImageZoom)`
        width: 100%;
        object-fit: contain;
        position: relative;
        > div {
          width: 100%;
          height: 100%;
          img.iiz__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      `,
      ImageComponent: ({
        image,
        ImageWrapper,
        ImageWithZoomWrapper,
        ImageWithZoom,
        restBaseServer,
        client,
      }) => {
        const zoomScale = useMemo(
          () => Math.min(
            1,
            1200 / Math.max(image.meta.width, image.meta.height),
          ),
          [image],
        );
        const actionDownloadImage = useMemo(
          () => Admin.compileFromLibrary(
            ['Action', {
              name: 'actionDownloadImage',
              title: 'Download',
              icon: 'download',
              type: 'default',
              style: {
                width: '100%',
                flexShrink: 0,
                borderTopRightRadius: '0px',
                borderTopLeftRadius: '0px',
              },
              onClick: (props) => clickAnchor({
                url: `${restBaseServer}/files/${image._id}/download`,
                query: {
                  access_token: props.client.getAccessToken(),
                },
              }),
            }],
          ),
          [restBaseServer, image],
        );
        return (
          <ImageWrapper>
            <ImageWithZoomWrapper>
              <ImageWithZoom
                src={image.src}
                alt={image.meta.filename}
                zoomScale={zoomScale}
                hideHint
              />
            </ImageWithZoomWrapper>
            {actionDownloadImage.render({ client })}
          </ImageWrapper>
        );
      },
      renderContent: (props) => {
        const {
          Wrapper,
          ImageWrapper,
          ImageWithZoomWrapper,
          ImageWithZoom,
          ImageComponent,
        } = props;
        const children = [
          props.form.getFieldValue('image'),
          props.form.getFieldValue('imageDisplay'),
        ].reduce(
          (agr, image) => {
            if (image?.src) {
              agr.push(
                <ImageComponent
                  key={image._id}
                  image={image}
                  client={props.client}
                  restBaseServer={props.restBaseServer}
                  ImageWrapper={ImageWrapper}
                  ImageWithZoomWrapper={ImageWithZoomWrapper}
                  ImageWithZoom={ImageWithZoom}
                />
              );
            }
            return agr;
          },
          [],
        );
        if (children.length) {
          return (
            <Wrapper>
              {children}
            </Wrapper>
          );
        }
        return null;
      },
      skipRules: true,
    }],

    ['FieldTitle', {
      title: 'Annotations',
      skip: [tests.viewerIsNotAdminNorContentManager],
    }],

    ['ActionWithRequest', {
      name: 'actionStartAnnotating',
      title: 'Start Annotating',
      // eslint-disable-next-line no-unused-vars
      getActionTitle: (props, action) => {
        if (someoneElseIsAnnotating(props)) {
          return (
            <>
              Take Over Annotation Session
              <span
                style={{
                  display: 'block',
                  fontSize: '80%',
                  marginTop: '-2px',
                }}
              >
                {`from ${
                  props.data.annotationsSession?.user?.name || 'another user'
                }`}
              </span>
            </>
          );
        }
        return 'Start Annotating';
      },
      // eslint-disable-next-line max-len
      skip: [viewerIsNotAdminNorContentManagerAndViewerIsNotAnnotatingOrViewerIsAnnotating],
      disabled: [],
      getRequestConfig: (props) => ({
        url: `visuals/${props.data._id}/actions/start-annotating`,
        data: {},
        method: 'POST',
      }),
      handleSuccess: (data, props) => props.reload(),
      type: 'default',
      style: {
        width: '100%',
        height: 'auto',
        minHeight: '32px',
        paddingTop: '6px',
        paddingBottom: '6px',
      },
      skipRules: true,
    }],

    ['FieldAnnotationsModal', {
      label: null,
      name: 'annotations',
      skip: [viewerIsNotAnnotating],
      virtual: [() => false],
      skipRules: true,
    }],

    ['Action', {
      name: 'actionCopyAnnotationsExport',
      title: 'Export',
      skip: [viewerIsNotAnnotating],
      onClick: (props) => {
        const visual = {
          ...props.data,
          ...props.form.getFieldsValue(),
        };
        visual.marketingKind = Types.getMarketingKind(visual.marketingType);
        Object.assign(visual, Types.getSpecifiedLabels(visual));
        const errors = Types.getAllAnnotationErrors(
          visual.annotations,
          visual,
          allAnnotations => (
            allAnnotations.filter(annotation => !annotation.contentKey)
          ),
        );
        if (!errors.count) {
          const annotationsExport = Types.getAnnotationsExport(visual);
          copyToClipboard(JSON.stringify(annotationsExport, null, '  '));
          return;
        }
        // eslint-disable-next-line no-console
        console.log('errors on actionCopyAnnotationsExport:', errors);
        // eslint-disable-next-line max-len
        AntdMessage.error('Some annotations are missing or invalid. Please check and try again');
      },
      type: 'default',
      style: { width: '100%' },
      col: { xs: 24, md: 24 },
      skipRules: true,
    }],

    // fieldSentiment,
    // fieldVisualSize,
    // fieldIncludesPeople,
    // fieldIncludesAnimals,
    // fieldShownAtTimeOfUse,

    // fieldColors,

    asLocalStorageField(['FieldTitleToggle', {
      name: '_titleContent',
      title: 'Content',
      // subtitle: 'Setup visual content',
      initialValue: false,
      virtual: [() => true],
    }]),
    ...CONTENT.map(([fieldType, fieldConfig]) => (
      fieldType === 'FieldTitle' // || fieldConfig.skipRules
      ? [fieldType, fieldConfig]
      : [
          fieldType,
          {
            ...fieldConfig,
            hidden: [
              ...(fieldConfig.hidden || []),
              ['condition.fieldIsNotTrue', '_titleContent'],
            ],
          },
        ]
    )),

    asLocalStorageField(['FieldTitle', {
      name: '_titleProcess',
      title: '',
      // subtitle: 'Setup visual content',
      initialValue: false,
      virtual: [() => true],
    }]),

    ['ActionWithRequest', {
      name: 'actionProcess',
      title: 'Start Processing',
      skip: [viewerIsNotAnnotating],
      ghost: false,
      type: 'primary',
      disabled: [],
      getRequestConfig: (props) => {
        const visual = {
          ...props.data,
          ...props.form.getFieldsValue(),
        };
        visual.marketingKind = Types.getMarketingKind(visual.marketingType);
        Object.assign(visual, Types.getSpecifiedLabels(visual));
        const errors = Types.getAllAnnotationErrors(
          visual.annotations,
          visual,
          allAnnotations => (
            allAnnotations.filter(annotation => !annotation.contentKey)
          ),
        );
        if (!errors.count) {
          return {
            url: `visuals/${props.data._id}/actions/process`,
            data: visual,
            method: 'POST',
          };
        }
        // eslint-disable-next-line max-len
        const errorMessage = 'Some annotations are missing or invalid. Please check and try again';
        AntdMessage.error(errorMessage);
        throw new Error(errorMessage);
      },
      style: { width: '100%' },
      ProcessingTraceWrapper: styled.code`
        display: inline-block;
        width: 100%;
        font-size: 70%;
        text-align: left;
        padding-top: 15px;
      `,
      handleSuccess: (data, props) => {
        props.reload()
        // try {
        //   const json = data;
        //   const values = {};
        //   Types.REPORT_VALUES_LIST.forEach((item) => {
        //     const { id: importKey } = item;
        //     if (importKey) {
        //       let value = json[importKey];
        //       if (typeof value === 'string') {
        //         value = Math.round(
        //           parseFloat(value.replace(/[^.0-9]/g, ''), 10),
        //         );
        //       } else if (typeof value === 'number') {
        //         value = Math.round(
        //           parseFloat(value, 10),
        //         );
        //       }
        //       if (Number.isFinite(value)) {
        //         values[item.id] = value;
        //       }
        //     }
        //   });
        //   props.form.setFields(Object.keys(values).reduce(
        //     (agr, key) => {
        //       agr[key] = {
        //         value: values[key],
        //         touched: true,
        //       };
        //       return agr;
        //     },
        //     {},
        //   ));
        //   props.form.validateFields();
        // } catch (error) {
        //   Admin.showMessage('Invalid JSON', 'error', 3);
        // }
      },
      getMessageError: (error, props) => {
        const { ProcessingTraceWrapper } = props;
        const processingTrace = error.response?.data?.meta?.processingTrace;
        return (
          <>
            <span>
              {error.message}
            </span>
            {
              processingTrace?.length
              ? (
                  <ProcessingTraceWrapper>
                    <pre>
                      {processingTrace}
                    </pre>
                  </ProcessingTraceWrapper>
                )
              : null
            }
          </>
        );
      },
      col: { xs: 24, md: 24 },
      skipRules: true,
    }],

    ['FieldTitleToggle', {
      name: '_titleMetrics',
      title: 'Metrics',
      initialValue: true,
      virtual: [() => true],
    }],
    ...[
      fieldPromotionVisibility,
      fieldProductExposure,
      fieldCtaVisibility,
      fieldKeyVisual,
      fieldTextBalance,
      fieldVisualSimplicity,
      fieldBrandingScore,
      fieldShoppingExperienceScore,
    ].map(([fieldType, fieldConfig]) => (
      fieldType === 'FieldTitle' // || fieldConfig.skipRules
      ? [fieldType, fieldConfig]
      : [
          fieldType,
          {
            ...fieldConfig,
            hidden: [
              ...(fieldConfig.hidden || []),
              ['condition.fieldIsNotTrue', '_titleMetrics'],
            ],
          },
        ]
    )),

    ['FieldTitleToggle', {
      name: '_titleScores',
      title: 'Scores',
      initialValue: true,
      virtual: [() => true],
    }],
    ...[
      // fieldShopnosisScore,
      fieldVisibilityScore,
      fieldEngagementScore,
    ].map(([fieldType, fieldConfig]) => (
      fieldType === 'FieldTitle' // || fieldConfig.skipRules
      ? [fieldType, fieldConfig]
      : [
          fieldType,
          {
            ...fieldConfig,
            hidden: [
              ...(fieldConfig.hidden || []),
              ['condition.fieldIsNotTrue', '_titleScores'],
            ],
          },
        ]
    )),

    ['FieldTitleToggle', {
      name: '_titleSuggestions',
      title: 'Suggestions',
      virtual: [() => true],
      initialValue: true,
    }],
    ...[
      fieldImprovementSuggestions,
    ].map(([fieldType, fieldConfig]) => (
      fieldType === 'FieldTitle' // || fieldConfig.skipRules
      ? [fieldType, fieldConfig]
      : [
          fieldType,
          {
            ...fieldConfig,
            hidden: [
              ...(fieldConfig.hidden || []),
              ['condition.fieldIsNotTrue', '_titleSuggestions'],
            ],
          },
        ]
    )),
  ].map(([fieldType, fieldConfig]) => (
    fieldType === 'FieldTitle' || fieldConfig.skipRules
    ? [fieldType, fieldConfig]
    : [
        fieldType,
        {
          ...fieldConfig,
          rules: [
            ...(fieldConfig.rules || []),
            ['validation.isRequired'],
          ],
        },
      ]
  )),
  ['FieldText', {
    name: '_fillReportFromJsonSaveTrigger',
    label: null,
    formItemConfig: {
      style: { display: 'none' },
    },
    virtual: [() => true],
  }],
];

export const REPORT = [
  ['FieldReact', {
    name: 'report',
    renderContent: (props) => (
      <Report
        data={props.data}
        isTablet={props.isTablet}
        viewer={props.viewer}
      />
    ),
  }],
];
