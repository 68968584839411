import {
  cloneItem,
  filterSort,
  filterPagination,
  filterSearch,
  filterPopulateDepartment,
  filterLibraryExportCsv,
} from '../common';

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  ...[
    [filterSearch, 12],
    [[filterSort[0], { ...filterSort[1], buildQueryDefault: 'name' }], 12],
  ].map((config) => cloneItem(...config)),
  filterLibraryExportCsv,
  filterPopulateDepartment,
  filterPagination,
];
