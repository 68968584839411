import React from 'react';
import styled from 'styled-components';

import { Title, Body } from './Text';
import { Space } from './Layout';

const BodyTitle = styled(Body)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Score = ({ score, scale, title, ...props }) => (
  <Wrapper {...props}>
    <Title>{score}</Title>
    <Title as="h2">{`/${scale}`}</Title>
    <Space $width={5} />
    <Body>{title}</Body>
  </Wrapper>
);

export const ScoreTitle = ({ title, value, ...props }) => (
  <Wrapper {...props}>
    <Title className="score-title">{title}</Title>
    <Space $width={5} />
    <BodyTitle className="score-body-title">{value}</BodyTitle>
  </Wrapper>
);

export default Score;
