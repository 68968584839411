import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import AntdSlider from 'antd/lib/slider';

import FieldRadio from 'hive-admin/src/components/FieldRadio';

// import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import { action as compareAction } from '../../components/Compare';

// import copyToClipboard from '../../helpers/copyToClipboard';

import Types from '../../common/modules/types';

import {
  cloneItem,
  filterSearch,
  filterSort,
  filterCountry,
  filterLimitToPublic,
  filterLimitToStatusReport,
  filterLimitToViewerOrganization,
  filterPopulateOrganization,
  filterPopulateBrand,
  filterPopulateCategory,
  filterPopulateDepartment,
  filterPagination,
  getValueForFieldDefault,
} from '../common';

const RadioComponent = styled(FieldRadio.config.Component)`
  display: flex;
  width: 100%;
  > .ant-radio-button-wrapper {
    flex: 1;
    flex-shrink: 1;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: auto;
    line-height: 100%;
    display: flex;
    padding: 8px 5px;
    white-space: nowrap;
  }
`;

export const filterSwitchView = ['FilterField', {
  id: 'view',
  label: null,
  section: 'top',
  getValueForField: (value, props) => {
    if (value) return value;

    switch (props.location.pathname) {
      case '/visuals':
        return 'table';
      case '/visuals/grid':
        return 'grid';
      default:
        return 'table';
    }
  },
  propsFromPage: ({ viewer, history, location }) => ({
    viewer,
    history,
    location,
  }),
  isAction: true,
  handleChange: () => { },
  buildQuery: () => { },
  field: ['FieldRadio', {
    isAction: true,
    name: 'date',
    label: null,
    prepareValueForInput: (val, props) => {
      const { history } = props;
      const visualsPath = '/visuals';
      if (
        val === 'grid'
        && history.location.pathname !== `${visualsPath}/grid`
      ) {
        history.replace(`${visualsPath}/grid`);
      } else if (
        val === 'table'
        && history.location.pathname !== `${visualsPath}`
      ) {
        history.replace(`${visualsPath}`);
      }

      return val;
    },
    defaultValue: 'table',
    initialValue: 'table',
    choices: [{
      label: 'Table View',
      value: 'table',
    }, {
      label: 'Grid View',
      value: 'grid',
    }],
    Component: RadioComponent,
  }],
}];

export const filterStatus = ['FilterField', {
  id: 'status',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => (
    value && value.length && builder.add(
      'where',
      { status: { IN: Array.isArray(value) ? value : [value] } },
    )
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'status',
    placeholder: 'Status',
    mode: 'multiple',
    choices: Types.VISUAL_STATUS_LIST.map(({ id, label }) => ({
      value: id,
      label,
    })),
  }],
}];

export const filterVisualId = ['FilterField', {
  id: 'visua-id',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => {
    if (value?.length) {
      builder.add('where', {
        visualIdSearch: value,
      });
    }
  },
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'visualId',
    placeholder: 'ID Search',
  }],
}];

export const filterSortTracker = ['FilterField', {
  id: 'sort',
  label: null,
  section: 'top',
  VALUES_MAP: {
    name: { name: 1 },
    date: { createdAt: -1 },
    visibility: { visibilityScore: -1, createdAt: -1 },
    engagement: { engagementScore: -1, createdAt: -1 },
  },
  buildQueryDefault: 'date',
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('sort', { ...filter.VALUES_MAP[value] })
    : builder.add('sort', { ...filter.VALUES_MAP[filter.buildQueryDefault] })
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldSelect', {
    name: 'sort',
    placeholder: 'Sort',
    defaultValue: 'date',
    initialValue: 'date',
    prepareValueForInput: (value, props) => value || props.buildQueryDefault,
    choices: [{
      label: 'Sort by date',
      value: 'date',
    }, {
      label: 'Sort by name',
      value: 'name',
    }, {
      label: 'Sort By Stopping Power',
      value: 'visibility',
    }, {
      label: 'Sort By Engagement',
      value: 'engagement',
    }],
  }],
}];

export const filterCategoryGroupTracker = ['FilterField', {
  id: 'category-group',
  label: null,
  section: 'top',
  propsFromPage: props => ({ viewer: props.viewer }),
  buildQuery: (value, builder) => {
    if (value && value.length) {
      builder.add(
        'custom',
        { categoryGroup: value },
      );
    }
  },
  getValueForField: value => (
    value && value.length
    ? value
    : 'all'
  ),
  field: ['FieldRadio', {
    name: 'categoryGroup',
    label: null,
    renderChoices: (field, props) => (
      !props.viewer || props.viewer.role === 'CUSTOMER'
      ? [
          { value: 'all', label: 'All Categories' },
          { value: 'additional', label: 'My Categories' },
          { value: 'trending', label: 'Trending Categories' },
        ]
      : [
          { value: 'all', label: 'All Categories' },
          { value: 'trending', label: 'Trending Categories' },
        ]
    ).map(choice => props.renderChoice(choice, field, props)),
    choices: [],
    block: true,
  }],
}];

export const filterOrganization = ['FilterField', {
  id: 'organization',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { organization: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'organization',
    url: '/organizations',
    placeholder: 'Organization',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterPeriodRange = ['FilterField', {
  id: 'periodRange',
  label: null,
  section: 'top',
  placeholder: ['From Date', 'To Date'],
  propsFromPage: props => ({
    searchParams: props.searchParams,
    history: props.history,
  }),
  ranges: {
    ...[
      ['Today', 0],
      ['Last 7 Days', 7],
      ['Last 30 Days', 30],
      ['Last 60 Days', 60],
      ['Last 90 Days', 90],
      ['Last 180 Days', 180],
    ].reduce(
      (agr, [label, daysOffset = null]) => {
        agr[label] = () => {
          const now = moment();
          return [
            now.clone().subtract(Math.max(0, daysOffset - 1), 'days'),
            now,
          ];
        };
        return agr;
      },
      {},
    ),
    ...[
      ['Week', 'week'],
      ['Month', 'month'],
      ['Year', 'year'],
    ].reduce(
      (agr, [label, value]) => {
        agr[`This ${label}`] = [
          moment().startOf(value),
          moment().endOf(value),
        ];
        agr[`Past ${label}`] = [
          moment().subtract(1, value).startOf(value),
          moment().subtract(1, value).endOf(value),
        ];
        agr[`2 ${label}s Age`] = [
          moment().subtract(2, value).startOf(value),
          moment().subtract(2, value).endOf(value),
        ];
        return agr;
      },
      {},
    ),
  },
  buildQuery: (value, builder, filter, _2, { pageProps }) => {
    // const now = moment();
    const isSideB = filter.id.indexOf('b.') === 0
    const whereKey = isSideB ? 'whereB' : 'where';
    const dateKeyRaw = (
      isSideB
      ? pageProps.searchParams.b?.date
      : pageProps.searchParams.date
    );
    const dateKey = (
      dateKeyRaw === 'completed'
      ? 'completedAt'
      : dateKeyRaw === 'approved'
      ? 'approvedAt'
      : dateKeyRaw === 'shipped'
      ? 'shippedAt'
      : 'createdAt'
    );
    if (
      value
      && value.length
    ) {
      const values = (
        value
        .split(',')
        .map(dateString => (
          moment(dateString, 'YYYY-MM-DD')
          // .hour(now.hour())
          // .minute(now.minute())
          // .second(now.second())
          // .millisecond(now.millisecond())
        ))
      );
      if (
        values.length === 2
        && values[0] && values[0].isValid()
        && values[1] && values[1].isValid()
      ) {
        builder.add(
          whereKey,
          {
            [dateKey]: {
              GTE: values[0].startOf('day').toJSON(),
              LTE: values[1].endOf('day').toJSON(),
            },
          },
        );
        // return;
      }
    }
    // builder.add(
    //   whereKey,
    //   {
    //     [dateKey]: {
    //       GTE: (
    //         filter.defaultDateFrom
    //         ? moment(filter.defaultDateFrom).toJSON()
    //         : now.clone().startOf('month').toJSON()
    //       ),
    //       LTE: (
    //         filter.defaultDateTo
    //         ? moment(filter.defaultDateTo).toJSON()
    //         : now.endOf('day').toJSON()
    //       ),
    //     },
    //   },
    // );
  },
  getValueForField: (value, _props) => {
    const now = moment();
    if (value && value.length) {
      const values = (
        value
        .split(',')
        .map(dateString => (
          moment(dateString, 'YYYY-MM-DD')
          .hour(now.hour())
          .minute(now.minute())
          .second(now.second())
          .millisecond(now.millisecond())
        ))
      );
      if (
        values.length === 2
        && values[0] && values[0].isValid()
        && values[1] && values[1].isValid()
      ) {
        return values;
      }
    }
    return [];
    // return [
    //   (
    //       props.defaultDateFrom
    //     ? moment(props.defaultDateFrom)
    //     : now.clone().startOf('month')
    //   ),
    //   (
    //       props.defaultDateTo
    //     ? moment(props.defaultDateTo)
    //     : now.startOf('day')
    //   ),
    // ];
  },
  getValueForQuery: (value) => {
    if (
      Array.isArray(value)
      && value.length === 2
      && value[0] && value[0].isValid()
      && value[1] && value[1].isValid()
    ) {
      // const now = moment();
      return `${
        value[0].format('YYYY-MM-DD')
      },${
        value[1].format('YYYY-MM-DD')
      }`;
    }
    return undefined;
  },
  // skip: [
  //   props => props.searchParams['period-span'] !== 'custom',
  // ],
  field: ['FieldDateRangePicker', {
    id: 'periodRange',
    name: 'periodRage',
    format: 'll',
    showToday: false,
    separator: '-',
    // skip: [
    //   props => props.searchParams?.['period-span'] !== 'custom',
    // ],
  }],
}];

export const filterRetailer = ['FilterField', {
  id: 'retailer',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { retailer: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'retailer',
    url: '/retailers',
    placeholder: 'Retailer',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterCategory = ['FilterField', {
  id: 'category',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { category: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'category',
    url: '/categories',
    placeholder: 'Category',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterCategoryTracker = ['FilterField', {
  id: 'category',
  label: null,
  section: 'top',
  propsFromPage: props => ({
    client: props.client,
    searchParams: props.searchParams,
  }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { category: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'category',
    url: '/categories/tracker',
    placeholder: 'Category',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    getExtraQueryProps: props => ({
      sort: props.extraQuerySortProps,
      categoryGroup: props.searchParams?.['category-group'],
      ...(props.extraQueryProps || {}),
    }),
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterBrandVisuals = ['FilterField', {
  id: 'brand',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client, viewer: props.viewer }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { brand: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'brand',
    url: '/brands',
    placeholder: 'Brand',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    getExtraQueryConditions: (props) => {
      const { organization } = props.viewer?.meta || {};
      if (organization) {
        return [{ organization: { IN: [organization, null] } }];
      }
      return [];
    },
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterBrandTracker = ['FilterField', {
  id: 'brand',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { brand: { IN: Array.isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'brand',
    url: '/brands',
    placeholder: 'Brand',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    getExtraQueryConditions: () => [{ organization: { EQ: null } }],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterPopulateAnnotationsSessionUser = ['FilterHidden', {
  id: 'populate-annotationsSession-user',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { 'annotationsSession.user': true });
  },
  skip: [tests.viewerIsNotAdminNorContentManager],
}];

const SliderWrapper = styled.div`
  width: 100%;
`;

const SliderLabel = styled.div`
  opacity: 0.5;
  margin: 10px 0 -5px;
`;

const SliderComponent = styled(AntdSlider)`
  .ant-slider-track {
    background-color: ${({ theme }) => theme.less.primaryColor};
  }
  .ant-slider-handle, .ant-slider-dot-active {
    border-color: ${({ theme }) => theme.less.primaryColor} !important;
  }
`;

const Slider = ({
  label,
  value,
  onChange,
}) => {
  value = useMemo(
    () => value || [1, 5],
    [value],
  );
  const [tempValue, setTempValue] = useState(value);
  useEffect(
    () => { setTempValue(value); },
    [value],
  );
  return (
    <SliderWrapper>
      <SliderLabel>
        {label}
      </SliderLabel>
      <SliderComponent
        tipFormatter={tipValue => (
          Types.SCORE_VALUE_DESCRIPTIONS_MAP[tipValue].label
        )}
        range
        min={1}
        max={5}
        step={1}
        marks={{
          1: '1',
          2: '2',
          3: '3',
          4: '4',
          5: '5',
        }}
        value={tempValue}
        onChange={setTempValue}
        onAfterChange={newValue => onChange(newValue.join(','))}
      />
    </SliderWrapper>
  )
};

export const [
  filterStoppingPower,
  filterEngagement,
] = [
  ['visibilityScore', 'Stopping Power', 'visibility'],
  ['engagementScore', 'Engagement', 'engagement'],
].map(([name, label, key]) => ['FilterField', {
  id: key,
  // label: 'Stopping Power',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    if (typeof value === 'string' && value.includes(',')) {
      value = value.split(',').map(str => parseInt(str, 10));
    }
    if (value && value.length === 2) {
      builder.add(
        'where',
        { [name]: { GTE: parseInt(value[0], 10) } },
      );
      builder.add(
        'where',
        { [name]: { LTE: parseInt(value[1], 10) } },
      );
    }
  },
  getValueForField: value => (
    Array.isArray(value)
    ? value
    : typeof value === 'string' && value.includes(',')
    ? value.split(',').map(str => parseInt(str, 10))
    : undefined
  ),
  field: ['FieldReact', {
    name,
    label: null,
    // eslint-disable-next-line arrow-body-style
    renderContent: (props) => {
      return (
        <Slider
          label={label}
          value={props.value}
          onChange={props.onChange}
        />
      );
    },
  }],
}]);

export const [
  filterMarketingType,
  filterGoal,
  filterPromotionType,
  filterSeason,
] = [
  [
    'marketingType',
    'Marketing Type',
    'marketing-type',
    Types.VISUAL_MARKETING_TYPE_LIST,
  ],
  [
    'goal',
    'Goal',
    'goal',
    Types.VISUAL_GOAL_LIST,
  ],
  [
    'promotionType',
    'Promotion Type',
    'promotion-type',
    Types.VISUAL_PROMOTION_TYPE_LIST,
  ],
  [
    'season',
    'Season',
    'season',
    Types.VISUAL_SEASON_LIST,
  ],
].map(([name, label, key, choices]) => (
  ['FilterField', {
    id: key,
    label: null,
    section: 'top',
    buildQuery: (value, builder) => value && value.length && builder.add(
      'where',
      { [name]: { IN: Array.isArray(value) ? value : [value] } },
    ),
    getValueForField: getValueForFieldDefault,
    field: ['FieldSelect', {
      name,
      label: null,
      placeholder: label,
      allowClear: true,
      choices: choices.map(({ id, label: choiceLabel }) => ({
        label: choiceLabel,
        value: id,
      })),
      mode: 'multiple',
      // col: { xs: 24, md: 12 },
    }],
  }]
));

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  ...[
    [filterSearch, 8],
    [filterStatus, 8],
    [filterSort, 8],
    //
    [filterSwitchView, 24],
    //
    [compareAction, 24],
    //
    ...[
      [filterVisualId, 12],
      [filterOrganization, 12],
      //
      [filterPeriodRange, 24],
      //
      [filterCountry, 6],
      [filterRetailer, 6],
      [filterCategory, 6],
      [filterBrandVisuals, 6],
      //
      [filterMarketingType, 6],
      [filterGoal, 6],
      [filterPromotionType, 6],
      [filterSeason, 6],
    ].map(([config, ...args]) => ([
      [config[0], {
        ...config[1],
        isExtra: true,
      }],
      ...args,
    ])),
    //

    // TODO Remove (testing only)
    // [
    //   ['FilterField', {
    //     id: 'actionNewAnnotationsSetAll',
    //     label: null,
    //     section: 'top',
    //     propsFromPage: props => ({ client: props.client }),
    //     skip: [
    //       Admin.compileFromLibrary(tests.viewerIsNotAdmin),
    //     ],
    //     buildQueryDefault: undefined,
    //     buildQuery: (value, builder, _filter) => builder,
    //     // getValueForField: getValueForFieldDefault,
    //     field: ['ActionWithRequest', {
    //       name: 'actionNewAnnotationsSetConvertAll',
    //       title: 'Set All New Annotations',
    //       disabled: [],
    //       getRequestConfig: (_props) => ({
    //         url: 'visuals/annotations-new-convert-all',
    //         method: 'POST',
    //       }),
    //       type: 'danger',
    //       style: { width: '100%' },
    //       col: { xs: 12, md: 12 },
    //     }],
    //   }],
    //   12,
    // ],

    // TODO Remove (testing only)
    // [
    //   ['FilterField', {
    //     id: 'actionMetricsBakeAll',
    //     label: null,
    //     section: 'top',
    //     propsFromPage: props => ({ client: props.client }),
    //     skip: [
    //       Admin.compileFromLibrary(tests.viewerIsNotAdmin),
    //     ],
    //     buildQueryDefault: undefined,
    //     buildQuery: (value, builder, _filter) => builder,
    //     // getValueForField: getValueForFieldDefault,
    //     field: ['ActionWithRequest', {
    //       name: 'actionMetricsBakeAll',
    //       title: 'Set All Baked Metrics',
    //       disabled: [],
    //       getRequestConfig: (_props) => ({
    //         url: 'visuals/metrics-bake-convert-all',
    //         method: 'POST',
    //       }),
    //       type: 'danger',
    //       style: { width: '100%' },
    //       col: { xs: 12, md: 12 },
    //     }],
    //   }],
    //   12,
    // ],
  ].map((config) => cloneItem(...config)),
  filterLimitToViewerOrganization,
  filterPopulateOrganization,
  filterPopulateBrand,
  filterPopulateDepartment,
  filterPopulateCategory,
  filterPopulateAnnotationsSessionUser,
  filterPagination,
];

export const TRACKER = [
  ...[
    [filterCategoryGroupTracker, 24],
    //
    [filterSearch, 12],
    [filterSortTracker, 12],
    //
    [compareAction, 24],
    //
    // [filterOrganization, 24],
    //
    ...[
      [filterCountry, 6],
      [filterRetailer, 6],
      [filterCategoryTracker, 6],
      [filterBrandTracker, 6],
      //
      [filterMarketingType, 6],
      [filterGoal, 6],
      [filterPromotionType, 6],
      [filterSeason, 6],
      //
      [filterStoppingPower, 12],
      [filterEngagement, 12],
    ].map(([config, ...args]) => ([
      [config[0], {
        ...config[1],
        isExtra: true,
      }],
      ...args,
    ])),
  ].map((config) => cloneItem(...config)),
  filterLimitToPublic,
  filterLimitToStatusReport,
  filterPopulateBrand,
  filterPopulateDepartment,
  filterPopulateCategory,
  filterPagination,
];
