import {
  columnName,
  columnAnnotationValue,
} from '../common';

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  columnName,
  columnAnnotationValue,
];
