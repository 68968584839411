import React from 'react';

import { Link } from 'react-router-dom';

import Types from '../../common/modules/types';
import {
  columnName,
  isColumnAvailableOnlyForAdmin,
} from '../common';

export const columnEmail = 'email';

export const columnRole = {
  path: 'role',
  title: 'Role',
  render: record => Types.USER_ROLE_LABELS_MAP[record.role],
  isAvailable: isColumnAvailableOnlyForAdmin,
};

export const columnOrganization = {
  path: 'organization',
  title: 'Organization',
  render: (record, _column, _props) => (
    record?.organization?.name
    ? (
        <Link to={`/organizations/${record.organization._id}`}>
          {record.organization.name}
        </Link>
      )
    : 'N/A'
  ),
};

export const columnActive = {
  path: 'active',
  title: 'Status',
  render: record => (record.active ? 'Active' : 'Inactive'),
};

export const ADMIN = [
  columnName,
  columnEmail,
  columnRole,
  columnOrganization,
  columnActive,
];
