import isArray from 'lodash/isArray';

import Types from '../../common/modules/types';

import {
  cloneItem,
  filterSort,
  filterPagination,
  getValueForFieldDefault,
  isFilterAvailableOnlyForAdmin,
  isFilterAvailableOnlyForAdminWhenRoleIsCustomer,
  isFilterAvailableOnlyForCustomer,
} from '../common';

export const filterSearch = ['FilterField', {
  id: 'search',
  label: null, // 'ID',
  section: 'top',
  buildQuery: (value, builder) => value && builder.add(
    'where',
    {
      OR: [
        { name: { REGEX: value, OPTIONS: 'i' } },
        { email: { REGEX: value, OPTIONS: 'i' } },
      ],
    }
  ),
  getValueForField: value => value || '',
  getValueForQuery: (value) => {
    value = !value
    ? undefined
    : value.target
    ? value.target.value
    : value;
    return !value || !value.length
    ? undefined
    : value;
  },
  field: ['FieldText', {
    name: 'search',
    placeholder: 'Search name or email',
  }],
}];

export const filterRole = ['FilterField', {
  id: 'role',
  label: null,
  section: 'top',
  VALUES_MAP: Types.USER_ROLE_LIST.reduce(
    (agr, { id, _label }) => {
      agr[id] = { role: { EQ: id } };
      return agr;
    },
    { any: null },
  ),
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : undefined
  ),
  field: ['FieldSelect', {
    name: 'role',
    placeholder: 'Role',
    prepareValueForInput: value => (value ? value : 'any'),
    choices: [
      { label: 'Any Role', value: 'any' },
      ...Types.USER_ROLE_LIST.map(({ id: value, label }) => ({ value, label })),
    ],
  }],
}];

export const filterActive = ['FilterField', {
  id: 'status',
  label: null,
  section: 'top',
  VALUES_MAP: {
    any: null,
    active: { active: { EQ: true } },
    inactive: { active: { EQ: false } },
  },
  buildQuery: (value, builder, filter) => (
    filter.VALUES_MAP[value]
    ? builder.add('where', { ...filter.VALUES_MAP[value] })
    : null
  ),
  getValueForField: value => (
    value && value.length
    ? value
    : 'any'
  ),
  field: ['FieldSelect', {
    name: 'status',
    placeholder: 'Status',
    prepareValueForInput: value => (value ? value : 'any'),
    initialValue: 'any',
    choices: [{
      label: 'Any Status',
      value: 'any',
    }, {
      label: 'Active',
      value: 'active',
    }, {
      label: 'Inactive',
      value: 'inactive',
    }],
  }],
}];

export const filterNotSignups = ['FilterHidden', {
  id: 'nonSignupUsers',
  section: 'bottom',
  build: builder => builder.add('where', { signup: { NE: true } }),
}];

export const filterOrganization = ['FilterField', {
  id: 'organization',
  label: null,
  section: 'top',
  propsFromPage: props => ({ client: props.client, viewer: props.viewer }),
  buildQuery: (value, builder) => value && value.length && builder.add(
    'where',
    { organization: { IN: isArray(value) ? value : [value] } },
  ),
  getValueForField: getValueForFieldDefault,
  field: ['FieldConnectionSelect', {
    name: 'organization',
    url: '/organizations',
    placeholder: 'Organizations',
    mode: 'multiple',
    allowClear: true,
    searchPaths: ['name'],
    // getChoiceLabel: choice => `${(choice.data.name)}`,
  }],
}];

export const filterPopulateOrganization = ['FilterHidden', {
  id: 'populate-organization',
  label: null,
  section: 'top',
  buildQuery: (value, builder) => {
    builder.add('populate', { organization: { name: true } });
  },
}];

export const ADMIN = [
  ...[
    // Admin
    [filterSearch, 12, isFilterAvailableOnlyForAdmin],
    [filterSort, 12, isFilterAvailableOnlyForAdmin],
    [filterRole, 12, isFilterAvailableOnlyForAdmin],
    [filterActive, 12, isFilterAvailableOnlyForAdmin],
    [filterOrganization, 24, isFilterAvailableOnlyForAdminWhenRoleIsCustomer],
    // Customer
    [filterSearch, 8, isFilterAvailableOnlyForCustomer],
    [filterActive, 8, isFilterAvailableOnlyForCustomer],
    [filterSort, 8, isFilterAvailableOnlyForCustomer],
  ].map((config) => cloneItem(...config)),
  // Common
  filterPopulateOrganization,

  filterPagination,
  filterNotSignups,
];
