import {
  fieldKind,
  fieldOrganization,
} from '../common';

export const fieldName = ['FieldText', {
  name: 'name',
  rules: [['validation.isRequired']],
}];

export const ALL = [
  fieldName,
  fieldKind,
  fieldOrganization,
];
