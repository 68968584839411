import {
  fieldNameWithAnnotationValue,
  fieldAnnotationValue,
} from '../common';

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  fieldNameWithAnnotationValue,
  fieldAnnotationValue,
];
