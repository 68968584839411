import Admin from 'hive-admin';

// import tests from '../../helpers/tests';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as FIELDS_ALL,
} from './fields';

import {
  skipIfViewerCanNotManageVisuals,
} from '../common';

const itemIsGlobalWhileEditing = props => (
  props.activity === 'edit'
  && props.data
  && props.data.organization === null
);

const viewerIsCustomerAndItemIsGlobalWhileEditing = props => (
  props.viewer
  && props.viewer.role === 'CUSTOMER'
  && itemIsGlobalWhileEditing(props)
);

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Brands',
    icon: 'font-size',
    path: '/brands',
    redirect: [['redirect.unauthorized']],
    skip: [skipIfViewerCanNotManageVisuals],
    archiveConfig: {
      icon: 'font-size',
      title: 'Brands',
      label: 'Brands',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
    },
    singleConfig: {
      hidden: true,
      alias: '/brands',
      backButtonPaths: ['/brands'],
      headerTitle: 'Create Brand',
      // loadUrl: '/brands/:id/',
      duplicateLoadUrl: 'brands/:duplicate',
    },
    singleEditConfig: {
      backButtonPaths: ['/brands'],
      actions: [
        ['ActionSave', {
          skip: [viewerIsCustomerAndItemIsGlobalWhileEditing],
        }],
        ['ActionDelete', {
          skip: [
            viewerIsCustomerAndItemIsGlobalWhileEditing,
          ],
        }],
        ['Action', {
          name: 'duplicate',
          title: 'Duplicate',
          type: 'primary',
          ghost: true,
          skip: [
            props => !itemIsGlobalWhileEditing(props),
          ],
          onClick: (props) => {
            props.history.push(
              `/brands/create?duplicate=${props.data._id}`
            );
          },
        }],
      ],
    },
    fields: FIELDS_ALL,
  }])
).pages;
