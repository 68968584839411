const TIMER = {
  sleep: 3000,
};

TIMER.run = async () => {
  const cbs = Object.values(TIMER.callbacks);
  for (let i = 0; i < cbs.length; i++) {
    cbs[i]();
    await new Promise(resolve => setTimeout(resolve, 30));
  }
};
TIMER.start = async () => {
  clearTimeout(TIMER.timeout);
  await TIMER.run();
  TIMER.timeout = setTimeout(
    TIMER.start,
    TIMER.sleep,
  );
  // Object.values(TIMER.callbacks).forEach(cb => cb());
};
TIMER.index = 0;
TIMER.callbacks = {};
TIMER.add = (cb) => {
  const id = `${TIMER.index++}`;
  TIMER.callbacks[id] = cb;
  return id;
};
TIMER.remove = (id) => {
  delete TIMER.callbacks[id];
  return id;
};
TIMER.restart = () => {
  TIMER.start();
}

TIMER.start();

export default TIMER;
