import tests from '../../helpers/tests';

import {
  fieldNameWithAnnotationValue,
  fieldAnnotationValue,
} from '../common';

export const fieldDepartment = ['FieldConnectionSelect', {
  name: 'department',
  label: 'Department',
  url: '/departments',
  searchPaths: ['name'],
  disabled: [tests.isNotCreateActivity],
  virtual: [tests.isNotCreateActivity],
}];

export const ALL = [
  fieldNameWithAnnotationValue,
  fieldAnnotationValue,
  fieldDepartment,
];
