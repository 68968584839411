import styled, { css } from 'styled-components';

const Title = styled.h1`
  margin: 0;
  font-weight: 700;
  color: ${({ theme }) => theme.less.textColor};

  font-size: 20px;
  ${({ as }) => as === 'h2' && css`font-size: 12px;`}
`;

const Body = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${({ theme }) => theme.less.textColor};
  opacity: 0.75;

  ${({ as }) => as === 'label' && css`font-size: 14px;`}
`;

export { Title, Body };
