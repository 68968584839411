import React, {
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';

import styled from 'styled-components';

import AntdTree from 'antd/lib/tree';
import AntdIcon from 'antd/lib/icon';
// import AntdSelect from 'antd/lib/select';
import AntdInput from 'antd/lib/input';
import AntdCheckbox from 'antd/lib/checkbox';
import AntdRadio from 'antd/lib/radio';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const SidebarTrack = styled.div`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  width: 200%;
  transition: transform 300ms cubic-bezier(0.16, 1, 0.3, 1);
`;

const SidebarSide = styled.div`
  display: flex;
  position: relative;
  flex-grow: 0;
  width: 50%;
  flex-direction: column;
  overflow-x: hidden;
`;

const SidebarHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* height: 60px; */
  /* min-height: 60px; */
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
  align-items: center;
  padding: 12px;
  gap: 15px;
`;

const SidebarHeaderTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  overflow: hidden;
  font-size: 18px;
  text-overflow: ellipsis;
`;

const SidebarHeaderTitleSide = styled.div`
  display: flex;
  align-items: center;
`;

const SidebarHeaderCloseIcon = styled(AntdIcon)`
  display: none;
  cursor: pointer;
  transition: all 300ms;
  &:hover {
    color: ${({ theme }) => theme.less.primaryColor};
  }
  #admin[data-is-tablet="true"] & {
    display: inline;
  }
`;

const SidebarHeaderTitle = styled.span`
  display: block;
  /* text-overflow: ellipsis; */
  overflow: hidden;
  /* white-space: nowrap; */
  width: 100%;
  font-weight: 600;
`;

const SidebarHeaderBack = styled(AntdIcon)`
  cursor: pointer;
  transform: scale(0.9) translateY(-1px);
`;

const SidebarHeaderDescription = styled.div`
  width: 100%;
  opacity: 0.4;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const SidebarList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 18px;
`;

const FieldWrapper = styled.div`
  width: 100%;
  /* padding: 12px 0px;
  margin: 0px 12px; */
  &[data-error="true"] {
    color: red;
  }
`;

const FieldLabel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-bottom: 5px;
`;

const FieldLabelTitle = styled.div`

`;

const FieldLabelDescription = styled.div`
  width: 100%;
  opacity: 0.4;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const FieldText = styled(AntdInput.TextArea)`
  margin-top: 10px;
`;

const FieldCheckbox = styled(AntdCheckbox.Group)`
  label {
    display: block;
  }
  .ant-checkbox-wrapper {
    display: flex;
    width: 100%;
    white-space: wrap !important;
    > .ant-checkbox {
      display: block;
      align-self: flex-start;
      transform: translateY(2px);
      margin: 10px 10px 10px 5px;
    }
    > span:nth-child(2) {
      padding: 10px 0px;
    }
  }
`;
FieldCheckbox.Checkbox = AntdCheckbox;

const FieldRadio = styled(AntdRadio.Group)`
  .ant-radio-wrapper {
    display: flex;
    width: 100%;
    white-space: wrap !important;
    > .ant-radio {
      display: block;
      align-self: flex-start;
      transform: translateY(2px);
      margin: 10px 10px 10px 5px;
    }
    > span:nth-child(2) {
      padding: 10px 0px;
    }
  }
`;
FieldRadio.Radio = AntdRadio;

const AnnotationTree = styled(AntdTree)`

`;

const AnnotationWrapper = styled.div`
  width: 100%;
  /* &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
  } */

  .ant-tree-switcher {
    display: none !important;
  }

  li {
    display: block;
    width: 100%;
    padding: 0px !important;
    ul {
      padding: 0px !important;
    }
  }

  .ant-tree-node-content-wrapper {
    &:hover {
      background-color: initial !important;
    }
    display: block !important;
    width: 100% !important;
    padding: 0px !important;
    height: auto !important;
    .ant-tree-title {
      display: block;
      width: 100%;
    }
  }
`;

const AnnotationTreeHeader = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
`;

const AnnotationTreeDescription = styled.div`
  width: 100%;
  opacity: 0.4;
  &:first-letter {
    text-transform: uppercase;
  }
`;

const Annotation = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  padding: 10px 12px;
  cursor: pointer;
  &:hover,
  &[data-selected="true"] {
    background-color: #f0f7ff;
  }
  li ul & {
    padding-left: 18px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
  white-space: initial;
`;

const AnnotationSub = styled(Annotation)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;
  min-height: 40px;
  overflow: hidden;
  padding: 10px 12px;
  cursor: pointer;
  &:hover,
  &[data-selected="true"] {
    background-color: #f0f7ff;
  }
  li ul & {
    padding-left: 18px;
  }
  border-bottom: 1px solid ${({ theme }) => theme.less.borderColor};
  white-space: initial;
`;

const AnnotationLine = styled.div`
  height: 100%;
  width: 8px;
  height: 8px;
  transform: translateY(-4px);
  border: 1px solid ${({ theme }) => theme.less.disabledColor};
  border-top: 0px;
  border-right: 0px;
`;

const AnnotationTitle = styled.div`
  width: 100%;
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AnnotationCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  color: ${({ theme }) => theme.less.borderColor};
  &[data-success] {
    &[data-success="false"] {
      border-color: ${({ theme }) => theme.less.errorColor};
      color: ${({ theme }) => theme.less.errorColor};
    }
    &[data-success="true"] {
      border-color: ${({ theme }) => theme.less.successColor};
      color: ${({ theme }) => theme.less.successColor};
    }
  }
`;

const AnnotationCountIcon = styled(AntdIcon)`

`;

function AnnotationCountValidation({ success, count }) {
  success = (
    typeof success === 'boolean'
    ? success
    : Number.isFinite(count)
    ? count > 0
    : null
  );
  const isCountable = Number.isFinite(count);
  const countProps = (
      (success === true && (!isCountable || (isCountable && count > 0)))
    ? { 'data-success': true }
    : success === false
    ? { 'data-success': false }
    : {}
  );
  return (
    <AnnotationCount {...countProps}>
      {
          isCountable
        ? `${count}`
        : (
            <AnnotationCountIcon
              type={
                  success === true
                ? 'check'
                : success === false
                ? 'exclamation-circle'
                : undefined
              }
            />
          )
      }
    </AnnotationCount>
  );
}

export default function Sidebar({
  data,
  isSmall,
  annotations = [],
  errors,
  selectedAnnotation,
  selectedAnnotationForm,
  onSelectedAnnotationChange,
  onSelectedAnnotationFormFieldChange,
  addingAnnotationConfig,
  isSidebarOpen,
  onSidebarClose,
  readOnly,
  hideDescriptions,
  hideAnnotations,
}) {
  const wrapperRef = useRef();
  const [expandedKeys, selectedKeys] = useMemo(
    () => {
      const keys = [];
      if (selectedAnnotation?.key) {
        keys.push(`0-${selectedAnnotation.key}`);
      }
      if (selectedAnnotation?.id) {
        keys.push(`1-${selectedAnnotation.id}`);
      }
      return [
        keys,
        (
            keys.length === 2
          ? [keys[1]]
          : []
        ),
      ];
    },
    [selectedAnnotation?.key, selectedAnnotation?.id],
  );
  const {
    title: headerTitle,
    subtitle: headerSubtitle,
  } = useMemo(
    () => {
      const result = { title: null, subtitle: null };
      const selectedAnnotationData = annotations.find(({ config }) => (
        config.key === selectedAnnotation?.key
      ));
      const selectedAnnotationConfig = selectedAnnotationData?.config;
      if (!selectedAnnotationConfig) {
        result.title = 'Annotations';
        return result;
      }
      if (['TEXT', 'CHOICES'].includes(selectedAnnotationConfig.type)) {
        // result.title = 'Annotation';
        // result.subtitle = selectedAnnotationConfig.description;
        result.title = 'Field';
        result.subtitle = null;
        return result;
      }
      const selectedAnnotationValueIndex = (
          selectedAnnotation?.id
        ? selectedAnnotationData?.value?.findIndex?.(
            ({ _id }) => _id === selectedAnnotation?.id
          )
        : null
      );
      if (selectedAnnotationValueIndex === null) {
        result.title = (
          selectedAnnotationConfig?.shapeLabel || 'Field'
        );
        result.subtitle = selectedAnnotationConfig.description;
        return result;
      }
      result.title = `${selectedAnnotationConfig.shapeLabel} ${
        selectedAnnotationValueIndex + 1
      }`;
      result.subtitle = selectedAnnotationConfig.description;
      return result;
    },
    [annotations, selectedAnnotation?.key, selectedAnnotation?.id],
  );

  const onBack = useCallback(
    () => {
      if (selectedAnnotation?.id) {
        onSelectedAnnotationChange({
          ...selectedAnnotation,
          id: null,
        })
      } else {
        onSelectedAnnotationChange(null);
      }
    },
    [onSelectedAnnotationChange, selectedAnnotation],
  );

  const selectedAnnotationFormFieldsCount = (
    selectedAnnotationForm?.fields || []
  ).length;

  useEffect(
    () => {
      if (isSmall && isSidebarOpen) {
        const onMouseDown = (event) => {
          if (
            wrapperRef.current
            && !wrapperRef.current.contains(event.target)
          ) {
            onSidebarClose();
          }
        };
        window.addEventListener('mousedown', onMouseDown);
        window.addEventListener('touchstart', onMouseDown);
        return () => {
          window.removeEventListener('mousedown', onMouseDown);
          window.removeEventListener('touchstart', onMouseDown);
        };
      }
      return undefined;
    },
    [isSidebarOpen, isSmall, onSidebarClose],
  );

  return (
    <Wrapper ref={wrapperRef}>
      <SidebarTrack
        style={{
          transform: `translateX(${
            selectedAnnotationForm
            ? '-50%'
            : '0%'
          })`,
        }}
      >
        <SidebarSide>
          <SidebarHeader>
            <SidebarHeaderTitleWrapper>
              <SidebarHeaderTitleSide>
                <SidebarHeaderTitle>
                  Annotations
                </SidebarHeaderTitle>
              </SidebarHeaderTitleSide>
              <SidebarHeaderTitleSide>
                <SidebarHeaderCloseIcon
                  type="close"
                  onClick={() => onSidebarClose()}
                />
              </SidebarHeaderTitleSide>
            </SidebarHeaderTitleWrapper>
          </SidebarHeader>
          <SidebarList>
            {
              annotations.map((annotation) => (
                  annotation.config.getExportValue
                  || (
                    hideAnnotations
                    && hideAnnotations.includes(annotation.config.id)
                  )
                ? null
                : (

                    <AnnotationWrapper
                      key={annotation.config.key}
                    >
                      <AnnotationTree
                        selectable={false}
                        expandedKeys={expandedKeys}
                        selectedKeys={selectedKeys}
                      >
                        <AnnotationTree.TreeNode
                          key={`0-${annotation.config.key}`}
                          selectable={false}
                          title={(
                            <Annotation
                              data-selected={(
                                selectedAnnotation?.key
                                === annotation.config.key
                              )}
                              onClick={() => {
                                onSelectedAnnotationChange({
                                  key: annotation.config.key,
                                  id: null,
                                });
                              }}
                            >
                              <AnnotationTreeHeader>
                                <AnnotationTitle>
                                  {annotation.config.label}
                                  {/* {`${annotation.config.label}${
                                    annotation.config.required ? ' (required)' : ''
                                  }`} */}
                                </AnnotationTitle>
                                <AnnotationCountValidation
                                  success={!errors.keys[annotation.config.key]}
                                  count={(
                                      annotation.config.type === 'SHAPE'
                                    ? annotation.value?.length || 0
                                    : annotation.config.type === 'CHOICES'
                                    ? (
                                          !annotation.value?.length
                                        ? 0
                                        : annotation.config.multiple
                                        ? (annotation.value?.length || 0)
                                        : (
                                            annotation.value?.length >= 1
                                            ? 1
                                            : 0
                                          )
                                      )
                                    : annotation.config.type === 'TEXT'
                                    ? annotation.value?.length ? 1 : 0
                                    : 0
                                  )}
                                />
                              </AnnotationTreeHeader>
                              {
                                (
                                  !hideDescriptions
                                  && (
                                    selectedAnnotation?.key
                                    === annotation.config.key
                                  )
                                  && annotation.config.description?.length > 0
                                )
                                ? (
                                    <AnnotationTreeDescription>
                                      {annotation.config.description}
                                    </AnnotationTreeDescription>
                                  )
                                : null
                              }
                            </Annotation>
                          )}
                        >
                          {
                            annotation.config.type === 'SHAPE'
                            ? [
                                ...(annotation.value || []).map(
                                  (subAnnotation, subAnnotationIndex) => (
                                    <AnnotationTree.TreeNode
                                      key={`1-${subAnnotation._id}`}
                                      selectable={false}
                                      title={(
                                        <AnnotationSub
                                          data-selected={(
                                            selectedAnnotation?.id
                                            === subAnnotation._id
                                          )}
                                          onClick={() => {
                                            onSelectedAnnotationChange({
                                              ...selectedAnnotation,
                                              id: subAnnotation._id,
                                            });
                                          }}
                                        >
                                          <AnnotationLine
                                            className="annotation-line"
                                          />
                                          <AnnotationTitle>
                                            {
                                              `${
                                                  annotation.config.shapeLabel
                                              } ${
                                                subAnnotationIndex + 1
                                              }`
                                            }
                                          </AnnotationTitle>
                                          <AnnotationCountValidation
                                            success={!errors.keys[`${
                                              annotation.config.key
                                            }.${
                                              subAnnotation._id
                                            }`]}
                                          />
                                        </AnnotationSub>
                                      )}
                                    />
                                  ),
                                ),
                                ...(
                                  addingAnnotationConfig && !readOnly
                                  ? [(
                                      <AnnotationTree.TreeNode
                                        key="1-new"
                                        selectable={false}
                                        title={(
                                          <AnnotationSub
                                            data-selected={
                                              !selectedAnnotation?.id
                                            }
                                            onClick={() => {
                                              onSelectedAnnotationChange({
                                                ...selectedAnnotation,
                                                id: null,
                                              });
                                            }}
                                          >
                                            <AnnotationLine
                                              className="annotation-line"
                                            />
                                            <AnnotationTitle>
                                              {`Add New ${
                                                annotation.config.shapeLabel
                                              }`}
                                            </AnnotationTitle>
                                          </AnnotationSub>
                                        )}
                                      />
                                    )]
                                  : []
                                ),
                              ]
                            : null
                          }
                        </AnnotationTree.TreeNode>
                      </AnnotationTree>
                    </AnnotationWrapper>
                  )
              ))
            }
          </SidebarList>
        </SidebarSide>
        <SidebarSide>
          <SidebarHeader>
            <SidebarHeaderTitleWrapper>
              <SidebarHeaderTitleSide
                onClick={onBack}
              >
                {
                  selectedAnnotationForm
                  ? (
                      <>
                        <SidebarHeaderBack
                          type="left"
                        />
                        &nbsp;
                        <SidebarHeaderTitle>
                          {headerTitle || 'Annotations'}
                        </SidebarHeaderTitle>
                      </>
                    )
                  : null
                }
              </SidebarHeaderTitleSide>
              <SidebarHeaderTitleSide>
                <SidebarHeaderCloseIcon
                  type="close"
                  onClick={() => onSidebarClose()}
                />
              </SidebarHeaderTitleSide>
            </SidebarHeaderTitleWrapper>
            {
              !hideDescriptions && headerSubtitle
              ? (
                  <SidebarHeaderDescription>
                    {headerSubtitle}
                  </SidebarHeaderDescription>
                )
              : null
            }
          </SidebarHeader>
          <SidebarList>
            <FieldsWrapper>
              {
                (selectedAnnotationForm?.fields || [])
                .map(({
                  value: fieldValue,
                  config: fieldConfig,
                  id: fieldId,
                  key: fieldFinalKey,
                }) => (
                  <FieldWrapper
                    key={fieldConfig.key}
                    data-error={!!errors.keys[fieldFinalKey]}
                  >
                    <FieldLabel>
                      {/* {fieldConfig.label} */}
                      <>
                        <FieldLabelTitle>
                          {
                            (
                                fieldConfig.getIsRequired
                              ? fieldConfig.getIsRequired(data)
                              : fieldConfig.required
                            )
                            ? (
                                <>
                                  <span style={{ color: 'red' }}>
                                    *
                                  </span>
                                  &nbsp;
                                </>
                              )
                            : null
                          }
                          {fieldConfig.label}
                        </FieldLabelTitle>
                        {
                          (
                            !hideDescriptions
                            && fieldConfig.description?.length > 0
                          )
                          ? (
                              <FieldLabelDescription>
                                {fieldConfig.description}
                              </FieldLabelDescription>
                            )
                          : null
                        }
                      </>
                    </FieldLabel>
                    {
                        fieldConfig.type === 'TEXT'
                      ? (
                          <FieldText
                            data-is-annotation-field="true"
                            autoSize={{ minRows: 1, maxRows: 6 }}
                            value={
                              typeof fieldValue === 'string' ? fieldValue : ''
                            }
                            onChange={(event) => {
                              onSelectedAnnotationFormFieldChange(
                                event.target.value,
                                fieldId,
                                fieldConfig.key,
                              );
                            }}
                            disabled={readOnly}
                          />
                        )
                      : fieldConfig.type === 'CHOICES'
                      ? (
                          fieldConfig.multiple
                          ? (
                              <FieldCheckbox
                                // data-is-annotation-field="true"
                                value={(
                                  Array.isArray(fieldValue)
                                  ? fieldValue
                                  : []
                                )}
                                onChange={(newChecked) => {
                                  onSelectedAnnotationFormFieldChange(
                                    (
                                      Array.isArray(newChecked)
                                      ? newChecked
                                      : []
                                    ),
                                    fieldId,
                                    fieldConfig.key,
                                  )
                                }}
                                options={fieldConfig.choices}
                                disabled={readOnly}
                              />
                            )
                          : (
                              <FieldRadio
                                // data-is-annotation-field="true"
                                value={fieldValue || undefined}
                                onChange={(event) => {
                                  onSelectedAnnotationFormFieldChange(
                                    event.target.value,
                                    fieldId,
                                    fieldConfig.key,
                                  );
                                }}
                                disabled={readOnly}
                              >
                                <FieldRadio.Radio
                                  // data-is-annotation-field="true"
                                  key="__NONE__"
                                  value={undefined}
                                  onClick={
                                      selectedAnnotationFormFieldsCount < 2
                                    ? onBack
                                    : undefined
                                  }
                                  disabled={readOnly}
                                >
                                  None
                                </FieldRadio.Radio>
                                {fieldConfig.choices.map(choice => (
                                  <FieldRadio.Radio
                                    // data-is-annotation-field="true"
                                    key={choice.value}
                                    value={choice.value}
                                    onClick={
                                        selectedAnnotationFormFieldsCount < 2
                                      ? onBack
                                      : undefined
                                    }
                                  >
                                    {choice.label}
                                  </FieldRadio.Radio>
                                ))}
                              </FieldRadio>
                            )
                        )
                      : null
                    }
                  </FieldWrapper>
                ))
              }
            </FieldsWrapper>
          </SidebarList>
        </SidebarSide>
      </SidebarTrack>
    </Wrapper>
  );
}
