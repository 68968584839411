import mongoose from 'mongoose/browser';

import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';

import styled, {
  ThemeProvider,
  useTheme,
} from 'styled-components';
import Color from 'color';

import { useHotkeys } from 'react-hotkeys-hook';

import {
  Stage,
  Layer,
  Rect,
  Image as KonvaImage,
} from 'react-konva';

import AntdIcon from 'antd/lib/icon';
import AntdRadio from 'antd/lib/radio';
import AntdButton from 'antd/lib/button';

import { useSize } from './helpers';

import ShapeRect from './ShapeRect';
import ShapePoint from './ShapePoint';

import TIMER from './Timer';

const { Types: { ObjectId } } = mongoose;

const Wrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
`;

const EditorWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  overflow: hidden;
`;

const ToolbarWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  display: flex;
  padding: 10px 20px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  background-color: rgba(233, 233, 233, 0.5);
`;

const ToolbarSide = styled.div`
  display: flex;
  gap: 10px;
`;

const Radio = styled(AntdRadio.Group)`
  border-radius: 6px;
  /* box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2); */
`;

const RadioButton = styled(AntdRadio.Button)`
  line-height: 30px !important;
  user-select: none;
  &:has(.ant-radio-button-checked) {
    background-color: ${({ theme }) => theme.less.primaryColor};
    color: white !important;
    border-color: ${({ theme }) => theme.less.primaryColor} !important;
  }
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  border-left: 0px;
  height: 32px !important;
  [data-is-small="true"] & {
    line-height: 42px !important;
    padding: 0 14px;
    height: 42px !important;
  }
  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.less.borderColor};
  }
  &:first-child {
    border-radius: 6px 0 0 6px;
  }
  &:last-child {
    border-radius: 0 6px 6px 0;
  }
  &:first-child:last-child {
    border-radius: 6px;
  }
  border-color: ${({ theme }) => theme.less.borderColor};
`;

const Button = styled(AntdButton)`
  border-radius: 6px;
  background-color: white !important;
`;

function setStageZoom(scaleOld, scaleNew, stage, rect) {
  stage.find('Circle').forEach((point) => {
    point.scale({
      x: 1 / scaleNew,
      y: 1 / scaleNew,
    });
  });
  const pointer = {
    x: rect.width / 2,
    y: rect.height / 2,
  };
  const mousePointTo = {
    x: (pointer.x - stage.x()) / scaleOld,
    y: (pointer.y - stage.y()) / scaleOld,
  };
  const positionNew = {
    x: pointer.x - mousePointTo.x * scaleNew,
    y: pointer.y - mousePointTo.y * scaleNew,
  };
  stage.scale({ x: scaleNew, y: scaleNew });
  stage.position(positionNew);
}

function getDistance(p1, p2) {
  return Math.sqrt(
    // eslint-disable-next-line prefer-exponentiation-operator, no-restricted-properties
    Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2)
  );
}

function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

export function EditorWithSize({
  isSmall,
  image,
  mode,
  onGenerate,
  onModeChange,
  onReset,
  onClose,
  isSidebarOpen,
  onSidebarOpen,
  // onSidebarClose,
  addingAnnotationConfig,
  annotations = [],
  onAnnotationsChange,
  selectedAnnotationId,
  onSelectedAnnotationIdChange,
  zoomBy = 1.1,
  editorWrapperRef,
  editorWrapperSize,
  onHotkeyIgnoreEventWhen,
  readOnly,
}) {
  const themeInitial = useTheme();
  const theme = useMemo(
    () => ({
      ...themeInitial,
      annotations: {
        fill: Color(themeInitial.less.primaryColor)
          .alpha(0.2).rgb().toString(),
        fillRect: Color(themeInitial.less.primaryColor)
          .alpha(0.4).rgb().toString(),
        fillPoint: Color(themeInitial.less.primaryColor)
          .alpha(0.4).rgb().toString(),
        fillSelected: Color(themeInitial.less.primaryColor)
          .alpha(0.6).rgb().toString(),
        fillSelectedRect: Color(themeInitial.less.primaryColor)
          .alpha(0.75).rgb().toString(),
        fillSelectedPoint: Color(themeInitial.less.primaryColor)
          .alpha(0.9).rgb().toString(),
        fillEffectRect: Color('#fff')
          .mix(Color(themeInitial.less.primaryColor), 0.15)
          .alpha(1)
          .rgb()
          .toString(),
        strokeWidth: 3,
        stroke: 'rgba(255, 255, 255, 0.5)',
        strokeSelected: 'white',
        hitStrokeWidth: 10,
        // shadowColor: 'black',
        // shadowBlur: 10,
        point: {
          radius: 15,
        },
        transformer: {
          // anchorSize: 15,
          borderStrokeWidth: 0,
          anchorStroke: 'white',
          anchorStrokeWidth: 3,
          // anchorCornerRadius: 2,
          flipEnabled: false,
          anchorFill: themeInitial.less.primaryColor,
        },
      },
    }),
    [themeInitial],
  );

  const width = image.naturalWidth;
  const height = image.naturalHeight;

  const stageRef = useRef();
  const annotationsLayerRef = useRef();

  const addingAnnotationRef = useRef();
  const addingAnnotationStatsRef = useRef();
  const panningRef = useRef();
  const [addingAnnotation, setAddingAnnotation] = useState(null);
  const { scale, layerProps, bounds } = useMemo( // eslint-disable-line no-unused-vars
    () => {
      const scaleInput = Math.min(
        editorWrapperSize.width / width,
        editorWrapperSize.height / height
      );
      const x = (editorWrapperSize.width - (width * scaleInput)) / 2;
      const y = (editorWrapperSize.height - (height * scaleInput)) / 2;
      return {
        scale: scaleInput,
        layerProps: {
          scale: { x: scaleInput, y: scaleInput },
          x,
          y,
        },
        bounds: {
          x,
          y,
          width: width * scaleInput,
          height: height * scaleInput,
          scale: scaleInput,
        },
      };
    },
    [
      editorWrapperSize.width,
      editorWrapperSize.height,
      width,
      height,
    ],
  );

  const onMouseDown = useCallback(
    (event) => {
      event.evt.preventDefault();
      if (!isSmall || !isSidebarOpen) {
        if (event.evt?.touches?.length > 1) {
          event.evt.preventDefault();
          addingAnnotationStatsRef.current = null;
          if (addingAnnotation) {
            setAddingAnnotation(null);
          }
          return;
        }
        if (mode === 'EDIT') {
          const clickedOnEmpty = event.target === event.target.getStage();
          if (clickedOnEmpty) {
            if (selectedAnnotationId) {
              onSelectedAnnotationIdChange(null);
            }
            if (addingAnnotationConfig) {
              const { current: frame } = annotationsLayerRef;
              event.evt.preventDefault();
              event.cancelBubble = true;
              const position = frame.getRelativePointerPosition();
              if (position) {
                addingAnnotationStatsRef.current = {
                  started: false,
                  positionStart: position,
                  config: addingAnnotationConfig,
                };
                if (addingAnnotationConfig.shape === 'POINT') {
                  addingAnnotationStatsRef.current.started = true;
                  addingAnnotationStatsRef.current.annotation = {
                    _id: `${ObjectId()}`,
                    x: position.x,
                    y: position.y,
                  };
                  setAddingAnnotation(
                    addingAnnotationStatsRef.current.annotation,
                  );
                }
              }
            }
          }
        }
      }
    },
    [
      mode,
      isSmall,
      selectedAnnotationId,
      addingAnnotation,
      addingAnnotationConfig,
      onSelectedAnnotationIdChange,
      isSidebarOpen,
    ],
  );

  useEffect(
    () => {
      const mouseMove = (event) => {
        const { current: stage } = stageRef;
        const { current: frame } = annotationsLayerRef;
        const { current: panning } = panningRef;
        if (event.touches?.length > 1) {
          stage.setPointersPositions(event);
          event.preventDefault();
          const [touch1, touch2] = event.touches;
          // if the stage was under Konva's drag&drop
          // we need to stop it, and implement our own pan logic with two pointers
          if (stage.isDragging()) {
            stage.stopDrag();
          }
          if (addingAnnotationStatsRef.current) {
            if (addingAnnotationStatsRef.current.started) {
              setAddingAnnotation(null);
            }
            addingAnnotationStatsRef.current = null;
          }
          const p1 = { x: touch1.clientX, y: touch1.clientY };
          const p2 = { x: touch2.clientX, y: touch2.clientY };
          if (!panning) {
            panningRef.current = {
              center: getCenter(p1, p2),
              distance: 0,
            };
            return;
          }
          const newCenter = getCenter(p1, p2);
          const newDistance = getDistance(p1, p2);
          if (!panning.distance) {
            panning.distance = newDistance;
          }
          // local coordinates of center point
          const pointTo = {
            x: (newCenter.x - stage.x()) / stage.scaleX(),
            y: (newCenter.y - stage.y()) / stage.scaleX(),
          };
          const newScale = stage.scaleX() * (newDistance / panning.distance);
          stage.scaleX(newScale);
          stage.scaleY(newScale);
          stage.find('Circle').forEach((point) => {
            point.scale({
              x: 1 / newScale,
              y: 1 / newScale,
            });
          });
          // calculate new position of the stage
          const dx = newCenter.x - panning.center.x;
          const dy = newCenter.y - panning.center.y;
          const newPosition = {
            x: newCenter.x - pointTo.x * newScale + dx,
            y: newCenter.y - pointTo.y * newScale + dy,
          };
          stage.position(newPosition);
          panning.distance = newDistance;
          panning.center = newCenter;
        } else if (frame && stage && addingAnnotationStatsRef.current) {
          const stats = addingAnnotationStatsRef.current;
          if (stats) {
            stage.setPointersPositions(event);
            const position = frame.getRelativePointerPosition();
            if (position) {
              const dx = position.x - stats.positionStart.x;
              const dy = position.y - stats.positionStart.y;
              if (!stats.started) {
                if (Math.abs(dx) > 2 || Math.abs(dy) > 2) {
                  const value = {
                    _id: `${ObjectId()}`,
                    x: stats.positionStart.x,
                    y: stats.positionStart.y,
                  };
                  if (addingAnnotationConfig?.shape === 'RECT') {
                    value.width = dx;
                    value.height = dy;
                  }
                  stats.annotation = value;
                  setAddingAnnotation(value);
                  stats.started = true;
                }
              } else if (addingAnnotationRef.current) {
                if (addingAnnotationConfig?.shape === 'RECT') {
                  Object.assign(stats.annotation, {
                    width: dx,
                    height: dy,
                  });
                  addingAnnotationRef.current.size({
                    width: dx,
                    height: dy,
                  });
                } else if (addingAnnotationConfig?.shape === 'POINT') {
                  Object.assign(stats.annotation, position);
                  addingAnnotationRef.current.position(position);
                }
              }
            }
          }
        }
      };
      window.addEventListener('mousemove', mouseMove);
      window.addEventListener('touchmove', mouseMove);
      return () => {
        window.removeEventListener('mousemove', mouseMove);
        window.removeEventListener('touchmove', mouseMove);
      }
    },
    [addingAnnotationConfig],
  );

  useEffect(
    () => {
      const mouseUp = (event) => {
        const { current: stage } = stageRef;
        const { current: frame } = annotationsLayerRef;
        panningRef.current = null;
        if (frame && stage && addingAnnotationStatsRef.current) {
          const stats = addingAnnotationStatsRef.current;
          if (stats.started) {
            stage.setPointersPositions(event);
            const position = frame.getRelativePointerPosition();
            if (position) {
              const newAnnotations = annotations.slice();
              const newAnnotation = { ...stats.annotation };
              if (stats.config?.shape === 'RECT') {
                if (newAnnotation.width < 0) {
                  newAnnotation.x += newAnnotation.width;
                  newAnnotation.width *= -1;
                }
                if (newAnnotation.height < 0) {
                  newAnnotation.y += newAnnotation.height;
                  newAnnotation.height *= -1;
                }
              }
              newAnnotations.push({ value: newAnnotation });
              onAnnotationsChange(newAnnotations);
              onSelectedAnnotationIdChange(newAnnotation._id);
              if (stats.config?.annotations?.length > 0) {
                onSidebarOpen();
              }
            }
          }
          setAddingAnnotation(null);
          addingAnnotationStatsRef.current = null;
          // stage.container().style.cursor = 'inherit';
        }
      };
      window.addEventListener('mouseup', mouseUp);
      window.addEventListener('touchend', mouseUp);
      return () => {
        window.removeEventListener('mouseup', mouseUp);
        window.removeEventListener('touchend', mouseUp);
      }
    },
    [
      addingAnnotationConfig,
      annotations,
      onSidebarOpen,
      onAnnotationsChange,
      onSelectedAnnotationIdChange,
    ],
  );

  useEffect(
    () => {
      const container = stageRef.current?.container?.();
      let onWheel;
      if (container) {
        TIMER?.run?.();
        onWheel = (event) => {
          const { current: stage } = stageRef;
          if (stage) {
            if (event.ctrlKey) {
              event.preventDefault();
              const oldScale = stage.scaleX();
              const pointer = stage.getPointerPosition();
              const mousePointTo = {
                x: (pointer.x - stage.x()) / oldScale,
                y: (pointer.y - stage.y()) / oldScale,
              };
              const zoomByLocal = zoomBy;
              const absDeltaY = Math.abs(event.deltaY);
              let direction = event.deltaY > 0 ? 1 : -1;
              if (absDeltaY < 100) {
                direction = -direction;
              }
              const newScale = (
                  direction > 0
                ? oldScale * zoomByLocal
                : oldScale / zoomByLocal
              );
              stage.find('Circle').forEach((point) => {
                point.scale({
                  x: 1 / newScale,
                  y: 1 / newScale,
                });
              });
              stage.scale({
                x: newScale,
                y: newScale,
              });
              const newPos = {
                x: pointer.x - mousePointTo.x * newScale,
                y: pointer.y - mousePointTo.y * newScale,
              };
              stage.position(newPos);
            } else {
              const oldPosition = stage.getPosition();
              stage.setPosition({
                x: oldPosition.x + (event.wheelDeltaX / 2),
                y: oldPosition.y + (event.wheelDeltaY / 2),
              });
            }
          }
        };
        container.addEventListener('mousewheel', onWheel);
      }
      return () => {
        if (container && onWheel) {
          container.removeEventListener('mousewheel', onWheel);
        }
      }
    },
    [zoomBy],
  );

  useEffect(
    () => {
      const { current: wrapper } = editorWrapperRef;
      const cb = (event) => {
        if (mode === 'PAN') {
          event.preventDefault();
        }
      };
      wrapper.addEventListener('mousedown', cb);
      wrapper.addEventListener('contextmenu', cb);
      return () => {
        wrapper.removeEventListener('mousedown', cb);
        wrapper.removeEventListener('contextmenu', cb);
      };
    },
    [mode, editorWrapperRef],
  );

  useEffect(
    () => {
      const { current: stage } = stageRef;
      if (stage) {
        if (mode === 'PAN') {
          stage.container().style.cursor = 'grab';
        } else {
          stage.container().style.cursor = 'inherit';
        }
      }
    },
    [mode],
  );

  const { onZoomIn, onZoomOut, onZoomReset } = useMemo(
    () => ({
      onZoomIn: () => {
        const { current: stage } = stageRef;
        if (stage) {
          const scaleOld = stage.scaleX();
          const scaleNew = scaleOld * zoomBy;
          setStageZoom(scaleOld, scaleNew, stage, editorWrapperSize);
        }
      },
      onZoomOut: () => {
        const { current: stage } = stageRef;
        if (stage) {
          const scaleOld = stage.scaleX();
          const scaleNew = scaleOld / zoomBy;
          setStageZoom(scaleOld, scaleNew, stage, editorWrapperSize);
        }
      },
      onZoomReset: () => {
        const { current: stage } = stageRef;
        if (stage) {
          const scaleOld = stage.scaleX();
          const scaleNew = 1;
          setStageZoom(scaleOld, scaleNew, stage, editorWrapperSize);
          stage.position({ x: 0, y: 0 });
        }
      },
    }),
    [editorWrapperSize, zoomBy],
  );

  const annotationsSorted = useMemo(
    () => (
      annotations
      .reduce(
        (agr, annotation) => {
          const selected = annotation.value._id === selectedAnnotationId;
          agr.items.splice(
            agr.items.length - (agr.hasSelected ? 1 : 0),
            0,
            annotation,
          );
          if (selected) {
            agr.hasSelected = true;
          }
          return agr;
        },
        { items: [], hasSelected: false },
      )
      .items
    ),
    [annotations, selectedAnnotationId],
  );

  useHotkeys(
    'ctrl+d, meta+d',
    (event) => {
      if (!readOnly) {
        event.preventDefault();
        if (selectedAnnotationId) {
          const annotation = annotations.find((testAnnotation) => (
            testAnnotation.value._id === selectedAnnotationId
          ));
          if (annotation) {
            const newAnnotations = annotations.slice();
            // const i = newAnnotations.indexOf(annotation);
            const id = `${ObjectId()}`;
            newAnnotations.push({
              value: {
                ...annotation.value,
                _id: id,
                x: annotation.value.x + (15 / bounds.scale),
                y: annotation.value.y + (15 / bounds.scale),
              },
            });
            onAnnotationsChange(newAnnotations);
            onSelectedAnnotationIdChange(id);
          }
        }
      }
    },
    {
      enabled: !!selectedAnnotationId,
      enableOnFormTags: true,
      // ignoreEventWhen: onHotkeyIgnoreEventWhen,
    },
    [
      readOnly,
      annotations,
      selectedAnnotationId,
      onAnnotationsChange,
      onSelectedAnnotationIdChange,
    ],
  );

  useHotkeys(
    'Escape',
    (event) => {
      event.preventDefault();
      onClose();
    },
    {
      enabled: !!onClose,
      enableOnFormTags: true,
      ignoreEventWhen: onHotkeyIgnoreEventWhen,
    },
  );

  useHotkeys(
    'Delete, Backspace',
    (event) => {
      if (!readOnly) {
        event.preventDefault();
        const newAnnotations = annotations.slice();
        const i = newAnnotations.findIndex((annotation) => (
          annotation.value._id === selectedAnnotationId
        ));
        newAnnotations.splice(i, 1);
        onAnnotationsChange(newAnnotations);
        onSelectedAnnotationIdChange(
            newAnnotations.length
          ? (newAnnotations[newAnnotations.length - 1]?.value?._id || null)
          : null
        );
      }
    },
    {
      enabled: !!selectedAnnotationId,
      enableOnFormTags: true,
      ignoreEventWhen: onHotkeyIgnoreEventWhen,
    },
    [
      readOnly,
      annotations,
      selectedAnnotationId,
      onAnnotationsChange,
      onSelectedAnnotationIdChange,
    ],
  );

  useHotkeys(
    'ctrl+=, meta+=',
    (event) => {
      event.preventDefault();
      onZoomIn();
    },
    {
      enableOnFormTags: true,
      ignoreEventWhen: onHotkeyIgnoreEventWhen,
    },
    [onZoomIn],
  );

  useHotkeys(
    'ctrl+Minus, meta+Minus',
    (event) => {
      event.preventDefault();
      onZoomOut();
    },
    {
      enableOnFormTags: true,
      ignoreEventWhen: onHotkeyIgnoreEventWhen,
    },
    [onZoomOut],
  );

  useHotkeys(
    'ctrl+0, meta+0',
    (event) => {
      event.preventDefault();
      onZoomReset();
    },
    {
      enableOnFormTags: true,
      ignoreEventWhen: onHotkeyIgnoreEventWhen,
    },
    [onZoomReset],
  );

  const addingAnnotationConfigFinal = (
    addingAnnotationStatsRef.current?.config
    || addingAnnotationConfig
  );

  const AddingShapeComponentClass = (
      addingAnnotationConfigFinal?.shape === 'RECT'
    ? ShapeRect
    : addingAnnotationConfigFinal?.shape === 'POINT'
    ? ShapePoint
    : null
  );

  return (
    <>
      <Stage
        ref={stageRef}
        width={editorWrapperSize.width}
        height={editorWrapperSize.height}
        onMouseDown={onMouseDown}
        onTouchStart={onMouseDown}
        draggable={mode === 'PAN'}
        onDragStart={(event) => {
          event.target.getStage().container().style.cursor = 'grabbing';
        }}
        onDragEnd={(event) => {
          if (event.target === event.target.getStage()) {
            if (mode === 'PAN') {
              event.target.getStage().container().style.cursor = 'grab';
            } else {
              event.target.getStage().container().style.cursor = 'inherit';
            }
          }
        }}
      >
        <ThemeProvider theme={theme}>
          <Layer
            key="image"
            listening={false}
            {...layerProps}
          >
            <KonvaImage
              x={0}
              y={0}
              listening={false}
              width={width}
              height={height}
              image={image}
            />
          </Layer>
          <Layer
            key="annotations"
            ref={annotationsLayerRef}
            listening={mode !== 'PAN'}
            {...layerProps}
          >
            <Rect
              width={width}
              height={height}
              listening={false}
            />
            {
              // eslint-disable-next-line arrow-body-style
              annotationsSorted.map((annotation) => {
                const ShapeComponentClass = (
                    annotation.config.shape === 'RECT'
                  ? ShapeRect
                  : annotation.config.shape === 'POINT'
                  ? ShapePoint
                  : null
                );
                return (
                  <ShapeComponentClass
                    key={annotation.value._id}
                    annotation={annotation.value}
                    selected={
                      mode === 'EDIT'
                      && annotation.value._id === selectedAnnotationId
                    }
                    bounds={bounds}
                    onClick={() => onSelectedAnnotationIdChange(
                      annotation.value._id,
                    )}
                    onDragStart={() => onSelectedAnnotationIdChange(
                      annotation.value._id,
                    )}
                    onDblClick={(event) => {
                      const newAnnotations = annotations.slice();
                      const i = newAnnotations.indexOf(annotation);
                      newAnnotations.splice(i, 1);
                      event.target.getStage().container().style
                      .cursor = 'inherit';
                      onAnnotationsChange(newAnnotations);
                      onSelectedAnnotationIdChange(null);
                    }}
                    onChange={(newAnnotationValue) => {
                      const newAnnotations = annotations.slice();
                      const i = newAnnotations.indexOf(annotation);
                      newAnnotations.splice(i, 1, {
                        ...annotations[i],
                        value: newAnnotationValue,
                      });
                      onAnnotationsChange(newAnnotations);
                    }}
                  />
                );
              })
            }
            {
              addingAnnotation && AddingShapeComponentClass
              ? (
                  <AddingShapeComponentClass
                    key={addingAnnotation._id}
                    adding
                    bounds={bounds}
                    shapeRef={addingAnnotationRef}
                    annotation={addingAnnotationStatsRef.current?.annotation}
                    selected
                  />
                )
              : null
            }
          </Layer>
        </ThemeProvider>
      </Stage>
      <ToolbarWrapper data-is-small={isSmall}>
        <ToolbarSide>
          {
            isSmall
            ? (
                <Radio value="NO_VALUE">
                  <RadioButton
                    onClick={() => onSidebarOpen()}
                  >
                    <AntdIcon type="menu" />
                  </RadioButton>
                </Radio>
              )
            : null
          }
        </ToolbarSide>
        <ToolbarSide>
          {
            selectedAnnotationId && !readOnly
            ? (
                <Radio value="NO_VALUE">
                  <RadioButton
                    onClick={() => {
                      onAnnotationsChange(annotations.filter((annotation) => (
                        annotation?.value?._id !== selectedAnnotationId
                      )));
                      onSelectedAnnotationIdChange(null);
                    }}
                  >
                    Delete
                  </RadioButton>
                </Radio>
              )
            : null
          }
          {
            isSmall || readOnly
            ? null
            : (
                <>
                  {
                    onReset
                    ? (
                        <Button
                          onClick={onReset}
                          type="danger"
                          ghost
                        >
                          Reset
                        </Button>
                      )
                    : null
                  }
                  <Radio
                    value={mode}
                    onChange={
                      event => onModeChange && onModeChange(event.target.value)
                    }
                  >
                    <RadioButton value="PAN">
                      Pan
                    </RadioButton>
                    <RadioButton value="EDIT">
                      Edit
                    </RadioButton>
                  </Radio>
                </>
              )
          }
          {
            isSmall
            ? null
            : (
                <Radio value="NO_VALUE">
                  <RadioButton onClick={onZoomIn}>
                    <AntdIcon type="plus" style={{ fontSize: '80%' }} />
                  </RadioButton>
                  <RadioButton onClick={onZoomReset}>
                    Reset
                  </RadioButton>
                  <RadioButton onClick={onZoomOut}>
                    <AntdIcon type="minus" style={{ fontSize: '80%' }} />
                  </RadioButton>
                </Radio>
              )
          }
          {
            isSmall || !onGenerate
            ? null
            : (
                <Radio value="NO_VALUE">
                  <RadioButton onClick={onGenerate}>
                    <AntdIcon type="fire" />
                  </RadioButton>
                </Radio>
              )
          }
          {
              onClose
            ? (
                <Radio value="NO_VALUE">
                  <RadioButton onClick={onClose}>
                    <AntdIcon type="close" />
                  </RadioButton>
                </Radio>
              )
            : null
          }
        </ToolbarSide>
      </ToolbarWrapper>
    </>
  );
}

export default function Editor(props) {
  const editorWrapperRef = useRef();
  const editorWrapperSize = useSize(editorWrapperRef);
  return (
    <Wrapper>
      <EditorWrapper ref={editorWrapperRef}>
        {
            !editorWrapperSize
          ? null
          : (
              <EditorWithSize
                {...props}
                editorWrapperRef={editorWrapperRef}
                editorWrapperSize={editorWrapperSize}
              />
            )
        }
      </EditorWrapper>
    </Wrapper>
  );
}
