import {
  useState,
  useEffect,
  useCallback,
  useLayoutEffect,
} from 'react';

function getSize(element) {
  return (
    !element
    ? null
    : {
        width: element.offsetWidth,
        height: element.offsetHeight,
      }
  );
}

export function useSize(ref) {
  const [size, setSize] = useState(getSize(ref ? ref.current : null));

  const handleResize = useCallback(
    () => {
      if (ref.current) {
        const newSize = getSize(ref.current);
        if (!size || (
          size.width !== newSize.width
          || size.height !== newSize.height
        )) {
          setSize(newSize);
        }
      }
    },
    [size, ref],
  );

  useLayoutEffect(
    () => {
      const element = ref.current;
      if (!element) {
        return undefined;
      }
      handleResize();
      if (typeof ResizeObserver === 'function') {
        let resizeObserver = new ResizeObserver(() => handleResize());
        resizeObserver.observe(element);
        return () => {
          if (resizeObserver) {
            resizeObserver.disconnect();
            resizeObserver = null;
          }
        }
      }
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      }
    },
    [ref, handleResize],
  );

  return size;
}

export function useMediaQuery(query) {
  const [matches, setMatches] = useState(false);

  useEffect(
    () => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      window.addEventListener('resize', listener);
      return () => window.removeEventListener('resize', listener);
    },
    [matches, query],
  );

  return matches;
}
