import React, {
  useRef,
  useEffect,
} from 'react';

import { useTheme } from 'styled-components';

import {
  Circle,
} from 'react-konva';

import Konva from 'konva';

import TIMER from './Timer';

// function getBoundsAvarePosition(x, y, bounds, annotation) {
//   return {
//     x: Math.max(
//       bounds.x,
//       Math.min(
//         bounds.x + bounds.width - (annotation.width * bounds.scale),
//         x,
//       ),
//     ),
//     y: Math.max(
//       bounds.y,
//       Math.min(
//         bounds.y + bounds.height - (annotation.height * bounds.scale),
//         y,
//       ),
//     ),
//   };
// }

export default function ShapePoint({
  selected,
  adding,
  onClick,
  onDblClick,
  onDragStart,
  annotation,
  onChange,
  bounds,
  shapeRef: externalShapeRef,
}) {
  const scaleSelected = 1.4;
  const scaleEffect = 4.4;

  const shapeRef = useRef();
  const shapeEffectRef = useRef();

  const {
    annotations: {
      fillPoint: fill,
      fillSelectedPoint: fillSelected,
      stroke,
      strokeSelected,
      strokeWidth,
      hitStrokeWidth,
      shadowColor,
      shadowBlur,
      point: {
        radius: pointRadius,
      },
    },
  } = useTheme();

  // useEffect(() => {
  //   shapeRef.current.dragBoundFunc((pos) => getBoundsAvarePosition(
  //     pos.x,
  //     pos.y,
  //     bounds,
  //     annotation,
  //   ));
  // }, [annotation, bounds]);

  useEffect(
    () => {
      const { current } = shapeRef;
      if (externalShapeRef) {
        externalShapeRef.current = shapeRef.current;
      }
      return () => {
        if (externalShapeRef?.current === current) {
          externalShapeRef.current = null;
        }
      };
    },
    [externalShapeRef],
  );

  useEffect(
    () => {
      let timerId = null;
      let shape = null;
      let tween = null;
      if (timerId) {
        TIMER.remove(timerId);
      }
      if (!selected && shapeEffectRef.current) {
        timerId = TIMER.add(() => {
          if (shapeEffectRef.current) {
            shape = shapeEffectRef.current;
            if (tween) {
              tween.destroy();
            }
            shape.opacity(1);
            shape.scaleX(1);
            shape.scaleY(1);
            tween = new Konva.Tween({
              node: shape,
              scaleX: scaleEffect,
              scaleY: scaleEffect,
              fill: fillSelected,
              duration: 2,
              opacity: 0,
              easing: Konva.Easings.StrongEaseOut,
              onFinish: () => {
                if (tween) {
                  tween.destroy();
                }
                shape.fill(selected ? fillSelected : fill);
                shape.opacity(0);
                shape.scaleX(1);
                shape.scaleY(1);
                // tween = new Konva.Tween({
                //   node: shape,
                //   scaleX: 1,
                //   scaleY: 1,
                //   fill: selected ? fillSelected : fill,
                //   duration: 0.8,
                //   easing: Konva.Easings.EaseOut,
                // });
                // tween.play();
              },
            });
            tween.play();
          }
        });
      } else if (shapeEffectRef.current) {
        TIMER.remove(timerId);
        if (tween) {
          tween.destroy();
        }
        shape = shapeEffectRef.current;
        shape.scaleX(selected ? scaleSelected : 1);
        shape.scaleY(selected ? scaleSelected : 1);
        shape.fill(selected ? fillSelected : fill);
        shape.opacity(0);
      }
      return () => {
        TIMER.remove(timerId);
        if (tween) {
          tween.destroy();
        }
        // if (shape) {
        //   shape.scaleX(1);
        //   shape.scaleY(1);
        //   shape.fill(selected ? fillSelected : fill);
        // }
      };
    },
    [selected, fill, fillSelected],
  );

  return (
    <>
      <Circle
        ref={shapeEffectRef}
        x={annotation.x}
        y={annotation.y}
        radius={pointRadius / 2 / bounds.scale}
        fill={fillSelected}
        stroke={strokeSelected}
        scaleX={1}
        scaleY={1}
        opacity={0}
      />
      <Circle
        onClick={onClick}
        onTap={onClick}
        onDblClick={onDblClick}
        onDblTap={onDblClick}
        scaleX={selected ? scaleSelected : 1}
        scaleY={selected ? scaleSelected : 1}
        ref={shapeRef}
        x={annotation.x}
        y={annotation.y}
        radius={pointRadius / 2 / bounds.scale}
        draggable={!adding}
        strokeScaleEnabled={false}
        hitStrokeWidth={hitStrokeWidth}
        fill={selected ? fillSelected : fill}
        stroke={selected ? strokeSelected : stroke}
        strokeWidth={strokeWidth}
        shadowColor={shadowColor}
        shadowBlur={shadowBlur}
        onMouseEnter={(event) => {
          event.target.getStage().container().style.cursor = (
            adding
            ? 'grabbing'
            : 'grab'
          );
        }}
        onMouseLeave={(event) => {
          event.target.getStage().container().style.cursor = 'inherit';
        }}
        onDragStart={(event) => {
          onDragStart && onDragStart(event);
          event.target.getStage().container().style.cursor = 'grabbing';
        }}
        onDragEnd={(event) => {
          event.cancelBubble = true;
          event.target.getStage().container().style.cursor = 'grab';
          onChange && onChange({
            ...annotation,
            x: event.target.x(),
            y: event.target.y(),
          });
        }}
      />
    </>
  );
}
