import {
  cloneItem,
  filterSearch,
  filterSort,
  filterCountry,
  filterPagination,
} from '../common';

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  ...[
    [filterSearch, 8],
    [filterCountry, 8],
    [filterSort, 8],
  ].map((config) => cloneItem(...config)),
  filterPagination,
];
