// import React from 'react';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-auto-rows: min-content;
  grid-gap: 20px;
  height: 100%;

  justify-items: center;
  align-items: center;
`;

export default Grid;
