import React from 'react';

import Admin from 'hive-admin';
import FieldText from 'hive-admin/src/components/FieldText';
import { LinkOutlined } from '@ant-design/icons';

import copyToClipboard, { getServerUrl } from '../helpers/copyToClipboard';

export default class FieldSlug extends FieldText {
  static config = {
    ...FieldText.config,
    copyLinkSegments: [],
    copyLinkMessage: 'Copied to clipboard!',
    copyLinkEncode: false,
  }

  renderInput(props) {
    const {
      copyLinkSegments,
      copyLinkMessage,
      copyLinkEncode = false,
    } = this.props;
    const extraProps = {};
    extraProps.addonBefore = '/';
    extraProps.addonAfter = (
      <LinkOutlined
        onClick={(event) => {
          const copyLinkSegmentsValue = copyLinkSegments
          .map(segment => segment(
            this.props,
            event,
          ))
          .filter(segment => segment && segment.length)
          .join('/');
          copyToClipboard(
            `${getServerUrl()}${copyLinkSegmentsValue.length
              ? `/${copyLinkSegmentsValue}`
              : ''
            }/${props.value || ''}`,
            copyLinkMessage,
            copyLinkEncode,
          );
        }}
      />
    );
    return super.renderInput({
      ...props,
      ...extraProps,
    });
  }
}

Admin.addToLibrary(
  'FieldSlug',
  config => FieldSlug.create(config),
);
