import Admin from 'hive-admin';

import isFunction from 'lodash/isFunction';
import getKey from 'lodash/get';

import PageArchiveTable from 'hive-admin/src/components/PageArchiveTable';

const {
  defaultProps: { getColumns: pageArchiveTableGetColumnsInitial },
} = PageArchiveTable;

PageArchiveTable.config.getArchiveFilters = (props, filtersOriginal = []) => (
  filtersOriginal.filter(filter => (
    !filter.isAvailable
      ? true
      : filter.isAvailable(props, filter)
  ))
);

PageArchiveTable.defaultProps.getColumns = (props, columns) => (
  pageArchiveTableGetColumnsInitial(
    props,
    (columns || props.columns).filter(column => (
      !column.isAvailable
        ? true
        : column.isAvailable(props, column)
    )),
  )
);

Admin.addToLibrary(
  'redirect.viewerHasNoRole',
  (destination = '/', roles = []) => ({ isAuthorized, viewer, _location }) => (
    !isAuthorized || !viewer
    ? false
    : !roles.includes(viewer.role)
    ? destination
    : false
  ),
);

Admin.addToLibrary(
  'redirect.viewerHasRole',
  (destination = '/', roles = []) => ({ isAuthorized, viewer, _location }) => (
    !isAuthorized || !viewer
    ? false
    : roles.includes(viewer.role)
    ? destination
    : false
  ),
);

Admin.addToLibrary(
  'condition.testFieldValue',
  (field, op, value, parentIndex) => {
    const check = (
      isFunction(op)
        ? null
        : Admin.createFromLibrary('condition.check', {
          path1: field,
          op,
          value2: value,
        })
    );
    return (props) => {
      let fieldValue;
      if (Number.isFinite(parentIndex)) {
        fieldValue = (
          props.forms && props.forms[parentIndex]
            ? props.forms[parentIndex].getFieldValue(field)
            : undefined
        );
        if (typeof fieldValue === 'undefined') {
          fieldValue = (
            props.datas && props.datas[parentIndex]
              ? getKey(props.datas[parentIndex], field, fieldValue)
              : fieldValue
          );
        }
      } else {
        fieldValue = props.form.getFieldValue(field);
        if (typeof fieldValue === 'undefined') {
          fieldValue = getKey(props.data, field, fieldValue);
        }
      }
      return check ? check({ [field]: fieldValue }) : op(fieldValue, props);
    };
  },
);
