import moment from 'moment';

// import Crypto from 'crypto';

import mongoose from 'mongoose/browser';

import COUNTRIES from '../assets/countries.json';
import LANGUAGES from '../assets/languages.json';

import TypesHelpers from './types.helpers';
import TypesInternationalization from './types.internationalization';
import TypesVisuals from './types.visuals';
import TypesAnnotations from './types.annotations';

const { Types: { ObjectId } } = mongoose;

const Types = {
  ...TypesHelpers,
  ...TypesInternationalization,
  ...TypesVisuals,
  ...TypesAnnotations,
};

/* eslint-disable max-len */
const SERVER_PROTOCOL = process.env.SERVER_PROTOCOL || process.env.REACT_APP_SERVER_PROTOCOL;
const SERVER_HOST = process.env.SERVER_HOST || process.env.REACT_APP_SERVER_HOST;
const HTTP_PORT = process.env.HTTP_PORT || process.env.REACT_APP_HTTP_PORT;
const HOME_URL = process.env.HOME_URL || process.env.REACT_APP_HOME_URL;
/* eslint-enable max-len */

Types.SERVER_URL = `${
  SERVER_PROTOCOL
}://${
  SERVER_HOST
}${
  HTTP_PORT !== '80' ? `:${HTTP_PORT}` : ''
}`;

Types.HOME_URL = HOME_URL;

Types.COUNTRIES = COUNTRIES;

Types.COUNTRIES_MAP = COUNTRIES.reduce(
  (agr, country) => { agr[country.iso3a2] = country; return agr; },
  {},
);

Types.COUNTRIES_DIAL_CODES = Types.COUNTRIES.reduce(
  (agr, country) => {
    country.dialCodes.forEach((dialCode, i) => {
      agr.push({
        value: dialCode,
        label: `+${dialCode} (${country.name})${i > 0 ? ` ${i}` : ''}`,
      });
    });
    return agr;
  },
  [],
);

Types.LANGUAGES = LANGUAGES;

Types.LANGUAGES_MAP = LANGUAGES.reduce(
  (agr, language) => { agr[language.iso3a2] = language; return agr; },
  {},
);

Types.LANGUAGES_ENUM = LANGUAGES.map(({ iso3a2 }) => iso3a2);

Types.COUNTRY = 'rs';
Types.CURRENCY = 'USD';

Types.COUNTRY_NAME = Types.COUNTRIES_MAP[Types.COUNTRY].name;

Types.TIMEZONE = 'Serbia/Belgrade';

Types.TRANSLATIONS = [
  [
    'home',
    'Home',
  ],
];

Types.TRANSLATIONS_MAP = Types.TRANSLATIONS.reduce(
  (agr, [key, text]) => {
    agr[key] = text;
    return agr;
  },
  {},
);

Types.getExpiresAt = ({
  expiresIn,
  expiresAt,
  supportsCustom = false,
  momentNow,
} = {}) => {
  momentNow = momentNow || moment();
  if (expiresIn === 'CUSTOM') {
    if (supportsCustom) {
      expiresAt = new Date(expiresAt);
    } else {
      expiresAt = null;
    }
  } else if (expiresIn === 'NEVER') {
    expiresAt = null;
  } else if (typeof expiresIn === 'string' && expiresIn.includes('_')) {
    let [count, unit] = expiresIn.split('_');
    count = parseInt(count, 10);
    if (!Number.isFinite(count) || count < 1) {
      const error = new Error('Invalid expiry count');
      error.isExpiresAtError = true;
      throw error;
    }
    unit = unit.toLowerCase().trim();
    if (!['day', 'week', 'month', 'year'].includes(unit)) {
      const error = new Error('Invalid expiry unit');
      error.isExpiresAtError = true;
      throw error;
    }
    expiresAt = momentNow.clone().add(count, unit).toDate();
  } else {
    expiresAt = null;
  }
  return expiresAt;
}

Types.getExpiresAtFromExpires = (
  {
    expires = 'NEVER',
    expiresDuration,
    expiresPeriod,
    expiresDate,
    supportsCustomDate = false,
    momentNow,
  } = {},
) => {
  momentNow = momentNow || moment();
  if (expires === 'NEVER') {
    return null;
  }
  if (expires === 'RELATIVE') {
    if (!Number.isFinite(expiresDuration) || expiresDuration < 1) {
      const error = new Error('Invalid expiration duration');
      error.isExpiresError = true;
      throw error;
    }
    expiresDuration = Math.round(expiresDuration);
    if (!['DAY', 'WEEK', 'MONTH', 'YEAR'].includes(expiresPeriod)) {
      const error = new Error('Invalid expiration period');
      error.isExpiresError = true;
      throw error;
    }
    expiresPeriod = expiresPeriod.toLowerCase();
    return momentNow.clone().add(expiresDuration, expiresPeriod).toDate();
  }
  if (expires === 'DATE') {
    if (!supportsCustomDate) {
      const error = new Error('No custom date allowed');
      error.isExpiresError = true;
      throw error;
    }
    if (!expiresDate) {
      const error = new Error('Invalid custom date');
      error.isExpiresError = true;
      throw error;
    }
    const expiresAt = new Date(expiresDate);
    if (!Number.isFinite(expiresAt.getTime())) {
      const error = new Error('Invalid custom date');
      error.isExpiresError = true;
      throw error;
    }
    return expiresAt;
  }
  return null;
};

Types.CONSTANTS = {};

const getScaledValuesWithScale = (value, scale, invertScale) => {
  const scaledValue = scale.reduce((acc, scaleValue, i) => {
    if (value >= scaleValue) {
      return i;
    }
    return acc;
  }, 0);

  return invertScale ? scale.length - scaledValue : scaledValue;
};

Object.assign(
  Types,
  Types.createValues('USER_ROLE', [{
    id: 'ADMIN',
    label: 'Admin',
  }, {
    id: 'CONTENT_MANAGER',
    label: 'Content Manager',
  }, {
    id: 'CUSTOMER',
    label: 'Customer',
  }]),
  Types.createValues('ACCESS_TOKEN_TYPE', [{
    id: 'User',
    label: 'User',
  }]),
  Types.createValues('CSV_FORMAT', [{
    id: 'GENERAL',
    label: 'General',
    delimiters: {
      quote: '"',
      column: ',',
      row: '\n',
      decimal: '.',
    },
  }, {
    id: 'EUROPEAN',
    label: 'European',
    delimiters: {
      quote: '"',
      column: ';',
      row: '\n',
      decimal: ',',
    },
  }, {
    id: 'INTERNATIONAL',
    label: 'International',
    delimiters: {
      quote: '"',
      column: ',',
      row: '\n',
      decimal: '.',
    },
  }]),
  Types.createValues('TRACKER_MODE', [{
    id: 'NONE',
    label: 'None',
  }, {
    id: 'TRENDING',
    label: 'Trending',
  }, {
    id: 'ALL',
    label: 'All',
  }]),
  Types.createValues('ITEM_TYPE', [{
    id: 'PUBLIC',
    label: 'Public',
  }, {
    id: 'PRIVATE',
    label: 'Private',
  }]),
  Types.createValues('VISUAL_STATUS', [{
    id: 'NEW',
    label: 'New',
  }, {
    id: 'PROCESSING',
    label: 'Processing',
  }, {
    id: 'REPORT',
    label: 'Report',
  }]),
  Types.createValues('CREDIT_EXPIRES_IN', [{
    id: 'NEVER',
    label: 'Never',
    labelShort: 'Never',
  }, {
    id: '1_MONTH',
    label: '1 Month',
    labelShort: '1m',
  }, {
    id: '3_MONTH',
    label: '3 Months',
    labelShort: '3m',
  }, {
    id: '1_YEAR',
    label: '1 Year',
    labelShort: '1y',
  }, {
    id: '3_YEAR',
    label: '3 Years',
    labelShort: '3y',
  }]),
  Types.createValues('SCORE_VALUE_DESCRIPTIONS', [{
    id: '1',
    key: 'standard',
    label: 'Standard',
    description: 'Standard performance.',
    note: 'Performance that is expected from an average POS or Display.',
    badge: null,
  }, {
    id: '2',
    key: 'good',
    label: 'Good',
    description: 'Good performance!',
    note: 'Approximately 2x more effective compared to average.',
    badge: 'Top 40%',
  }, {
    id: '3',
    key: 'veryGood',
    label: 'Very Good',
    description: 'Very good performance!',
    note: 'Approximately 3x more effective compared to average.',
    badge: 'Top 20%',
  }, {
    id: '4',
    key: 'excellent',
    label: 'Excellent',
    description: 'Excellent performance!',
    note: 'Approximately 4x more effective compared to average.',
    badge: 'Top 10%',
  }, {
    id: '5',
    key: 'outstanding',
    label: 'Outstanding',
    description: 'Outstanding performance!',
    note: 'Approximately 5x more effective compared to average.',
    badge: 'Top 5%',
  }]),
  Types.createValues('REPORT_VALUES', [{
    id: 'visibilityScore',
    key: 'visibilityScore',
    label: 'Stopping Power',
    // eslint-disable-next-line max-len
    description: 'How effective is the POS / Display in attracting shopper attention?',
    type: 'SCORE',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 1, 2, 3, 4, 5],
        invertScale: false,
        importKey: 'sp_prediction_vision',
      },
      _: {
        scale: [0, 1, 2, 3, 4, 5],
        invertScale: false,
        importKey: 'sp_prediction_vision',
      },
    },
  }, {
    id: 'engagementScore',
    key: 'engagementScore',
    label: 'Engagement',
    // eslint-disable-next-line max-len
    description: 'How effective is the POS / Display in holding attention and engaging shoppers towards making a purchase?',
    type: 'SCORE',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 1, 2, 3, 4, 5],
        invertScale: false,
        importKey: 'median_browsing_time',
      },
      _: {
        scale: [0, 1, 2, 3, 4, 5],
        invertScale: false,
        importKey: 'median_browsing_time',
      },
    },
    // promotionVisibility,
    // productExposure,
    // ctaVisibility,
    // keyVisual,
    // textHeaviness,
    // visualComplexity,
    // shoppingExperienceScore,
  }, {
    id: 'promotionVisibility',
    key: 'promotionVisibility',
    label: 'Promotion',
    description: 'How visible is the off-price promotion (like discount)?',
    type: 'METRIC',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 0.01, 1.7, 2.77, 4.13, 6.22],
        invertScale: false,
        importKey: 'total_promo_area',
      },
      _: {
        scale: [0, 0.01, 9.07, 11.21, 14.8, 20.92],
        invertScale: false,
        importKey: 'total_promo_area',
      },
    },
  }, {
    id: 'productExposure',
    key: 'productExposure',
    label: 'Product Exposure',
    // eslint-disable-next-line max-len
    description: 'How visible is the product visual on POS material? (for POS) / How visible and easy to pick up are the products? (for Displays)',
    type: 'METRIC',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 0.01, 40, 49, 57, 65],
        invertScale: false,
        importKey: 'total_product_area',
      },
      _: {
        scale: [0, 0.01, 6, 9, 14, 19],
        invertScale: false,
        importKey: 'total_package_images_area',
      },
    },
  }, {
    id: 'ctaVisibility',
    key: 'ctaVisibility',
    label: 'Call To Action Score',
    // eslint-disable-next-line max-len
    description: 'How visible & easy to process is the call to action/main brand message?',
    type: 'METRIC',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 0.01, 1.36, 2.39, 3.3, 5.73],
        invertScale: false,
        importKey: 'total_msg_brand_area',
      },
      _: {
        scale: [0, 0.01, 2, 4, 9, 15.03],
        invertScale: false,
        importKey: 'total_msg_brand_area',
      },
    },
  }, {
    id: 'keyVisual',
    key: 'keyVisual',
    label: 'Key Visual',
    description: 'How visible & differentiating are the key graphics?',
    type: 'METRIC',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 0.01, 3.2, 5.8, 10.3, 17.5],
        invertScale: false,
        importKey: 'total_img_not_package_area',
      },
      _: {
        scale: [0, 0.01, 16, 27, 37.94, 50.96],
        invertScale: false,
        importKey: 'total_img_not_package_area',
      },
    },
  }, {
    id: 'textBalance',
    key: 'textBalance',
    label: 'Text Balance',
    // eslint-disable-next-line max-len
    description: 'How condensed is the textual content on the POS / Display?',
    type: 'METRIC',
    chatGptLabel: 'Text Balance',
    // eslint-disable-next-line max-len
    chatGptDescription: 'How condensed is the textual content on the POS / Display?',
    // chatGptIndex: -1,
    sourceKey: 'textHeaviness',
    // sourceKeyInverted: true,
    marketingTypes: {
      DISPLAY: {
        scale: [0, 0.01, 1.5, 2.7, 4.3, 6.6],
        // invertScale: true,
        importKey: 'total_all_text_area',
      },
      _: {
        scale: [0, 0.01, 9, 14.04, 20.86, 30.3],
        // invertScale: true,
        importKey: 'total_all_text_area',
      },
    },
  }, {
    id: 'visualSimplicity',
    key: 'visualSimplicity',
    label: 'Visual Simplicity',
    // eslint-disable-next-line max-len
    description: 'How clear is the visual communication? Having too many (or too little) distinctive elements can reduce effectiveness.',
    type: 'METRIC',
    chatGptLabel: 'Visual Simplicity',
    // eslint-disable-next-line max-len
    chatGptDescription: 'How clear is the visual messaging? Having too many distinctive visual elements can be distracting.',
    // chatGptIndex: -1,
    sourceKey: 'visualComplexity',
    // sourceKeyInverted: true,
    marketingTypes: {
      DISPLAY: {
        scale: [0, 1, 10, 13, 16, 20],
        // invertScale: true,
        importKey: 'total_zones',
      },
      _: {
        scale: [0, 1, 4, 5, 6, 7],
        // invertScale: true,
        importKey: 'total_zones',
      },
    },
  }, {
    id: 'shoppingExperienceScore',
    key: 'shoppingExperienceScore',
    label: 'Shopping Experience',
    description: 'How inspiring & creative is the POS / Display?',
    type: 'METRIC',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 1, 2, 3, 4, 5],
        invertScale: false,
        importKey: 'koliko_skupo_izgleda_displej',
      },
      _: {
        scale: [0, 1, 2, 2, 3, 3],
        invertScale: false,
        importKey: 'koliko_je_kvalitetna_stampa_na_pos',
      },
    },
  }, {
    id: 'brandingScore',
    key: 'brandingScore',
    label: 'Branding',
    sourceKey: 'brandingScore',
    description: 'How aligned is the POS / Display with your brand?',
    marketingTypes: {
      DISPLAY: {
        scale: [0, 1, 2, 3, 4, 5],
      },
      _: {
        scale: [0, 1, 2, 3, 4, 5],
      },
    },
  },
].reduce(
  (agr, reportValue) => {
    reportValue = {
      type: 'METRIC',
      chatGptLabel: reportValue.label,
      chatGptDescription: reportValue.description,
      chatGptIndex: 1,
      getScaledValue: (value, marketingType) => {
        const {
          scale,
          invertScale,
        } = reportValue.marketingTypes[marketingType]
          || reportValue.marketingTypes._;

        return getScaledValuesWithScale(value, scale, invertScale);
      },
      getImportKey: (marketingType) => {
        const {
          importKey,
        } = reportValue.marketingTypes[marketingType]
          || reportValue.marketingTypes._;
        return importKey;
      },
      getScaleLength: (marketingType) => {
        const {
          scale,
        } = reportValue.marketingTypes[marketingType]
          || reportValue.marketingTypes._;

        return scale.length - 1;
      },
      ...reportValue,
    };
    reportValue.chatGptFieldLabel = `${reportValue.chatGptLabel}${
        reportValue.chatGptIndex === -1
      ? ` (inversion of "${reportValue.label}")`
      : ''
    }`;
    agr.push(reportValue);
    return agr;
  },
  [],
)));

Object.assign(
  Types,
  Types.createValues(
    'REPORT_METRICS',
    Types.REPORT_VALUES_LIST.filter(({ type }) => type === 'METRIC'),
  ),
  Types.createValues(
    'REPORT_SCORES',
    Types.REPORT_VALUES_LIST.filter(({ type }) => type === 'SCORE'),
  ),
);

Object.assign(Types.CONSTANTS, {

});

Types.getNewMetrics = (visual) => Types.REPORT_VALUES_LIST.reduce(
  (agr, typeObject) => {
    const oldValue = visual[typeObject.sourceKey || typeObject.id];
    let newValue = Math.round(typeObject.getScaledValue(
      oldValue,
      visual.marketingType,
    )) || 0;
    if (typeObject.sourceKey) {
      agr[typeObject.sourceKey] = newValue;
    }
    if (typeObject.sourceKeyInverted) {
      newValue = 6 - newValue;
    }
    agr[typeObject.id] = newValue;
    return agr;
  },
  {},
);

Types.generateSuggestionsPromptValuesList = (
  data,
  // marketingType,
  // dataPreScaled = false,
) => Types.REPORT_VALUES_LIST.reduce(
  (agr, item) => {
    const value = data?.[item.id];
    if (Number.isFinite(value) && value !== 0) {
      // const multiplier = 5 / item.getScaleLength(marketingType);
      // const endValue = Math.abs(
      //   (item.chatGptIndex === -1 ? 5 : 0)
      //   - parseFloat(
      //       (
      //         (
      //             dataPreScaled
      //           ? value
      //           : item.getScaledValue(value, marketingType)
      //         ) * multiplier
      //       ).toFixed(1),
      //       10,
      //     )
      // );
      if (value > 0 && value <= 5) {
        agr.push(`- ${item.chatGptLabel}: ${value}`);
      }
    }
    return agr;
  },
  [],
);

Types.generateAnnotations = (marketingType, marketingKind) => {
  const VISUAL_ANNOTATION_ITEMS = (
    Types.getVisualAnnotationItemsForMarketingType(
      marketingType,
      marketingKind,
    )
  );
  return VISUAL_ANNOTATION_ITEMS.reduce(
    (agr, item) => {
      switch (item.type) {
        case 'SHAPE':
          switch (item.shape) {
            case 'RECT':
              agr[item.id] = Types.generateAnnotations.makeRect(item);
            break;
            case 'POINT':
              agr[item.id] = Types.generateAnnotations.makePoint(item);
            break;
            default:
          }
        break;
        case 'TEXT':
          agr[item.id] = Types.generateAnnotations.makeText();
        break;
        case 'CHOICES':
          agr[item.id] = Types.generateAnnotations.makeChoices(item);
        break;
        default:
      }
      return agr;
    },
    {},
  );
};

Object.assign(
  Types.generateAnnotations,
  {
    makeText() {
      const length = (
        Math.random() > 0.5
        ? Math.floor(Math.random() * 30)
        : 1
      );
      let result = '';
      // eslint-disable-next-line max-len
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789 ';
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * characters.length)
        );
        counter += 1;
      }
      return result;
    },
    makeChoices(item) {
      const result = [];
      const values = item.choices.map(choice => choice.value);
      const length = (
        Math.random() > 0.5
        ? (
            item.multiple
            ? (
                Math.floor(Math.random() * 2)
                + Math.floor(Math.random() * item.choices.length)
              )
            : 1
          )
        : 1
      );
      for (let i = 0; i < length; i++) {
        let value = values[Math.floor(Math.random() * values.length)];
        while (result.includes(value)) {
          value = values[Math.floor(Math.random() * values.length)];
        }
        result.push(value);
      }
      return item.multiple ? result : result[0];
    },
    makeRect(item) {
      const result = [];
      const length = (
        Math.random() > 0.5
        ? (
            item.multiple
            ? (1 + Math.floor(Math.random() * 4))
            : 1
          )
        : 1
      );
      for (let i = 0; i < length; i++) {
        const resultSingle = {
          _id: `${ObjectId()}`,
          x: Math.random() * 500,
          y: Math.random() * 500,
          width: Math.random() * 500,
          height: Math.random() * 500,
        };
        if (item?.annotations?.length) {
          item.annotations.forEach((annotation) => {
            resultSingle[annotation.id] = (
                annotation.type === 'TEXT'
              ? Types.generateAnnotations.makeText()
              : annotation.type === 'CHOICES'
              ? Types.generateAnnotations.makeChoices(
                  annotation,
                )
              : undefined
            );
          });
        }
        result.push(resultSingle);
      }
      return result;
      // return item.multiple ? result : result[0];
    },
    makePoint(item) {
      const result = [];
      const length = (
        item.multiple
        ? (1 + Math.floor(Math.random() * 4))
        : 1
      );
      for (let i = 0; i < length; i++) {
        const resultSingle = {
          _id: `${ObjectId()}`,
          x: Math.random() * 500,
          y: Math.random() * 500,
        };
        if (item?.annotations?.length) {
          item.annotations.forEach((annotation) => {
            resultSingle[annotation.id] = (
                annotation.type === 'TEXT'
              ? Types.generateAnnotations.makeText()
              : annotation.type === 'CHOICES'
              ? Types.generateAnnotations.makeChoices(
                  annotation,
                )
              : undefined
            );
          });
        }
        result.push(resultSingle);
      }
      return result;
      // return item.multiple ? result : result[0];
    },
  },
);

Types.getMarketingKind = (marketingType) => (
  marketingType === 'DISPLAY'
  ? 'DISPLAY'
  : 'POS'
);

// eslint-disable-next-line arrow-body-style
Types.getSpecifiedLabels = (visual) => {
  return [
    [
      'marketingType',
      Types.VISUAL_MARKETING_TYPE_LABELS_MAP,
    ],
    [
      'goal',
      Types.VISUAL_GOAL_LABELS_MAP,
    ],
    [
      'promotionType',
      Types.VISUAL_PROMOTION_TYPE_LABELS_MAP,
      ['DISCOUNT', 'OTHER'],
      (testVisual) => testVisual.goal !== 'PROMOTION',
    ],
    [
      'season',
      Types.VISUAL_SEASON_LABELS_MAP,
    ],
  ].reduce(
    (agr, [
      key,
      valueLabelsMap,
      useSpecifyForValues = ['OTHER'],
      getIsHidden,
    ]) => {
      if (!getIsHidden || !getIsHidden(visual)) {
        agr[`${key}Label`] = (
          useSpecifyForValues.includes(visual[key])
          ? visual[`${key}Specify`]
          : valueLabelsMap[visual[key]]
        );
      }
      return agr;
    },
    {},
  );
};

Types.generateSuggestionsPrompt = (
  template,
  marketingType = Types.VISUAL_MARKETING_TYPE[0],
  valuesList,
) => {
  const initialPrompt = [
    template.intro,
    '',
    'Rules:',
    template.rules.map(({ text }) => `- ${text}`).join('\n'),
    template.rulesDev.map(({ text }) => `- ${text}`).join('\n'),
    // eslint-disable-next-line max-len
    'Below, you will find a detailed guide on how to interpret the input scores, which will help you in locating issues and finding better suggestions for improvement.',
    '',
    'Input Effectiveness Scores:',
    ...Types.REPORT_SCORES_LIST.reduce(
      (agr, { id, chatGptLabel }) => {
        const text = template.scores?.[id];
        if (text?.length) {
          agr.push(`- ${chatGptLabel}: ${text}`);
        }
        return agr;
      },
      [],
    ),
    '',
    'Interpretation for the Input Effectiveness Scores values:',
    ...Types.SCORE_VALUE_DESCRIPTIONS_LIST.reduce(
      (agr, { id, key, label }) => {
        const text = template.scoresInterpretations?.[key];
        if (text?.length) {
          agr.push(`- For value ${id} result is labeled as ${label}: ${
            text
          }`);
        }
        return agr;
      },
      [],
    ),
    '',
    'Input Metrics Scores:',
    ...Types.REPORT_METRICS_LIST.reduce(
      (agr, { id, chatGptLabel }) => {
        const text = template.metrics?.[id];
        if (text?.length) {
          agr.push(`- ${chatGptLabel}: ${text}`);
        }
        return agr;
      },
      [],
    ),
    '',
    `Marketing type you'll be analyzing is: ${
      Types.VISUAL_MARKETING_TYPE_MAP?.[
        marketingType
      ]?.label || 'POS / Display'
    }`,
  ];
  if (!valuesList.length) {
    throw new Error('No report values set');
  }
  return [
    ...initialPrompt,
    '',
    'Input Scores are:',
    ...valuesList,
  ].join('\n');
};

export default Types;
