import React from 'react';
import styled from 'styled-components';
import { Star as StarIcon } from 'react-feather';

import { Body } from './Text';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 100px;
  flex: 1 0 auto;

  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
`;

const Star = styled(StarIcon)`
  width: 12px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.2)};
  color: ${({ theme }) => theme.less.primaryColor};
`;

const Stars = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;

const StarScore = ({ score, scale, title }) => (
  <Wrapper $isActive={score > 0}>
    <Stars>
      {Array.from({ length: scale }, (_, i) => (
        <Star key={i} $isActive={i < score} />
      ))}
    </Stars>
    <Body style={{ fontSize: 8 }}>{title}</Body>
  </Wrapper>
);

export default StarScore;
