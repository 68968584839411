import {
  GENERAL as FIELDS_GENERAL,
} from './fields';

import tests from '../../helpers/tests';

// eslint-disable-next-line import/no-anonymous-default-export
export default ['PageSingle', {
  title: 'Settings',
  label: 'Settings',
  activity: 'edit',
  hidden: false,
  path: '/settings',
  url: '/settings/general',
  redirect: [['redirect.unauthorized']],
  fields: FIELDS_GENERAL,
  actions: [['ActionSaveAndReload']],
  skip: [tests.viewerIsNotAdmin],
  icon: 'setting',
}];
