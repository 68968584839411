import React from 'react';

import { Link } from 'react-router-dom';

import {
  columnName,
  columnAnnotationValue,
} from '../common';

export const columnDepartment = {
  title: 'Department',
  render: record => (
    record.department?.name
    ? (
        <Link to={`/departments/${record.department._id}`}>
          {record.department.name}
        </Link>
      )
    : '-'
  ),
};

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  columnName,
  columnAnnotationValue,
  columnDepartment,
];
