/* eslint-disable import/prefer-default-export */

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';

import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';

import Admin from 'hive-admin';

import { useHotkeys } from 'react-hotkeys-hook';

import AntdButton from 'antd/lib/button';

import Editor from './Editor';
import Sidebar from './Sidebar';

import TIMER from './Timer';

// import { useOverrideAdminProps } from '../OverrideAdminPropsContext';

import Types from '../../common/modules/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 70vh;
  display: flex;
  position: relative;
  overflow: hidden;
  [data-modal="true"] {
    border: 1px solid ${({ theme }) => theme.less.borderColor};
    border-radius: ${({ theme }) => theme.less.borderRadius};
  }
`;

const SidebarWrapper = styled.div`
  display: flex;
  width: 260px;
  flex-shrink: 0;
  height: 100%;
  border-right: 1px solid ${({ theme }) => theme.less.borderColor};
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
  background: white;
  z-index: 1;
  transition: transform 300ms cubic-bezier(0.33, 1, 0.68, 1);
  #admin[data-is-tablet="true"] & {
    position: absolute;
    height: auto;
    left: 10px;
    top: 10px;
    bottom: 10px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.less.borderColor};
    transform: translateX(calc(-100% - 20px));
    &[data-open="true"] {
      transform: translateX(0px);
    }
  }
`;

const EditorWrapper = styled.div`
  display: flex;
  position: relative;
  flex: 1;
  cursor: ${({ adding }) => (adding ? 'cell' : 'inherit')};
`;

const ModalWrapper = styled(motion.div)`
  position: fixed;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.less.borderColor};
  overflow: hidden;
  background: white;
  z-index: 9;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  #admin[data-is-tablet="true"] & {
    border: 0px;
    border-radius: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
  #admin[data-is-tablet="true"][data-hide-header="false"] & {
    top: 80px;
  }
  ${Wrapper} {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`;

function useLoadedImage(src) {
  const imageRef = useRef();
  const [image, setImage] = useState(null);
  useEffect(
    () => {
      if (src && imageRef.current?.src !== src) {
        setImage(null);
        imageRef.current = new Image();
        imageRef.current.onload = () => {
          setImage(imageRef.current);
        };
        imageRef.current.onerror = (error) => {
          // eslint-disable-next-line no-console
          console.log('error loading annotation image:', error);
          setImage(null);
        };
        imageRef.current.src = src;
      }
    },
    [src],
  );
  return image;
}

export function ImageProvider({ children, ...props }) {
  const imageSrc = props.data?.image?.src;
  const image = useLoadedImage(imageSrc);
  return React.cloneElement(children, { ...props, image });
}

export function FieldAnnotationsModal(props) {
  const [isOpen, setIsOpen] = useState(false);
  // const { setPropsRef, unsetPropsRef } = useOverrideAdminProps();
  // useEffect(
  //   () => (
  //     isOpen
  //     ? setPropsRef.current({ hideHeader: true })
  //     : unsetPropsRef.current({ hideHeader: true })
  //   ),
  //   [isOpen, setPropsRef, unsetPropsRef],
  // );
  return (
    <>
      <AntdButton
        onClick={() => setIsOpen(true)}
        data-action="actionOpenAnnotations"
        block
        {...(props.buttonProps || {})}
      >
        {
          props.readOnly
          ? 'Open Areas Of Interest'
          : 'Open Annotations'
        }
      </AntdButton>
      <AnimatePresence exitBeforeEnter>
        {
          isOpen && props.image
          ? (
              <ModalWrapper
                key="modal"
                data-visible="true"
                initial={{
                  opacity: 0,
                  scale: 0.8,
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                }}
                exit={{
                  opacity: 0,
                  scale: 0.8,
                  transition: { duration: 0.25 },
                }}
              >
                <FieldAnnotationsComponent
                  {...props}
                  onClose={() => setIsOpen(false)}
                />
              </ModalWrapper>
            )
          : null
        }
      </AnimatePresence>
    </>
  );
}

// eslint-disable-next-line no-unused-vars
function AnnotationsEditor({
  data,
  image,
  // form,
  value,
  wrapperStyle,
  onChange,
  onReset,
  onClose,
  canGenerate,
  visualAnnotationItems,
  isSmall,
  readOnly,
  hideDescriptions,
  hideAnnotations,
}) {
  // console.log('marketingType:', marketingType);
  const wrapperRef = useRef();
  // const imageSrc = data?.image?.src;
  // const [image, setImage] = useState(null);
  const [mode, setMode] = useState(readOnly ? 'PAN' : 'EDIT');

  const hotkeyLastModeRef = useRef(mode);
  const hotkeyLastPressedRef = useRef(null);

  const onHotkeyIgnoreEventWhen = useCallback(
    // eslint-disable-next-line arrow-body-style
    (event) => {
      return (
        `${event.target.getAttribute('data-is-annotation-field')}`
        === 'true'
      );
    },
    [],
  );

  useHotkeys(
    'ctrl, meta',
    (event) => {
      if (
        !readOnly
        && ['Control', 'Meta'].includes(event.key)
      ) {
        const pressed = event.type === 'keydown';
        event.preventDefault();
        if (
          pressed
          && (
            !hotkeyLastPressedRef.current
            || hotkeyLastPressedRef.current !== event.key
          )
        ) {
          hotkeyLastModeRef.current = mode;
          hotkeyLastPressedRef.current = event.key;
          setMode('PAN');
        } else if (!pressed) {
          hotkeyLastPressedRef.current = null;
          if (hotkeyLastModeRef.current) {
            setMode(hotkeyLastModeRef.current);
          }
          hotkeyLastModeRef.current = null;
        }
      }
    },
    {
      keyup: true,
      keydown: true,
      enableOnContentEditable: true,
      enableOnFormTags: true,
      // eslint-disable-next-line arrow-body-style
      ignoreEventWhen: onHotkeyIgnoreEventWhen,
      // enableOnFormTags: ['INPUT', 'TEXTAREA', 'SELECT'],
    },
    [mode, readOnly],
  );

  // useHotkeys(
  //   '*',
  //   (event) => {
  //     if (event.)
  //   }
  // );

  // useEffect(
  //   () => {
  //     if (imageSrc) {
  //       const img = new Image();
  //       img.onload = () => {
  //         setImage(img);
  //       };
  //       img.onerror = (error) => {
  //         // eslint-disable-next-line no-console
  //         console.log('error loading annotation image:', error);
  //         setImage('Error loading image');
  //       };
  //       img.src = imageSrc;
  //     } else {
  //       setImage('No image found');
  //     }
  //   },
  //   [imageSrc],
  // );

  // stop propagation so it does not pull to refresh
  // useEffect(
  //   () => {
  //     const { current: wrapper } = wrapperRef;
  //     if (wrapper) {
  //       const cb = (event) => {
  //         event.stopPropagation();
  //         return false;
  //       }
  //       wrapper.addEventListener('touchstart', cb);
  //       return () => {
  //         wrapper.removeEventListener('touchstart', cb);
  //       }
  //     }
  //     return undefined;
  //   },
  //   [image],
  // );

  const [selectedAnnotation, setSelectedAnnotation] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const annotations = useMemo(
    // eslint-disable-next-line arrow-body-style
    () => visualAnnotationItems.map((item) => {
      return {
        config: item,
        value: value[item.key],
      };
    }),
    [value, visualAnnotationItems],
  );

  const annotationErrors = useMemo(
    () => visualAnnotationItems.reduce(
      (agr, config) => {
        Types.getAnnotationErrors(data, config, value[config.key], agr);
        return agr;
      },
      { keys: {}, count: 0 },
    ),
    [value, data, visualAnnotationItems],
  );

  const selectedAnnotationShapes = useMemo(
    () => {
      if (selectedAnnotation?.key) {
        const config = Types.VISUAL_ANNOTATION_MAP[selectedAnnotation.key];
        if (config?.type === 'SHAPE') {
          return (value[selectedAnnotation.key] || []).map((item) => ({
            value: item,
            config,
          }));
        }
      }
      return [];
    },
    [value, selectedAnnotation?.key],
  );

  const addingAnnotationConfig = useMemo(
    () => {
      if (selectedAnnotation?.key) {
        const config = Types.VISUAL_ANNOTATION_MAP[selectedAnnotation.key];
        if (config?.type === 'SHAPE') {
          const shapes = value[selectedAnnotation.key] || [];
          const canAdd = shapes.length < (config.multiple ? Infinity : 1);
          if (canAdd) {
            return config;
          }
        }
      }
      return {};
    },
    [selectedAnnotation?.key, value],
  );

  const valueForSelectedAnnotationKey = value?.[selectedAnnotation?.key];

  const selectedAnnotationForm = useMemo(
    () => {
      if (selectedAnnotation?.key) {
        const config = Types.VISUAL_ANNOTATION_MAP[selectedAnnotation.key];
        if (config.type === 'SHAPE') {
          if (selectedAnnotation?.id) {
            if (config.annotations?.length) {
              const valueForSelectedAnnotationIdIndex = (
                valueForSelectedAnnotationKey.findIndex(
                  ({ _id }) => _id === selectedAnnotation.id
                )
              );
              const valueForSelectedAnnotationId = (
                valueForSelectedAnnotationKey[
                  valueForSelectedAnnotationIdIndex
                ]
              );
              return {
                label: `${config.shapeLabel} ${
                  valueForSelectedAnnotationIdIndex + 1
                }`,
                fields: config.annotations.map((subConfig) => ({
                  value: valueForSelectedAnnotationId?.[subConfig.id],
                  config: subConfig,
                  id: selectedAnnotation.id,
                  key: [
                    config.key,
                    selectedAnnotation.id,
                    subConfig.key,
                  ].join('.'),
                })),
              };
            }
          }
        } else {
          return {
            label: config.label,
            fields: [{
              value: valueForSelectedAnnotationKey,
              config,
              key: config.key,
            }],
          };
        }
      }
      return null;
    },
    [
      valueForSelectedAnnotationKey,
      selectedAnnotation?.key,
      selectedAnnotation?.id,
    ],
  );

  const onSelectedAnnotationFormFieldChange = useCallback(
    (fieldValue, fieldId = null, fieldKey = null) => {
      const onChangeValue = {
        ...visualAnnotationItems.reduce(
          (agr, keyConfig) => {
            if (keyConfig.key === selectedAnnotation?.key) {
              const valueCurr = value[selectedAnnotation?.key];
              if (!fieldId) {
                agr[selectedAnnotation?.key] = fieldValue;
              } else {
                // TODO LOOK HERE why all keys get shape on single form change
                agr[selectedAnnotation?.key] = (
                  valueCurr || []
                ).map((idValue) => (
                  idValue._id === fieldId
                  ? ({
                      ...idValue,
                      [fieldKey]: fieldValue,
                    })
                  : idValue
                ));
              }
            }
            return agr;
          },
          { ...value },
        ),
      };
      onChange(onChangeValue);
    },
    [value, onChange, selectedAnnotation?.key, visualAnnotationItems],
  );

  const onModeChange = useCallback(
    (newMode) => {
      hotkeyLastModeRef.current = null;
      if (mode !== newMode) {
        selectedAnnotation && setSelectedAnnotation({
          ...selectedAnnotation || {},
          id: null,
        });
        setMode(newMode);
      }
    },
    [mode, selectedAnnotation],
  );

  const onAnnotationsChange = useCallback(
    (newAnnotations) => {
      onChange({
        ...value,
        [selectedAnnotation?.key]: newAnnotations.map(
          annotation => annotation.value,
        ),
      })
    },
    [value, onChange, selectedAnnotation?.key],
  );

  const onSelectedAnnotationIdChange = useCallback(
    selectedSubAnnotationId => (
      selectedAnnotation && setSelectedAnnotation({
        ...selectedAnnotation || {},
        id: selectedSubAnnotationId,
      })
    ),
    [selectedAnnotation],
  );

  const onSelectedAnnotationChange = useCallback(
    newSelectedAnnotation => {
      setSelectedAnnotation(
        newSelectedAnnotation
        ? ({ ...newSelectedAnnotation })
        : null
      );
    },
    [],
  );

  useEffect(
    () => {
      let timeout = null;
      timeout = setTimeout(
        () => {
          TIMER.restart();
        },
        500,
      );
      return () => {
        clearTimeout(timeout);
      };
    },
    [selectedAnnotation, selectedAnnotationShapes],
  );

  const { onSidebarOpen, onSidebarClose } = useMemo(
    () => ({
      onSidebarOpen: () => setIsSidebarOpen(true),
      onSidebarClose: () => setIsSidebarOpen(false),
    }),
    [],
  );

  const onGenerate = useCallback(
    () => onChange(Types.generateAnnotations(
      data.marketingType,
      data.marketingKind,
    )),
    [onChange, data.marketingType, data.marketingKind],
  );

  if (!image) {
    return null;
  }

  if (typeof image === 'string') {
    return (
      <p>
        {image}
      </p>
    );
  }

  return (
    <Wrapper
      ref={wrapperRef}
      style={wrapperStyle}
      data-modal={!!onClose}
      readOnly={readOnly}
    >
      <EditorWrapper
        adding={!!addingAnnotationConfig?.shape}
        readOnly={readOnly}
      >
        <Editor
          isSmall={isSmall}
          image={image}
          mode={mode}
          onGenerate={canGenerate ? onGenerate : undefined}
          addingAnnotationConfig={addingAnnotationConfig}
          onModeChange={onModeChange}
          onReset={onReset}
          onClose={onClose}
          annotations={selectedAnnotationShapes}
          onAnnotationsChange={onAnnotationsChange}
          selectedAnnotationId={selectedAnnotation?.id || null}
          onSelectedAnnotationIdChange={onSelectedAnnotationIdChange}
          isSidebarOpen={isSidebarOpen}
          onSidebarOpen={onSidebarOpen}
          onSidebarClose={onSidebarClose}
          onHotkeyIgnoreEventWhen={onHotkeyIgnoreEventWhen}
          readOnly={readOnly}
        />
      </EditorWrapper>
      <SidebarWrapper
        data-open={isSidebarOpen}
        readOnly={readOnly}
      >
        <Sidebar
          isSmall={isSmall}
          value={value}
          data={data}
          annotations={annotations}
          errors={annotationErrors}
          selectedAnnotation={selectedAnnotation}
          selectedAnnotationForm={selectedAnnotationForm}
          onSelectedAnnotationChange={onSelectedAnnotationChange}
          onSelectedAnnotationFormFieldChange={onSelectedAnnotationFormFieldChange} // eslint-disable-line max-len
          isSidebarOpen={isSidebarOpen}
          addingAnnotationConfig={addingAnnotationConfig}
          onSidebarClose={onSidebarClose}
          readOnly={readOnly}
          hideDescriptions={hideDescriptions}
          hideAnnotations={hideAnnotations}
        />
      </SidebarWrapper>
    </Wrapper>
  );
}

function FieldAnnotationsComponent(props) {
  const visual = {
    ...props.data,
    ...props.form?.getFieldsValue?.(),
  };
  visual.marketingKind = Types.getMarketingKind(visual.marketingType);
  Object.assign(visual, Types.getSpecifiedLabels(visual));
  const { marketingType, marketingKind } = visual || {};
  const visualAnnotationItems = useMemo(
    () => Types.getVisualAnnotationItemsForMarketingType(
      marketingType,
      marketingKind,
    ),
    [marketingType, marketingKind],
  );
  const { onChange } = props;
  const onReset = useCallback(
    () => {
      onChange && onChange({});
    },
    [onChange],
  );
  return (
    <AnnotationsEditor
      {...props}
      onReset={onReset}
      data={visual}
      visualAnnotationItems={visualAnnotationItems}
    />
  );
}

export function getImageProviderProps(props) {
  return {
    form: props.form,
    data: (
        props.datas
      ? props.datas[props.datas.length - 1]
      : props.data
    ),
    value: props.value,
    onChange: props.onChange,
    wrapperStyle: props.wrapperStyle,
    // canGenerate: props.viewer?.role === 'ADMIN',
    isSmall: props.isTablet,
    readOnly: props.readOnly,
  };
}

Admin.addToLibrary(
  'FieldAnnotations',
  (config = {}) => Admin.compileFromLibrary(['FieldReact', {
    renderContent: (props) => (
      <ImageProvider {...getImageProviderProps(props)}>
        <FieldAnnotationsComponent />
      </ImageProvider>
    ),
    ...config,
  }]),
);

Admin.addToLibrary(
  'FieldAnnotationsModal',
  (config = {}) => Admin.compileFromLibrary(['FieldReact', {
    renderContent: (props) => (
      <ImageProvider {...getImageProviderProps(props)}>
        <FieldAnnotationsModal />
      </ImageProvider>
    ),
    ...config,
  }]),
);
