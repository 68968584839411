import React from 'react';
import styled from 'styled-components';
import { Star as StarIcon } from 'react-feather';

import { StarFilled } from '@ant-design/icons';

import { Title, Body } from './Text';
import { Space } from './Layout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 240px;
  flex: 1 0 auto;

  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.5)};
`;

const Star = styled(StarIcon)`
  width: 24px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.2)};
  color: ${({ theme }) => theme.less.primaryColor};
`;

const StarReport = styled(StarFilled)`
  width: 24px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.1)};
  color: ${({ $isActive, theme }) => (
    $isActive
    ? theme.less.primaryColor
    : theme.less.textColor
  )};
`;

const StarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export function Stars({ score, stars, style, starStyle }) {
  return (
    <StarsWrapper style={style}>
      {Array.from({ length: stars }, (_, i) => (
        <Star
          key={i}
          $isActive={i < score}
          style={starStyle}
        />
      ))}
    </StarsWrapper>
  );
}

export function StarsCompare({
  score,
  stars,
  style,
  starStyle,
  className,
}) {
  return (
    <StarsWrapper className={className} style={style}>
      {Array.from({ length: stars }, (_, i) => (
        <StarReport
          key={i}
          $isActive={i < score}
          style={starStyle}
        />
      ))}
    </StarsWrapper>
  );
}

const StarScore = ({ score, stars, title, description }) => (
  <Wrapper $isActive={score > 0}>
    <Stars score={score} stars={stars} />
    <Space $height={14} />
    <Title as="h3">{title}</Title>
    <Space $height={6} />
    <Body style={{ textWrap: 'balance' }}>{description}</Body>
  </Wrapper>
);

export default StarScore;
