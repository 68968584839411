import React from 'react';

import {
  columnCreatedAt as columnCreatedAtCommon,
  columnCountry,
} from '../common';

export const columnName = {
  path: 'name',
  width: '100%',
  link: '/organizations/:_id',
};

export const columnVisualUidPrefix = {
  path: 'visualIdPrefix',
  title: 'ID Prefix',
  render: record => (
    <code>
      {record.visualIdPrefix}
    </code>
  ),
};

export const columnCreatedAt = {
  ...columnCreatedAtCommon,
  title: 'Joined',
};

export const ALL = [
  columnName,
  columnVisualUidPrefix,
  columnCountry,
  columnCreatedAt,
];
