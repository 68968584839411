import React from 'react';

import DefaultAdmin from 'hive-admin';

import {
  Provider as OverrideAdminPropsProvider,
  WithOverridePropsCallback,
} from './OverrideAdminPropsContext';

export default class Admin extends DefaultAdmin {
  render(extraProps = {}) {
    return (
      <OverrideAdminPropsProvider>
        <WithOverridePropsCallback {...extraProps}>
          {(...args) => super.render(...args)}
        </WithOverridePropsCallback>
      </OverrideAdminPropsProvider>
    );
  }
}
