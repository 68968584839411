import {
  columnName,
  columnOrganization,
} from '../common';

// eslint-disable-next-line import/prefer-default-export
export const ALL = [
  columnName,
  columnOrganization,
];
