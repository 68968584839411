import styled from 'styled-components';

const Space = styled.div`
  width: ${({ $width }) => $width}px;
  height: ${({ $height }) => $height}px;
  flex: 0 0 auto;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ $align }) => $align || 'flex-start'};
  justify-content: ${({ $justify }) => $justify || 'flex-start'};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ $align }) => $align || 'flex-start'};
  justify-content: ${({ $justify }) => $justify || 'flex-start'};
`;

export { Space, Column, Row };
