import React, {
  useRef,
  useEffect,
} from 'react';

import { useTheme } from 'styled-components';

import {
  Rect,
  Transformer,
} from 'react-konva';

import Konva from 'konva';

import TIMER from './Timer';

// function getBoundsAvarePosition(x, y, bounds, annotation) {
//   return {
//     x: Math.max(
//       bounds.x,
//       Math.min(
//         bounds.x + bounds.width - (annotation.width * bounds.scale),
//         x,
//       ),
//     ),
//     y: Math.max(
//       bounds.y,
//       Math.min(
//         bounds.y + bounds.height - (annotation.height * bounds.scale),
//         y,
//       ),
//     ),
//   };
// }

// function getBoundsAvareTransform(oldBox, newBox, bounds, annotation) {
//   const { x, y } = getBoundsAvarePosition(newBox.x, oldBox.y, bounds, {
//     ...annotation,
//     width: newBox.width / bounds.scale,
//     height: newBox.height / bounds.scale,
//   });
//   if (x !== newBox.x || y !== newBox.y) {
//     return oldBox;
//   }
//   return newBox;
// }

export default function ShapeRect({
  selected,
  adding,
  onClick,
  onDblClick,
  onDragStart,
  annotation,
  onChange,
  bounds,
  shapeRef: externalShapeRef,
}) {
  const shapeRef = useRef();
  const transformerRef = useRef();

  // const scaleSelected = 1.2;

  selected = selected || adding;

  const {
    annotations: {
      fillRect: fill,
      fillSelectedRect: fillSelected,
      fillEffectRect,
      stroke,
      strokeSelected,
      strokeWidth,
      hitStrokeWidth,
      shadowColor,
      shadowBlur,
      transformer: {
        anchorSize,
        borderStrokeWidth,
        anchorCornerRadius,
        flipEnabled,
        anchorStroke,
        anchorStrokeWidth,
        anchorFill,
      },
    },
  } = useTheme();

  useEffect(() => {
    // if (selected && !adding) {
    if (selected) {
      transformerRef.current.nodes([shapeRef.current]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selected, adding, annotation, bounds]);

  useEffect(
    () => {
      const { current } = shapeRef;
      if (externalShapeRef) {
        externalShapeRef.current = shapeRef.current;
      }
      return () => {
        if (externalShapeRef?.current === current) {
          externalShapeRef.current = null;
        }
      };
    },
    [externalShapeRef],
  );

  useEffect(
    () => {
      let timerId = null;
      let shape = null;
      let tween = null;
      if (timerId) {
        TIMER.remove(timerId);
      }
      if (!selected && shapeRef.current) {
        timerId = TIMER.add(() => {
          if (shapeRef.current) {
            shape = shapeRef.current;
            if (tween) {
              tween.destroy();
            }
            shape.fill(fillEffectRect);
            tween = new Konva.Tween({
              node: shape,
              // scaleX: 1,
              // scaleY: 1,
              fill: selected ? fillSelected : fill,
              duration: 0.6,
              easing: Konva.Easings.EaseInOut,
              onFinish: () => {
                if (tween) {
                  tween.destroy();
                  tween = null;
                }
              },
            });
            tween.play();
          }
        });
      } else if (shapeRef.current) {
        TIMER.remove(timerId);
        if (tween) {
          tween.destroy();
          tween = null;
        }
        shape = shapeRef.current;
        // shape.scaleX(selected ? scaleSelected : 1);
        // shape.scaleY(selected ? scaleSelected : 1);
        shape.fill(selected ? fillSelected : fill);
      }
      return () => {
        TIMER.remove(timerId);
        if (tween) {
          tween.destroy();
        }
        // if (shape) {
        //   shape.scaleX(1);
        //   shape.scaleY(1);
        //   shape.fill(selected ? fillSelected : fill);
        // }
      };
    },
    [selected, fill, fillSelected, fillEffectRect],
  );

  return (
    <>
      <Rect
        onClick={onClick}
        onTap={onClick}
        onDblClick={onDblClick}
        onDblTap={onDblClick}
        ref={shapeRef}
        x={annotation.x}
        y={annotation.y}
        // scaleX={selected ? scaleSelected : 1}
        // scaleY={selected ? scaleSelected : 1}
        width={annotation.width}
        height={annotation.height}
        draggable={!adding}
        strokeScaleEnabled={false}
        hitStrokeWidth={hitStrokeWidth}
        fill={selected ? fillSelected : fill}
        stroke={selected ? strokeSelected : stroke}
        strokeWidth={strokeWidth}
        shadowColor={shadowColor}
        shadowBlur={shadowBlur}
        onMouseEnter={(event) => {
          event.target.getStage().container().style.cursor = (
            adding
            ? 'nwse-resize'
            : 'grab'
          );
        }}
        onMouseLeave={(event) => {
          event.target.getStage().container().style.cursor = 'inherit';
        }}
        onMouseDown={(event) => {
          event.cancelBubble = true;
        }}
        onDragStart={(event) => {
          onDragStart && onDragStart(event);
          event.target.getStage().container().style.cursor = 'grabbing';
        }}
        onDragMove={(event) => {
          if (event.evt.touches?.length > 1) {
            shapeRef.current.stopDrag && shapeRef.current.stopDrag();
            shapeRef.current.stopTransform && shapeRef.current.stopTransform();
          }
        }}
        onDragEnd={(event) => {
          event.cancelBubble = true;
          event.target.getStage().container().style.cursor = 'grab';
          onChange && onChange({
            ...annotation,
            x: event.target.x(),
            y: event.target.y(),
          });
        }}
        onTransformStart={(event) => {
          event.cancelBubble = true;
        }}
        onTransform={(event) => {
          if (event.evt.touches?.length > 1) {
            shapeRef.current.stopDrag && shapeRef.current.stopDrag();
            shapeRef.current.stopTransform && shapeRef.current.stopTransform();
          }
        }}
        // eslint-disable-next-line no-unused-vars
        onTransformEnd={(event) => {
          const node = shapeRef.current;
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();
          node.scaleX(1);
          node.scaleY(1);
          const newAnnotation = {
            ...annotation,
            x: node.x(),
            y: node.y(),
            width: node.width() * scaleX,
            height: node.height() * scaleY,
          };
          if (newAnnotation.width < 0) {
            newAnnotation.x += newAnnotation.width;
            newAnnotation.width *= -1;
          }
          if (newAnnotation.height < 0) {
            newAnnotation.y += newAnnotation.height;
            newAnnotation.height *= -1;
          }
          onChange && onChange(newAnnotation);
        }}
      />
      {
          // selected && !adding
          selected
        ? (
            <Transformer
              ref={transformerRef}
              rotateEnabled={false}
              flipEnabled={flipEnabled}
              borderStroke={strokeSelected}
              borderStrokeWidth={borderStrokeWidth}
              anchorSize={anchorSize}
              keepRatio={false}
              anchorCornerRadius={anchorCornerRadius}
              anchorStroke={anchorStroke}
              anchorStrokeWidth={anchorStrokeWidth}
              anchorFill={anchorFill}
            />
          )
        : null
      }
    </>
  );
}
