import Admin from 'hive-admin';
import ActionSaveDefault from 'hive-admin/src/components/ActionSave';

export default class ActionSave extends ActionSaveDefault {
  static config = {
    ...ActionSave.config,
    ghost: false,
  }
}

Admin.addToLibrary(
  'ActionSave',
  config => ActionSave.create(config),
);
