import React from 'react';
import styled, { css } from 'styled-components';

import { Title, Subtitle, Body } from './Text';
import { Space, Row } from './Layout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bar = styled.div`
  width: 20%;
  height: 10px;
  border-radius: 10px;
  opacity: 0.1;
  background: ${({ theme }) => theme.less.textColor};

  ${({ $isActive }) => $isActive && css`
    background: ${({ theme }) => theme.less.primaryColor};
    opacity: 1;
  `}
`;

const BarsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  margin-bottom: 10px;
`;

const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border-radius: 6px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  margin-top: 14px;
  background: ${({ theme }) => theme.less.primaryColor};
  opacity: ${({ $value }) => ($value * 0.2)};
`;

export function Bars({
  score,
  scale,
  style,
  barStyle,
  className,
}) {
  return (
    <BarsWrapper className={className} style={style}>
      {Array.from({ length: scale }, (_, i) => (
        <Bar key={i} $isActive={i < score} style={barStyle} />
      ))}
    </BarsWrapper>
  )
}

const BarScore = ({ score, scale, title, subtitle, description, badge }) => (
  <Wrapper>
    <Bars score={score} scale={scale} />
    <Row $justify="space-between">
      <Row $align="baseline">
        <Title>{score}</Title>
        <Title as="h3">{`/${scale}`}</Title>
        <Space $width={10} />
        <Subtitle>{title}</Subtitle>
      </Row>
      {!!badge && <Badge $value={score}>{badge}</Badge>}
    </Row>
    <Body>{subtitle}</Body>
    <Body $alternative>{description}</Body>
  </Wrapper>
);

export default BarScore;
