/* eslint-disable max-len */

import React from 'react';
import styled from 'styled-components';
// import moment from 'moment';
import {
  Page,
  Text,
  View,
  Image,
  Document,
  Font,
  StyleSheet,
  PDFDownloadLink,
} from '@react-pdf/renderer';

// import testData from './compareData';

const formatLabel = (label) => label
  ?.toLowerCase()
  ?.replace(/_/g, ' ')
  ?.replace(/\b\w/g, (char) => char?.toUpperCase());

// const formatDate = (date) => moment(date)?.format('MMM DD, YYYY');

const formatDescription = (description, type) => description
  .replace(/{TYPE}/g, formatLabel(type));

// const resolveNestedKey = (obj, path) => path
//   .split('.')
//   .reduce((acc, part) => acc && acc[part], obj);

Font.register({
  family: 'Poppins',
  fonts: [
    { src: '/pdf-assets/poppins-regular.ttf', fontWeight: 'normal' },
    { src: '/pdf-assets/poppins-medium.ttf', fontWeight: 'medium' },
    { src: '/pdf-assets/poppins-bold.ttf', fontWeight: 'bold' },
  ],
});

const Wrapper = styled.div`
  display: inline-block;

  @media(max-width: 768px) {
    display: block;
    width: 100%;
    button {
      width: 100%;
      margin-top: 10px;
    }
  }
`;

// const SPECS = [
//   { label: 'Date', key: 'createdAt', func: formatDate },
//   { label: 'Type', key: 'marketingType', func: formatLabel },
//   { label: 'Goal', key: 'goal', func: formatLabel },
//   { label: 'Season', key: 'season', func: formatLabel },
//   { label: 'Brand', key: 'brandData.name', func: formatLabel },
//   { label: 'Category', key: 'categoryData.name', func: formatLabel },
//   { label: 'Retailer', key: 'retailerData.name', func: formatLabel },
// ];

const SCORES = [{
  key: 'visibilityScore',
  label: 'Stopping Power',
  description: 'How effective is the {TYPE} in\nattracting shoppers attention?',
}, {
  key: 'engagementScore',
  label: 'Engagement',
  description: 'How engaging is the {TYPE}\ntowards making a purchase?',
}];

const METRICS = [{
  key: 'promotionVisibility',
  label: 'Promotion',
  description: 'How visible is the off-price promotion?',
}, {
  key: 'productExposure',
  label: 'Product Exposure',
  description: 'How visible/accessible are the products?',
}, {
  key: 'ctaVisibility',
  label: 'Call To Action',
  description: 'How visible is the main message?',
}, {
  key: 'keyVisual',
  label: 'Key Visual',
  description: 'How visible are the key graphics?',
}, {
  key: 'textBalance',
  label: 'Text Balance',
  description: 'How condensed is the textual content?',
}, {
  key: 'visualSimplicity',
  label: 'Visual Simplicity',
  description: 'How clear is the visual communication?',
}, {
  key: 'shoppingExperienceScore',
  label: 'Shopping Experience',
  description: 'How inspiring & creative is the {TYPE}?',
}, {
  key: 'brandingScore',
  label: 'Branding',
  description: 'How aligned is the {TYPE} with the brand?',
}];

// const CASE_STUDY = [{
//   title: 'Improved Product Exposure',
//   description: 'By larger bottle image and shaped header.',
// }, {
//   title: 'Changed Key Visual',
//   description: 'New position and brand name.',
// }, {
//   title: 'Open Structure',
//   description: 'Allows better product visibility.',
// }, {
//   title: 'Less Bottles',
//   description: 'Enabling structural design to function.',
// }, {
//   title: 'Increased Bottle Image',
//   description: 'Within side visual.',
// }, {
//   title: 'Bigger Brand Logo',
//   description: 'Increased brand recognition.',
// }];

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  headerLogo: {
    width: 'auto',
    maxWidth: 160,
    height: 'auto',
    maxHeight: 68,
    objectFit: 'contain',
    objectPosition: 'left',
    marginBottom: 10,
  },
  coverImageWrapper: {
    position: 'relative',
    width: 'auto',
    maxWidth: '50%',
  },
  coverImage: {
    width: 'auto',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
  },
  blockImageWrapper: {
    position: 'relative',
    width: '50%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  blockImage: {
    width: 'auto',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
  shade: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: '#222',
    opacity: 0.05,
  },
  scale: {
    position: 'absolute',
    top: 170,
    right: 54,
    width: 'auto',
    height: 16.7,
    objectFit: 'contain',
  },
  section: {
    flexDirection: 'column',
    padding: 50,
  },
  block: {
    flexDirection: 'column',
  },
  row: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 30,
  },
  title: { fontFamily: 'Poppins', fontSize: 36, fontWeight: 'bold' },
  subtitle: { fontFamily: 'Poppins', fontSize: 26, fontWeight: 'bold' },
  caption: { fontFamily: 'Poppins', fontSize: 16, fontWeight: 'bold' },
  text: { fontFamily: 'Poppins', fontSize: 9.6, fontWeight: 'medium' },
  textBold: { fontFamily: 'Poppins', fontSize: 9.6, fontWeight: 'bold' },
  label: {
    fontFamily: 'Poppins',
    fontWeight: 'medium',
    fontSize: 8,
    letterSpacing: 4,
    textTransform: 'uppercase',
    opacity: 0.5,
  },
  caseStudy: {
    position: 'absolute',
    top: 60,
    right: 30,
    width: 'auto',
    height: 480,
    objectFit: 'contain',
    objectPosition: 'center',
  },
});

// const Footer = ({ page }) => (
//   <View
//     style={{
//       flexDirection: 'row',
//       justifyContent: 'space-between',
//       alignItems: 'flex-end',
//       position: 'absolute',
//       bottom: 25,
//       left: 25,
//       right: 25,
//     }}
//   >
//     <Image
//       src="/pdf-assets/logo.png"
//       style={{
//         height: 14,
//         width: 'auto',
//         objectFit: 'contain',
//       }}
//     />
//     <Text style={styles.label}>{page}</Text>
//   </View>
// );

const Rating = ({ score, shape = 'star', style }) => (
  <View style={{ ...style, flexDirection: 'row', gap: 3, flex: '0 0 auto' }}>
    {[...Array(5)].map((_, index) => (
      <Image
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        src={index < score
          ? `/pdf-assets/${shape}-filled.png`
          : `/pdf-assets/${shape}-empty.png`}
        style={{ width: 64, height: 'auto', objectFit: 'contain' }}
      />
    ))}
  </View>
);

const Report = ({
  data, // = testData,
}) => console.log('dddd', data.length) || (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <View style={{ ...styles.block, gap: 15, alignItems: 'center' }}>
          <Image
            src="/pdf-assets/logo.png"
            style={{
              height: 76,
              width: 'auto',
              objectFit: 'contain',
            }}
          />
          <Text style={{ ...styles.label, fontSize: 10 }}>Comparison Report</Text>
        </View>
      </View>
    </Page>

    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, gap: 10 }}>

        <View style={{ flexDirection: 'row', alignItems: 'center', paddingBottom: 5, paddingLeft: data.length === 4 ? 272 : data.length === 3 ? 320 : 390 }}>
          {data.map(item => (
            <View
              key={item?._id}
              style={{ width: '100%', flex: 1, padding: '0px 5px' }}
            >
              <Image
                src={item?.image?.src}
                style={{ ...styles.blockImage, width: '100%', height: 80 }}
              />
            </View>
          ))}
        </View>

        <View style={{ ...styles.block, gap: 10 }}>
          {SCORES.map(({ key, label, description }) => (
            <View key={key} style={{ gap: 20, flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ ...styles.block, width: '100%' }}>
                <Text style={{ ...styles.caption, fontSize: 12 }}>{label}</Text>
                <Text style={{ ...styles.text, fontSize: 8, opacity: 0.5 }}>{formatDescription(description, 'POS / Display')}</Text>
              </View>
              <View style={{ ...styles.row, flex: '0 0 auto', flexWrap: 'nowrap', gap: 80 / data.length }}>
                {data.map((item, index) => {
                  const maxScore = Math.max(...data.map(d => d[key]));
                  const opacity = item[key] === maxScore ? 1 : 0.4;
                  // eslint-disable-next-line react/no-array-index-key
                  return <Rating key={index} score={item[key]} shape="box" style={{ opacity }} />;
                })}
              </View>
            </View>
          ))}
        </View>

        <View style={{ ...styles.block, gap: 8 }}>
          {METRICS.map(({ key, label, description }) => (
            <View key={key} style={{ gap: 20, flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', alignItems: 'center' }}>
              <View style={{ ...styles.block, width: '100%' }}>
                <Text style={{ ...styles.caption, fontSize: 12 }}>{label}</Text>
                <Text style={{ ...styles.text, fontSize: 8, opacity: 0.5 }}>{formatDescription(description, 'POS / Display')}</Text>
              </View>
              <View style={{ ...styles.row, flex: '0 0 auto', flexWrap: 'nowrap', gap: 80 / data.length }}>
                {data.map((item, index) => {
                  const maxScore = Math.max(...data.map(d => d[key]));
                  const opacity = (item[key] === maxScore && item[key] !== 0) ? 1 : 0.4;
                  // eslint-disable-next-line react/no-array-index-key
                  return <Rating key={index} score={item[key]} style={{ opacity }} />;
                })}
              </View>
            </View>
          ))}
        </View>

      </View>
    </Page>

    <Page size="A4" orientation="landscape" style={styles.page}>
      <View style={{ ...styles.section, width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
        <View style={{ ...styles.block, flex: 1, alignItems: 'center', justifyContent: 'center' }}>
          <Image
            src="/pdf-assets/linkedin.png"
            style={{
              height: 156,
              width: 'auto',
              objectFit: 'contain',
            }}
          />
        </View>
        <View style={{ ...styles.block, gap: 15, alignItems: 'center' }}>
          <Image
            src="/pdf-assets/logo.png"
            style={{
              height: 40,
              width: 'auto',
              objectFit: 'contain',
            }}
          />
          <Text style={styles.label}>Your AI Assistant for Retail Marketing Planning</Text>
        </View>
      </View>
    </Page>
  </Document>
);

export function DownloadReport({
  fileName,
  children,
  ...props
}) {
  return (
    <Wrapper>
      <PDFDownloadLink
        document={<Report {...props} />}
        fileName={fileName}
        className="ant-btn ant-btn-primary"
      >
        {children}
        {/* eslint-disable-next-line no-unused-vars */}
        {/* {({ blob, url, loading, error }) => (
          loading ? 'Loading...' : children)} */}
      </PDFDownloadLink>
    </Wrapper>
  );
}

export default Report;
