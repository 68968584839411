import React, { useRef, useEffect } from 'react';
import Spline from '@splinetool/react-spline';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  width: "100%";
  overflow: hidden;
  border-radius: ${({ $borderRadius }) => $borderRadius || '16px'};
  aspect-ratio: ${({ $aspectRatio }) => $aspectRatio || '2/1'};
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.3)};
  transition: opacity 800ms;
  pointer-events: none;
`;

function getIsMissing(value) {
  return ['INITIAL', 'OTHER', 'SHELF_READY_PACKAGING'].includes(value)
}

export default function Preview({
  value,
  aspectRatio,
  borderRadius,
  splineProps,
  ...props
}) {
  const splineRef = useRef();
  const prevValue = useRef(value);

  const isMissing = getIsMissing(value);

  function onLoad(spline) {
    splineRef.current = spline;

    const val = isMissing ? 'INITIAL' : value;
    setTimeout(() => splineRef.current.emitEvent('keyDown', val), 1000);
  }

  useEffect(() => {
    if (!splineRef.current) return;

    const isMissingValue = getIsMissing(value);
    // Removes unnecessary jump when switching between OTHER and INITIAL
    if (getIsMissing(prevValue.current) && isMissingValue) return;
    prevValue.current = value;

    const val = isMissingValue ? 'INITIAL' : value;
    splineRef.current.emitEvent('keyDown', val);
  }, [value]);

  return (
    <Wrapper
      $isActive={!isMissing}
      $aspectRatio={aspectRatio}
      $borderRadius={borderRadius}
      {...props}
    >
      <Spline
        // eslint-disable-next-line react/jsx-no-bind
        onLoad={onLoad}
        scene="https://draft.spline.design/JSPLTOSetMNlE-G6/scene.splinecode"
        style={{
          position: 'absolute',
          inset: '0',
          width: '100%',
          height: '100%',
        }}
        {...splineProps}
      />
    </Wrapper>
  );
}
