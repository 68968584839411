import React from 'react';
import styled from 'styled-components/macro';

import logoSrc from '../common/assets/logo.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 40px 0 40px;
  align-items: center;
  justify-content: center;
  #admin[data-is-tablet="true"] #sidebar & {
    display: none;
  }
`;

const Image = styled.div`
  background-image: url(${logoSrc});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 70px;
  width: 100%;
  max-width: 200px;
  margin-top: 15px;

  [data-component="PageLogin"] & {
    max-width: 280px;
  }

`;

export default function Logo({ src, ...props }) {
  return (
    <Wrapper {...props}>
      <Image src={src || logoSrc} />
    </Wrapper>
  );
}

export function renderLogo() {
  return <Logo />;
}

export function renderSidebarLogo() {
  return <Logo />;
}
