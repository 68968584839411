import React, {
  useState,
  useMemo,
  useRef,
  useContext,
  createContext,
} from 'react';

const Context = createContext({});

export default Context;

export const { Consumer } = Context;

export function Provider({ children }) {
  const [overrideProps, setOverrideProps] = useState({});
  const overridePropsRef = useRef(overrideProps);
  overridePropsRef.current = overrideProps;
  const setOverridePropsRef = useRef(
    (newOverrideProps = {}) => setOverrideProps({
      ...overridePropsRef.current,
      ...newOverrideProps,
    })
  );
  const unsetOverridePropsRef = useRef(
    (unsetOverrideProps = {}) => setOverrideProps(
      Object.keys(overridePropsRef.current).reduce(
        (agr, key) => {
          if (!unsetOverrideProps[key]) {
            agr[key] = overridePropsRef.current[key];
          }
          return agr;
        },
        {},
      )
    ),
  );
  const overridePropsFinal = useMemo(
    () => ({
      props: overrideProps,
      setPropsRef: setOverridePropsRef,
      unsetPropsRef: unsetOverridePropsRef,
    }),
    [overrideProps],
  );
  return (
    <Context.Provider value={overridePropsFinal}>
      {children}
    </Context.Provider>
  );
}

export function useOverrideAdminProps() {
  return useContext(Context);
}

export function WithOverrideProps({ children, ...props }) {
  const { props: overrideProps } = useOverrideAdminProps();
  return React.cloneElement(children, {
    ...props,
    ...overrideProps,
  });
}

export function WithOverridePropsCallback({ children, ...props }) {
  const { props: overrideProps } = useOverrideAdminProps();
  return children({ ...props, ...overrideProps });
}
