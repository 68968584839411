import React from 'react';
import styled from 'styled-components';

import { Link } from 'react-router-dom';

import AntdIcon from 'antd/lib/icon';

import AntdCheckbox from 'antd/lib/checkbox';

import FieldText from 'hive-admin/src/components/FieldText';

import Types from '../common/modules/types';

class FieldWrapper extends FieldText {
  renderInput(props) {
    return this.props.renderContent(props, this.props, this);
  }
}

const TOCCheckbox = styled(AntdCheckbox)`
  margin-right: 11px;
  margin-left: 5px;
`;

const TOCContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  line-height: initial;
  opacity: 0.8;
  margin-top: 10px;
  .has-error & {
    color: ${({ theme }) => theme.less.errorColor};
    a { color: ${({ theme }) => theme.less.errorColor}; }
    .ant-checkbox-inner {
      border-color: ${({ theme }) => theme.less.errorColor};
    }
  }
`;

export default ['PageSmallForm', {
  title: 'Signup',
  path: '/signup',
  exact: true,
  hidden: true,
  hideHeader: true,
  hideSidebar: true,
  redirect: [['redirect.authorized']],
  fields: [
    ['FieldText', {
      name: 'name',
      label: null,
      placeholder: 'Full Name',
      size: 'large',
      prefix: <AntdIcon type="user" style={{ fontSize: 13 }} />,
      rules: [
        ['validation.isRequired'],
      ],
    }],
    ['FieldText', {
      name: 'email',
      label: null,
      placeholder: 'Email',
      size: 'large',
      prefix: <AntdIcon type="mail" style={{ fontSize: 13 }} />,
      autoComplete: 'email',
      rules: [
        ['validation.isRequired'],
        ['validation.isEmail'],
      ],
    }],
    ['FieldText', {
      name: 'organization',
      label: null,
      placeholder: 'Organization',
      size: 'large',
      prefix: <AntdIcon type="home" style={{ fontSize: 13 }} />,
      rules: [
        ['validation.isRequired'],
      ],
    }],
    ['FieldSelect', {
      name: 'country',
      label: null,
      prefix: (
        <AntdIcon
          type="environment"
          style={{
            fontSize: 14,
            transform: 'translateY(-9px)',
          }}
        />
      ),
      placeholder: 'Country',
      allowClear: true,
      showArrow: true,
      showSearch: true,
      optionFilterProp: 'children',
      size: 'large',
      choices: [
        { label: 'N/A', value: null },
        ...Types.COUNTRIES.map(({ name, iso3a2 }) => ({
          label: name,
          value: iso3a2,
        })),
      ],
    }],
    ['FieldConnectionSelect', {
      name: 'categories',
      label: null,
      url: '/categories/public',
      placeholder: 'Categories To Follow',
      mode: 'tags',
      prefix: (
        <AntdIcon
          type="fire"
          style={{
            fontSize: 14,
            transform: 'translateY(-10px)',
          }}
        />
      ),
      maxTagCount: 1,
      size: 'large',
      allowClear: true,
      searchPaths: ['name'],
    }],
    [config => FieldWrapper.create(config), {
      name: 'toc',
      label: null,
      formItemConfig: { help: null },
      renderContent: ({ value, onChange }) => (
        <TOCContainer>
          <TOCCheckbox
            checked={!!value}
            onChange={ev => onChange(ev.target.checked)}
          />
          <span>
            {'I accept the '}
            <Link to="#">Terms of Service</Link>
          </span>
        </TOCContainer>
      ),
      rules: [
        {
          validator: (rule, value, cb) => cb(
            value === true
            ? undefined
            : 'Please accept the TOC first'
          ),
        },
      ],
    }],
  ],
  renderAfterForm: props => (
    <p className="after-form">
      Already have an account? Click
      {' '}
      <Link to={props.loginPath}>
        here
      </Link>
      {' '}
      to login.
    </p>
  ),
  actions: [
    ['ActionWithFormBasedRequest', {
      name: 'signup',
      title: 'Sign Up',
      messageWorking: 'Signing up...',
      messageSuccess: 'Check your inbox for the Account Activation email!',
      method: 'post',
      type: 'primary',
      size: 'large',
      style: { width: '100%' },
      getRequestConfig: (props, data) => ({
        url: '/users/signup',
        method: 'POST',
        data,
      }),
    }],
  ],
}];
