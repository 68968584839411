import isUndefined from 'lodash/isUndefined';

import React from 'react';

// import Admin from 'hive-admin';
import {
  asLocalStorageField,
} from 'hive-admin/src/components/FieldLocalStorage';

import {
  encode as gptEncode,
} from 'gpt-tokenizer'

import { CreditIcon } from '../organizations/fields';

import { createExpiresFields } from '../common';

import Types from '../../common/modules/types';

// eslint-disable-next-line import/prefer-default-export
export const GENERAL = [
  asLocalStorageField(['FieldTitleToggle', {
    name: '_signupTitle',
    title: 'Signup',
    // eslint-disable-next-line max-len
    subtitle: 'Configure how and if new members can join and their trial period',
    initialValue: true,
  }]),
  ...[
    ['FieldRadio', {
      name: 'signup.open',
      label: null,
      block: true,
      choices: [{
        label: 'Enabled',
        value: true,
      }, {
        label: 'Disabled',
        value: false,
      }],
    }],
    ['FieldTextArea', {
      name: 'signup.blacklist',
      label: 'Blacklisted Signup Domains',
      placeholder: 'gmail.com, yahoo.com, etc.',
      autoSize: { minRows: 2 },
      prepareValueForForm: event => (
        isUndefined(event.target.value) || !event.target.value
        ? ' '
        : event.target.value
      ),
      prepareValueForInput: value => (
        value === ' '
        ? undefined
        : value
      ),
    }],
    ['FieldText', {
      name: 'signup.credit.amount',
      // label: null,
      label: 'Credits Issued on Signup',
      type: 'number',
      prefix: 'Credits:',
      addonAfter: <CreditIcon />,
      align: 'right',
      rules: [['validation.isRequired']],
    }],
    ...createExpiresFields(
      'signup.credit.expires',
      false,
      'Signup Credit Expiration',
    ),
  ].map(([fieldName, fieldConfig = {}]) => ([fieldName, {
    ...fieldConfig,
    hidden: [
      ...(fieldConfig.hidden || []),
      ['condition.fieldIsNotTrue', '_signupTitle'],
    ],
  }])),
  asLocalStorageField(['FieldTitleToggle', {
    name: '_pricingTitle',
    title: 'Pricing',
    subtitle: 'Credit prices and offers',
    initialValue: false,
  }]),
  ...[
    ['FieldTitle', {
      title: 'Credit',
      size: 'medium',
      subtitle: 'Purchased via Stripe / On Demand (1+)',
    }],
    ['FieldText', {
      name: 'pricing.credit.online.price',
      // label: null,
      label: 'Price per Credit',
      type: 'number',
      prefix: 'Price:',
      addonAfter: 'USD',
      align: 'right',
      rules: [['validation.isRequired']],
    }],
    ...createExpiresFields(
      'pricing.credit.online.expires',
      false,
      'Purchased Credits Expiration',
    ),
    ['FieldTitle', {
      title: 'Credit Offers',
      size: 'medium',
      subtitle: 'Purchased via Invoice / In Bulk',
    }],
    ['FieldSortableList', {
      name: 'pricing.credit.offers',
      label: null,
      addButtonLabel: 'Add Offer',
      fields: [
        ['FieldText', {
          name: 'name',
          label: null,
          prefix: 'Name:',
          placeholder: 'Offer name, eg. Super Pack',
        }],
        ['FieldText', {
          name: 'price',
          label: null,
          type: 'number',
          prefix: 'Price:',
          placeholder: 'Total offer price',
          addonAfter: 'USD',
          align: 'right',
          col: { xs: 24, md: 12 },
        }],
        ['FieldText', {
          name: 'amount',
          label: null,
          type: 'number',
          prefix: 'Credits:',
          placeholder: 'Total credits issued',
          addonAfter: (
            <CreditIcon />
          ),
          align: 'right',
          col: { xs: 24, md: 12 },
        }],
      ],
    }],
  ].map(([fieldName, fieldConfig = {}]) => ([fieldName, {
    ...fieldConfig,
    hidden: [
      ...(fieldConfig.hidden || []),
      ['condition.fieldIsNotTrue', '_pricingTitle'],
    ],
  }])),
  asLocalStorageField(['FieldTitleToggle', {
    name: '_trackerTitle',
    title: 'Tracker',
    subtitle: 'Setup tracker access',
    initialValue: true,
  }]),
  ...[
    ['FieldConnectionSelect', {
      name: 'tracker.categories.trending',
      label: 'Trending Categories',
      url: '/categories',
      placeholder: 'Select trending categories',
      mode: 'multiple',
      allowClear: true,
      searchPaths: ['name'],
      // getChoiceLabel: choice => `${(choice.data.name)}`,
    }],
    ['FieldText', {
      name: 'tracker.categories.additionalCount',
      label: 'Additional Categories Count',
      type: 'number',
      rules: [['validation.isRequired']],
    }],
  ].map(([fieldName, fieldConfig = {}]) => ([fieldName, {
    ...fieldConfig,
    hidden: [
      ...(fieldConfig.hidden || []),
      ['condition.fieldIsNotTrue', '_trackerTitle'],
    ],
  }])),
  asLocalStorageField(['FieldTitleToggle', {
    name: '_linksTitle',
    title: 'Links',
    subtitle: 'Various links',
    initialValue: true,
  }]),
  ...[
    ['FieldText', {
      name: 'links.howToUploadVisuals',
      label: 'How To Upload Visuals',
    }],
    ['FieldText', {
      name: 'links.howToInterpretReports',
      label: 'How To Interpret Reports',
    }],
  ].map(([fieldName, fieldConfig = {}]) => ([fieldName, {
    ...fieldConfig,
    hidden: [
      ...(fieldConfig.hidden || []),
      ['condition.fieldIsNotTrue', '_linksTitle'],
    ],
  }])),
  asLocalStorageField(['FieldTitleToggle', {
    name: '_visualsGenerateSuggestions',
    title: 'Visuals - Generate Report Suggestions',
    // eslint-disable-next-line max-len
    subtitle: 'Setup OpenAI ChatGPT bot to generate improvement suggestions based on the report results',
    initialValue: false,
  }]),
  ...[
    ['FieldText', {
      name: 'visualsGenerateSuggestions.temperature',
      type: 'number',
      label: 'ChatGPT Temperature',
      // eslint-disable-next-line max-len
      help: 'Number between 0 and 2 determening how strict (0) or creative (2) the responses are.',
      rules: [
        ['validation.isRequired'],
        ['validation.isNumber', { min: 0, max: 2 }],
      ],
    }],
    ['FieldTitle', {
      title: 'Template',
      size: 'medium',
      subtitle: 'Setup the ChatGPT prompt and declare rules of the report',
      getAnnotation: (_text, props) => {
        const values = props.form.getFieldsValue();
        const { template = {} } = values?.visualsGenerateSuggestions || {};
        // eslint-disable-next-line no-console
        console.log('TEMPLATE:', template);
        const prompt = Types.generateSuggestionsPrompt(
          template,
          undefined,
          Types.generateSuggestionsPromptValuesList(
            Types.REPORT_VALUES_LIST.reduce(
              (agr, item) => {
                agr[item.id] = 2;
                return agr;
              },
              {},
            ),
            undefined,
          ),
          true,
        );
        const initialPromptTokens = gptEncode(prompt);
        return `Tokens: ${initialPromptTokens?.length || '-'}`;
      },
    }],
    ['FieldTextArea', {
      name: 'visualsGenerateSuggestions.template.intro',
      label: 'Intro',
      autoSize: { minRows: 2 },
      placeholder: 'Explain the role of the ChatGPT assistant',
    }],
    ...[
      [''],
      ['Dev', [
        props => !/@thehive.rs$/.test(props.viewer?.email || ''),
      ]],
    ].map(([suffix, skip = []]) => ['FieldSortableList', {
      name: `visualsGenerateSuggestions.template.rules${suffix}`,
      label: `Rules${suffix ? ` ${suffix}` : ''}`,
      fields: [
        ['FieldTextArea', {
          label: null,
          name: 'text',
          placeholder: 'Rule Text',
          autoSize: { minRows: 2 },
        }],
      ],
      addButtonLabel: 'Add Rule',
      hidden: [...skip],
    }]),
    ['FieldTitle', {
      title: 'Metrics',
      size: 'medium',
      subtitle: 'Describe the meaning behind each metric value',
    }],
    ...Types.REPORT_METRICS_LIST.map(
      ({ id, chatGptFieldLabel }) => ['FieldText', {
        name: `visualsGenerateSuggestions.template.metrics.${id}`,
        label: chatGptFieldLabel,
      }],
    ),
    ['FieldTitle', {
      title: 'Scores',
      size: 'medium',
      subtitle: 'Describe the meaning behind each score value',
    }],
    ...Types.REPORT_SCORES_LIST.map(
      ({ id, chatGptFieldLabel }) => ['FieldText', {
        name: `visualsGenerateSuggestions.template.scores.${id}`,
        label: chatGptFieldLabel,
      }],
    ),
    ['FieldTitle', {
      title: 'Scores Interpretations',
      size: 'medium',
      subtitle: 'Describe how score values are interpreted',
    }],
    ...Types.SCORE_VALUE_DESCRIPTIONS_LIST.map(({ key, label }) => (
      ['FieldText', {
        name: `visualsGenerateSuggestions.template.scoresInterpretations.${
          key
        }`,
        label,
      }]
    )),
  ].map(([fieldName, fieldConfig = {}]) => ([fieldName, {
    ...fieldConfig,
    hidden: [
      ...(fieldConfig.hidden || []),
      ['condition.fieldIsNotTrue', '_visualsGenerateSuggestions'],
    ],
  }])),
  // ['FieldTitle'],
  // ['FieldUploadRefs', {
  //   name: 'logo',
  //   maxCount: 1,
  //   col: 1,
  //   accept: 'image/jpg,image/jpeg,image/png,image/gif',
  //   transformations: [[
  //     'GM',
  //     { command: 'compress', quality: 95, maxWidth: 2000 },
  //   ]],
  // }],
  // ['FieldUploadRefs', {
  //   name: 'favicon',
  //   maxCount: 1,
  //   col: 1,
  //   accept: 'image/jpg,image/jpeg,image/png,image/ico',
  //   transformations: [[
  //     'GM',
  //     { command: 'compress', quality: 95, maxWidth: 2000 },
  //   ]],
  // }],
  // ['FieldTitle'],
  // ['FieldText', {
  //   name: 'title',
  //   prefix: 'Website Title:',
  //   label: null,
  // }],
];
