import React from 'react';
import styled from 'styled-components';

import AntdRadio from 'antd/lib/radio';
import AntdResult from 'antd/lib/result';
import {
  InfoCircleOutlined,
} from '@ant-design/icons';

import Admin from 'hive-admin';

import tests from '../../helpers/tests';

import Types from '../../common/modules/types';

import {
  ALL as COLUMNS_ALL,
} from './columns';

import {
  ALL as FILTERS_ALL,
} from './filters';

import {
  ALL as FIELDS_ALL,
  CreditIcon,
} from './fields';

import {
  createExpiresFields,
} from '../common';

const {
  viewerIsNotAdmin,
  isNotViewerCustomerWhichCanManageOrganization,
} = tests;

const ComingSoonResult = styled(AntdResult)`

`;

const Button = styled(AntdRadio.Button)`
  height: auto;
  flex-shrink: 0;
  flex: 1;
`;

const ButtonOffer = styled(Button)`
  flex: 1;
`;

const OfferWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 10px; */
  padding-top: 15px;
  padding-bottom: 10px;
  width: 100%;
  flex-shrink: 0;
  line-height: initial;
`;

const OfferName = styled.div`
  display: flex;
  color: white;
  background-color: ${({ theme }) => theme.less.textColor};
  .ant-radio-button-wrapper-checked & {
    background-color: ${({ theme }) => theme.less.primaryColor};
  }
  border-radius: 4px;
  padding: 5px 10px;
  line-height: 100%;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  padding-bottom: 4px;
`;

const OfferCredits = styled.div`
  color: ${({ theme }) => theme.less.textColor};
  .ant-radio-button-wrapper-checked & {
    color: ${({ theme }) => theme.less.primaryColor};
  }
  font-size: 38px;
  font-weight: 700;
  margin-top: 0px;
`;

// const OfferCreditsLabel = styled.div`
//   color: ${({ theme }) => theme.less.textColor};
//   opacity: 0.5;
//   .ant-radio-button-wrapper-checked & {
//     opacity: 1;
//     color: ${({ theme }) => theme.less.primaryColor};
//   }
//   font-size: 10px;
//   font-weight: 400;
//   margin-top: -2px;
// `;

const OfferPrice = styled.div`
  color: ${({ theme }) => theme.less.textColor};
  .ant-radio-button-wrapper-checked & {
    color: ${({ theme }) => theme.less.primaryColor};
  }
  font-size: 14px;
  font-weight: 400;
  /* margin-top: 5px; */
  margin-top: -5px;
`;

export default (
  Admin.compileFromLibrary(['GroupResource', {
    title: 'Organizations',
    icon: 'home',
    path: '/organizations',
    redirect: [['redirect.unauthorized']],
    skip: [viewerIsNotAdmin],
    archiveConfig: {
      icon: 'home',
      title: 'Organizations',
      label: 'Organizations',
      filters: FILTERS_ALL,
      columns: COLUMNS_ALL,
    },
    singleConfig: {
      hidden: true,
      alias: '/organizations',
      backButtonPaths: ['/organizations'],
      headerTitle: 'Create Organization',
      loadUrl: '/organizations/:id/?teams=true',
    },
    singleEditConfig: {
      backButtonPaths: ['/organizations'],
      actions: [
        ['ActionSaveAndReload'],
        ['ActionWithFormModal', {
          name: 'add-credit',
          label: null,
          title: 'Add Credit',
          icon: 'plus',
          type: 'primary',
          skip: [viewerIsNotAdmin],
          fields: [
            ['FieldText', {
              name: 'amount',
              label: 'Amount',
              align: 'right',
              type: 'number',
              initialValue: 1,
              addonAfter: <CreditIcon />,
            }],
            ...createExpiresFields('expires', true),
            ['FieldTextArea', {
              name: 'note',
              label: 'Note',
              autoSize: { minRows: 3 },
            }],
          ],
          actions: [
            ['ActionWithFormBasedRequest', {
              name: 'add-credit-submit',
              title: 'Submit',
              // eslint-disable-next-line arrow-body-style
              getRequestConfig: (props, data) => {
                return {
                  url: `/organizations/${props.data._id}/credit`,
                  method: 'POST',
                  data: {
                    ...data,
                  },
                };
              },
              handleSuccess: (data, props) => {
                props.cancel();
                props.reload();
              },
            }],
          ],
        }],
        ['ActionDelete', {
          skip: [viewerIsNotAdmin],
        }],
      ],
    },
    fields: FIELDS_ALL,
  }])
).pages;

const addCreditModeIsNotOffers = [
  'condition.testFieldValue',
  'mode',
  '!==',
  'OFFERS',
];

const addCreditModeIsNotOnline = [
  'condition.testFieldValue',
  'mode',
  '!==',
  'ONLINE',
];

export const MyOrganizationPage = ['PageSingle', {
  icon: 'home',
  label: 'My Organization',
  title: 'My Organization',
  path: '/organization',
  exact: true,
  backButtonPaths: false,
  skip: [
    isNotViewerCustomerWhichCanManageOrganization,
  ],
  redirect: [
    ['redirect.unauthorized'],
  ],
  hidden: false,
  activity: 'edit',
  fields: FIELDS_ALL,
  useTrash: false,
  loadUrl: '/organizations/me/?teams=true',
  saveUrl: '/organizations/me/?teams=true',
  saveMethod: 'patch',
  loadExtractData: response => (response ? response.data : null),
  editConfig: {
    actions: [
      ['ActionSaveAndReload'],
      ['ActionWithFormModal', {
        name: 'add-credit-customer',
        label: null,
        title: 'Add Credit',
        icon: 'plus',
        type: 'primary',
        fields: [
          ['FieldTitle', {
            title: 'Checkout Mode',
            size: 'large',
            titleStyle: { marginTop: '-20px' },
            // subtitle: 'Purchased via Invoice / In Bulk',
            subtitle: 'See bulk offers or purchase online.',
          }],
          ['FieldRadio', {
            name: 'mode',
            label: null,
            block: true,
            initialValue: 'OFFERS',
            choices: [
              { value: 'OFFERS', label: 'See Offers' },
              { value: 'ONLINE', label: 'Purchase Online' },
            ],
          }],
          ['FieldTitle', {
            title: 'Offers',
            size: 'medium',
            // subtitle: 'Purchased via Invoice / In Bulk',
            subtitle: 'Buy credits in bulk, pay by invoice.',
            skip: [addCreditModeIsNotOffers],
          }],
          ['FieldRadio', {
            name: 'offer',
            // label: 'Choose Package',
            label: null,
            block: true,
            skip: [addCreditModeIsNotOffers],
            getInitialValue: (fieldConfig, props) => (
              props.viewer?.settings?.pricing?.credit?.offers?.[0]?._id
            ),
            // eslint-disable-next-line arrow-body-style
            renderChoices: (field, props) => {
              return props.viewer?.settings?.pricing?.credit?.offers?.map?.(
                ({ _id, name, price, amount }) => (
                  <ButtonOffer
                    key={_id}
                    value={_id}
                  >
                    <OfferWrapper>
                      <OfferName>{name}</OfferName>
                      <OfferCredits>
                        {`${amount}`}
                        <CreditIcon
                          style={{
                            fontSize: '50%',
                            verticalAlign: 'middle',
                            lineHeight: '150%',
                          }}
                        />
                      </OfferCredits>
                      {/* <OfferCreditsLabel>
                        FOR
                      </OfferCreditsLabel> */}
                      <OfferPrice>
                        {`${
                          Types.CURRENCY
                        } ${
                          Types.decimalize(price)
                        }`}
                      </OfferPrice>
                      {/* <OfferCreditsLabel>
                        + VAT / per year
                      </OfferCreditsLabel> */}
                    </OfferWrapper>
                  </ButtonOffer>
                ),
              ) || [];
            },
            choices: [
              { value: 'a', label: 'a' },
              { value: 'b', label: 'b' },
            ],
          }],

          // Online

          ['FieldReact', {
            name: 'comingSoon',
            label: null,
            skip: [addCreditModeIsNotOnline],
            // eslint-disable-next-line arrow-body-style, no-unused-vars
            renderContent: (props) => {
              return (
                <ComingSoonResult
                  icon={(
                    <InfoCircleOutlined
                      style={{ color: '#eee' }}
                    />
                  )}
                  title={<span style={{ color: '#ccc' }}>Coming Soon</span>}
                  subTitle="Online payment feature will be available soon."
                />
              );
            },
          }],
        ],
        actions: [
          ['ActionWithFormBasedRequest', {
            name: 'add-credit-customer-submit-offer',
            title: 'Submit',
            // eslint-disable-next-line arrow-body-style
            getRequestConfig: (props, data) => {
              return {
                url: `/organizations/${props.data._id}/credit/offer`,
                method: 'POST',
                data: {
                  ...data,
                },
              };
            },
            skip: [addCreditModeIsNotOffers],
            handleSuccess: (data, props) => {
              props.cancel();
              props.reload();
            },
          }],
          ['ActionWithFormBasedRequest', {
            name: 'add-credit-customer-submit-online',
            title: 'Submit',
            renderAction: (props, instance) => instance.renderAction({
              ...props,
              disabled: true,
            }),
            skip: [addCreditModeIsNotOnline],
            onClick: () => {},
            handleSuccess: (data, props) => {
              props.cancel();
              props.reload();
            },
          }],
        ],
      }],
    ],
  },
  notFoundMessage: 'Organization not found!',
}];
